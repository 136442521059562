//@ts-nocheck
import React, { createContext, useContext, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { DateRange } from '@mui/x-date-pickers-pro';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { DialogNegativeButtonStyle, PrimaryButton } from '../../styles/global/components/dpButtons';
import dpTheme from '../../styles/theme/dpTheme';
import { toast } from 'react-toastify';
import TIME_RANGE, { GRAPH_TYPE, WIDGET_TYPE } from '../../constants/constants';
import { calculateTimeFrame } from '../../utilities/helpers';
import { TIME_UNITS } from '../../utils/time.utils';
import { add530Hours } from '../../utils/dashboard.utils';
import { updateTimeFrameForRefId } from '../../services/commonApis';

const DateRangeContext = createContext<any>({}); // Set default value as an empty object and use 'any' type

export const useDateRange = () => {
  return useContext(DateRangeContext);
};

const DateAndTimeStyles = {
  ButtonStyles: { height: '42px', width: '45%' },
  CancelButtonStyle: { backgroundColor: '#E0E0E0', color: '#8B8B8B' },
};

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: 'Today',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('day'), today];
    },
  },
  {
    label: 'Last 24 Hours',
    getValue: () => {
      const now = dayjs();
      const yesterday = now.subtract(24, 'hours');
      return [yesterday, now];
    },
  },
  {
    label: 'Yesterday',
    getValue: () => {
      const yesterday = dayjs().subtract(1, 'day');
      return [yesterday.startOf('day'), yesterday.endOf('day')];
    },
  },
  {
    label: 'Last 3 Days',
    getValue: () => {
      const today = dayjs();
      const threeDaysAgo = today.subtract(3, 'day');
      return [threeDaysAgo.startOf('day'), today];
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs();
      const sevenDaysAgo = today.subtract(7, 'day');
      return [sevenDaysAgo.startOf('day'), today];
    },
  },
  {
    label: 'Last 10 Days',
    getValue: () => {
      const today = dayjs();
      const tenDaysAgo = today.subtract(10, 'day');
      return [tenDaysAgo.startOf('day'), today];
    },
  },
  {
    label: 'Last 30 Days',
    getValue: () => {
      const today = dayjs();
      const thirtyDaysAgo = today.subtract(30, 'day');
      return [thirtyDaysAgo.startOf('day'), today];
    },
  },
  {
    label: 'Last 3 Months',
    getValue: () => {
      const today = dayjs();
      const threeMonthsAgo = today.subtract(3, 'month');
      return [threeMonthsAgo.startOf('month'), today];
    },
  },
  {
    label: 'Last 6 Months',
    getValue: () => {
      const today = dayjs();
      const sixMonthsAgo = today.subtract(6, 'month');
      return [sixMonthsAgo.startOf('month'), today];
    },
  },
];

function Granularity({
  selectedDateRange,
  selectedGranularity2,
  setSelectedGranularity2,
  minimumGranularity,
  parent,
}: any) {
  let minimumGranularity2 = minimumGranularity
    ? minimumGranularity.charAt(0).toUpperCase() + minimumGranularity.slice(1)
    : 0;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    setSelectedGranularity2(event.target.value);
  };

  // Define granularity options based on selectedDateRange condition
  let granularityOptions: any = [];
  if (
    (selectedDateRange[0] !== null && selectedDateRange[1] !== null) ||
    (selectedDateRange[1] === null && selectedDateRange[0] !== null)
  ) {
    if (parent === 'widget') {
      const dateDiff = selectedDateRange[1]
        ? selectedDateRange[1].diff(selectedDateRange[0], 'days')
        : 0;
      const allGranularities = ['Minutes', 'Hours', 'Days', 'Weeks', 'Months'];
      const minGranularityIndex = minimumGranularity2
        ? allGranularities.indexOf(minimumGranularity2)
        : 0;
      const allowedGranularities = allGranularities.slice(minGranularityIndex);
      if (dateDiff <= 3) {
        granularityOptions = ['Minutes', 'Hours', 'Days'];
      } else if (dateDiff > 3 && dateDiff <= 7) {
        granularityOptions = ['Hours', 'Days'];
      } else if (dateDiff > 7 && dateDiff <= 60) {
        granularityOptions = ['Days', 'Weeks'];
      } else if (dateDiff > 60) {
        granularityOptions = ['Weeks', 'Months'];
      }
      granularityOptions = granularityOptions.filter((granularity: any) =>
        allowedGranularities.includes(granularity)
      );
    } else {
      const timeDifferenceHours = selectedDateRange[1]
        ? selectedDateRange[1].diff(selectedDateRange[0], 'hours')
        : dayjs().diff(selectedDateRange[0], 'hours');

      if (timeDifferenceHours <= 6) {
        granularityOptions = ['Minutes', 'Hours'];
      } else {
        const dateDiff = selectedDateRange[1]
          ? selectedDateRange[1].diff(selectedDateRange[0], 'days')
          : 0;
        const allGranularities = ['Minutes', 'Hours', 'Days', 'Weeks', 'Months'];
        const minGranularityIndex = minimumGranularity2
          ? allGranularities.indexOf(minimumGranularity2)
          : 0;
        const allowedGranularities = allGranularities.slice(minGranularityIndex);
        if (dateDiff <= 3) {
          granularityOptions = ['Hours', 'Days'];
        } else if (dateDiff > 3 && dateDiff <= 7) {
          granularityOptions = ['Hours', 'Days'];
        } else if (dateDiff > 7 && dateDiff <= 60) {
          granularityOptions = ['Days', 'Weeks'];
        } else if (dateDiff > 60) {
          granularityOptions = ['Weeks', 'Months'];
        }
        granularityOptions = granularityOptions.filter((granularity: any) =>
          allowedGranularities.includes(granularity)
        );
      }
    }
  }

  const [value, setValue] = useState(selectedGranularity2);
  const [dateChanged, setDateChanged] = useState(false);

  useEffect(() => {
    setValue(
      selectedGranularity2 &&
        granularityOptions
          .map(option => option.toLowerCase())
          .includes(selectedGranularity2.toLowerCase())
        ? selectedGranularity2
        : granularityOptions[0]
    );
    setSelectedGranularity2(
      selectedGranularity2 &&
        granularityOptions
          .map(option => option.toLowerCase())
          .includes(selectedGranularity2.toLowerCase())
        ? selectedGranularity2
        : granularityOptions[0]
    );
  }, [selectedGranularity2, selectedDateRange]);

  useEffect(() => {
    setDateChanged(true);
    setValue(
      selectedGranularity2 &&
        granularityOptions
          .map(option => option.toLowerCase())
          .includes(selectedGranularity2.toLowerCase())
        ? selectedGranularity2
        : granularityOptions[0]
    );
    setSelectedGranularity2(selectedGranularity2 || granularityOptions[0]);
  }, [selectedDateRange && !dateChanged]);

  return (
    <Box
      sx={{
        border: '1px solid #BDBDBD',
        borderRadius: '12px',
        padding: '24px',
      }}
    >
      <FormControl>
        <FormLabel
          id="granularity-radio-button-group"
          sx={{ color: '#1A3356', fontSize: '14px', fontWeight: '600' }}
        >
          Granularity
        </FormLabel>
        <RadioGroup
          aria-labelledby="granularity-radio-button-group"
          name="granularity-radio-button-group"
          value={value ? value.charAt(0).toUpperCase() + value.slice(1) : value}
          defaultValue={value ? value.charAt(0).toUpperCase() + value.slice(1) : value}
          onChange={handleChange}
        >
          {granularityOptions.map((option: any) => (
            <FormControlLabel
              key={option}
              value={option}
              control={<Radio />}
              label={option}
              sx={{ fontSize: '14px' }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

function Chart({ selectChart, setSelectChart2 }) {
  const [value, setValue] = useState(selectChart);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    setSelectChart2((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    if (selectChart == 'table') setValue('tableC');
    else setValue(selectChart);
  }, [selectChart]);

  return (
    <Box
      sx={{
        border: '1px solid #BDBDBD',
        borderRadius: '12px',
        padding: '24px',
      }}
    >
      <FormControl>
        <FormLabel
          id="chart-radio-button-group"
          sx={{
            color: '#1A3356',
            fontSize: '16px !important',
            fontWeight: '600',
          }}
        >
          Chart
        </FormLabel>
        <RadioGroup
          aria-labelledby="chart-radio-button-group"
          name="chart-radio-button-group"
          value={value}
          onChange={handleChange}
          defaultValue={value}
        >
          <FormControlLabel value="tableC" control={<Radio />} label="Table" />
          <FormControlLabel value="bar" control={<Radio />} label="Bar Graph" />
          <FormControlLabel value="line" control={<Radio />} label="Line Graph" />
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

const RelativeTimeToolbar = () => {
  const {
    selectedDateRange,
    setSelectedDateRange,
    timeFrom,
    setTimeFrom,
    timeTo,
    setTimeTo,
    combineDateWithTime,
  } = useDateRange();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Stack direction={isSmallScreen ? 'column' : 'row'} gap={2}>
      <Box>
        <Box
          sx={{
            marginBottom: '40px',
          }}
        >
          <span
            style={{
              color: '#747474',
              fontSize: '12px',
              fontWeight: '400',
            }}
          >
            Select Date Range
          </span>
          <Box>
            <span
              style={{
                fontSize: '24px',
                fontWeight: '500',
              }}
            >
              {selectedDateRange[0] !== null ? selectedDateRange[0].format('MMM D') : 'Start Date'}
            </span>{' '}
            -
            <span
              style={{
                fontSize: '24px',
                fontWeight: '500',
              }}
            >
              {selectedDateRange[1] !== null ? selectedDateRange[1].format('MMM D') : 'End Date'}
            </span>
          </Box>
        </Box>
        <Stack direction="column" gap={2}>
          <TimePicker
            label="From"
            ampm={false}
            value={timeFrom}
            timeSteps={{ minutes: 1 }}
            onChange={newValue => {
              const startTimestamp = combineDateWithTime(selectedDateRange[0], newValue);
              setSelectedDateRange([startTimestamp, selectedDateRange[1]]);
              setTimeFrom(newValue);
            }}
          />

          <TimePicker
            label="To"
            ampm={false}
            value={timeTo}
            timeSteps={{ minutes: 1 }}
            onChange={newValue => {
              const endTimestamp = combineDateWithTime(selectedDateRange[1], newValue);
              setSelectedDateRange([selectedDateRange[0], endTimestamp]);
              setTimeTo(newValue);
            }}
          />
        </Stack>
      </Box>
      {!isSmallScreen && <Divider orientation="vertical" />} {/* Hide divider on small screens */}
    </Stack>
  );
};

export default function DateAndTime({
  parent,
  onClose,
  widgetDateAndTime,
  setIsBottomBarOpen,
  setChangedWidgets,
  changedWidgets,
  showClock,
  setShowClock,
  setWidgetTimeFrame,
  dashboardDateAndTime,
  setTimeFrame,
  setDashboardSaveData,
  setSelectedTimeRange,
  setSelectedGranularity,
  timeFrame,
  setCustomGranularity,
  setParent,
  setCustomTimeRangeSettings,
  setSelectedChart,
  selectChart,
  dashboardId,
}: any) {
  //Widget level minimum settings (not applicable for dashboard!)
  const [minimumGranularity, setMinimumGranularity] = useState(null);
  const [minimumTimeRangeInMins, setMinimumTimeRangeInMins] = useState(null);

  //Dashboard & Widget level date & time settings
  const today = dayjs();
  const startOfToday = today.startOf('day');
  const endOfToday = today.endOf('day');
  const [selectedDateRange, setSelectedDateRange] = useState([startOfToday, endOfToday]);
  const [timeRangeType, setTimeRangeType] = useState('absolute');
  const [timeFrom, setTimeFrom] = useState<any>();
  const [timeTo, setTimeTo] = useState<any>();

  //Dashboard granularity settings
  const [selectedGranularity2, setSelectedGranularity2] = useState(null);
  const [unit, setUnit] = useState('days');
  const [unitMultiplier, setUnitMultiplier] = useState(1);

  const [selectChart2, setSelectChart2] = useState(selectChart);

  const setDateTimeRange = (startTime: any, endTime: any) => {
    const start = dayjs(startTime);
    const end = dayjs(endTime);
    setSelectedDateRange([start, end]);
    setTimeFrom(start);
    setTimeTo(end);
  };

  useEffect(() => {
    if (parent !== 'widget') {
      setDateTimeRange(timeFrame.startDate, timeFrame.endDate);
      setSelectedGranularity2(timeFrame.granularityLabel);
    } else {
      //Widget minimum settings
      if (widgetDateAndTime && widgetDateAndTime.factoryGranularitySettings) {
        setMinimumGranularity(
          widgetDateAndTime.factoryGranularitySettings.minAllowedGranularity.unit
        );
        setMinimumTimeRangeInMins(
          widgetDateAndTime.factoryTimeRangeSettings.minAllowedTimeRange.timeRangeInMins
        );
      }

      const existingWidgetIndex = changedWidgets?.findIndex(
        widget => widget.widgetId === widgetDateAndTime.widgetId
      );
      if (existingWidgetIndex !== -1) {
        setSelectedGranularity2(
          showClock == 'on'
            ? changedWidgets[existingWidgetIndex]?.selectedGranularity?.['unit']
            : timeFrame?.granularity
        );
        let selectedTimeRangeForWidget = changedWidgets[existingWidgetIndex]?.['selectedTimeRange'];

        let widgetTimeFrame =
          selectedTimeRangeForWidget && showClock == 'on'
            ? calculateTimeFrame(changedWidgets[existingWidgetIndex]['selectedTimeRange'])
            : timeFrame;
        setDateTimeRange(widgetTimeFrame.startDate, widgetTimeFrame.endDate);
      } else {
        //Apply widget custom settings if it is said so!
        if (widgetDateAndTime && widgetDateAndTime.advancedWidgetSettings?.applyCustomSettings) {
          setSelectedGranularity2(widgetDateAndTime.customGranularitySettings.unit);

          let widgetTimeFrame =
            calculateTimeFrame(widgetDateAndTime.customTimeRangeSettings) || timeFrame;
          setDateTimeRange(widgetTimeFrame.startDate, widgetTimeFrame.endDate);
        } else {
          setSelectedGranularity2(timeFrame.granularity);
          setDateTimeRange(timeFrame.startDate, timeFrame.endDate);
        }
      }
    }
  }, [widgetDateAndTime]);

  const handleDateRangeChange = (value: any, context: any) => {
    setSelectedDateRange(value);
    setTimeFrom(value[0]);
    setTimeTo(value[1]);
    const timeRangeMappings: any = {
      Today: { timeRangeType: 'relative', unitMultiplier: 0, unit: 'days' },
      'Last 24 Hours': {
        timeRangeType: 'relative',
        unitMultiplier: 1,
        unit: 'days',
      },
      Yesterday: {
        timeRangeType: 'relative',
        unitMultiplier: -1,
        unit: 'days',
      },
      'Last 3 Days': {
        timeRangeType: 'relative',
        unitMultiplier: 3,
        unit: 'days',
      },
      'Last 7 Days': {
        timeRangeType: 'relative',
        unitMultiplier: 7,
        unit: 'days',
      },
      'Last 10 Days': {
        timeRangeType: 'relative',
        unitMultiplier: 10,
        unit: 'days',
      },
      'Last 30 Days': {
        timeRangeType: 'relative',
        unitMultiplier: 30,
        unit: 'days',
      },
      'Last 3 Months': {
        timeRangeType: 'relative',
        unitMultiplier: 3,
        unit: 'months',
      },
      'Last 6 Months': {
        timeRangeType: 'relative',
        unitMultiplier: 6,
        unit: 'months',
      },
    };

    const mapping = timeRangeMappings[context?.shortcut?.label];
    if (mapping) {
      setTimeRangeType(mapping.timeRangeType);
      setUnitMultiplier(mapping.unitMultiplier);
      setUnit(mapping.unit);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const shouldProceedWithSelection = (selectedRangeInMinutes: number) => {
    console.log('selected range  : ', minimumTimeRangeInMins, selectedRangeInMinutes);
    if (
      (minimumTimeRangeInMins && selectedRangeInMinutes >= minimumTimeRangeInMins) ||
      parent === 'dashboard'
    ) {
      return true;
    }

    return false;
  };

  const handleApplyClick = () => {
    if (!selectedDateRange[0] || !selectedDateRange[1]) return;
    if (parent !== 'widget') {
      setIsBottomBarOpen(true);
      const startTimestamp = combineDateWithTime(selectedDateRange[0], timeFrom);
      const endTimestamp = combineDateWithTime(selectedDateRange[1], timeTo);
      handleDashboardUpdate(startTimestamp, endTimestamp);
      onClose();
    } else {
      const startTimestamp = combineDateWithTime(selectedDateRange[0], timeFrom);
      const endTimestamp = combineDateWithTime(selectedDateRange[1], timeTo);

      const selectedRangeInMinutes = endTimestamp.diff(startTimestamp, 'minute');

      if (shouldProceedWithSelection(selectedRangeInMinutes)) {
        setIsBottomBarOpen(true);
        handleWidgetUpdate(startTimestamp, endTimestamp);
        onClose();
      } else {
        setIsBottomBarOpen(false);
        toast.error('Selected range is less than minimum allowed time range!');
      }
      setSelectedChart(selectChart2);
    }
  };

  const calculateTimeRangeInMins = (startTimestamp: any, endTimestamp: any) => {
    return (endTimestamp.$d.valueOf() - startTimestamp.$d.valueOf()) / (1000 * 60);
  };

  const combineDateWithTime = (date: any, time: any) => {
    return dayjs(date)
      .set('hour', dayjs(time).hour())
      .set('minute', dayjs(time).minute())
      .set('second', dayjs(time).second())
      .set('millisecond', dayjs(time).millisecond());
  };

  const handleWidgetUpdate = (startTimestamp: any, endTimestamp: any) => {
    const updatedWidget = {
      dashboardId: dashboardId,
      widgetId: widgetDateAndTime?.widgetId || '',
      selectedGranularity: {
        unit: selectedGranularity2,
        unitMultiplier: 1,
      },
      selectedTimeRange: {
        unit,
        unitMultiplier,
        timeRangeType,
        startTime: startTimestamp.$d,
        endTime: endTimestamp.$d,
      },
      selectedChart: {
        widgetType: WIDGET_TYPE[selectChart2],
        graphType: GRAPH_TYPE[selectChart2],
      },
      applyCustomSettings: true,
    };

    const widgetTimeFrame = {
      startDate: startTimestamp.$d,
      endDate: endTimestamp.$d,
      granularity: selectedGranularity2.toLowerCase(),
      timeRangeType,
      granularityLabel: selectedGranularity2,
    };

    setShowClock('on');
    setParent('widget');
    setCustomTimeRangeSettings({
      unit,
      unitMultiplier,
      timeRangeInMins: calculateTimeRangeInMins(startTimestamp, endTimestamp),
      timeRangeType,
      startTime: startTimestamp.$d,
      endTime: endTimestamp.$d,
    });

    setCustomGranularity({
      unit: selectedGranularity2.toLowerCase(),
      unitMultiplier: 1,
      granularityInMins: TIME_UNITS[selectedGranularity2.toLowerCase()],
    });
    setWidgetTimeFrame(widgetTimeFrame);
    updateChangedWidgets(updatedWidget);
  };

  const updateChangedWidgets = (updatedWidget: any) => {
    const tempChangedWidgets = [...changedWidgets];
    const existingWidgetIndex = tempChangedWidgets.findIndex(
      widget => widget.widgetId === updatedWidget.widgetId
    );

    if (existingWidgetIndex !== -1) {
      tempChangedWidgets[existingWidgetIndex] = updatedWidget;
    } else {
      tempChangedWidgets.push(updatedWidget);
    }

    setChangedWidgets(tempChangedWidgets);
  };

  const handleDashboardUpdate = async (startTimestamp: any, endTimestamp: any) => {
    const timeFrame = {
      startDate: startTimestamp.$d,
      endDate: endTimestamp.$d,
      granularity: selectedGranularity2 !== null ? selectedGranularity2.toLowerCase() : '',
      timeRangeType,
      granularityLabel: selectedGranularity2,
      timeRangeLabel: determineTimeRangeLabel(timeRangeType, unitMultiplier, unit),
    };

    setSelectedGranularity(selectedGranularity2);
    setTimeFrame(timeFrame);
    setParent('dashboard');

    const updatedDashboard = {
      dashboardPageId: dashboardDateAndTime?.dashboardId || '',
      selectedGranularity: {
        unit: selectedGranularity2,
        unitMultiplier: 1,
      },
      selectedTimeRange: {
        unit,
        unitMultiplier,
        timeRangeType,
        startTime: timeFrame.startDate,
        endTime: timeFrame.endDate,
      },
    };

    setDashboardSaveData(updatedDashboard);

    const { startDate: startDate530Ahead, endDate: endDate530Ahead } = add530Hours(
      timeFrame?.startDate,
      timeFrame?.endDate
    );
    updateTimeFrameForRefId(
      startDate530Ahead,
      endDate530Ahead,
      updatedDashboard.dashboardPageId,
      'dashboardPage'
    ).then(() => {});
  };

  const determineTimeRangeLabel = (timeRangeType: any, unitMultiplier: any, unit: any) => {
    if (timeRangeType === 'relative') {
      for (let obj of TIME_RANGE) {
        if (obj.value === unitMultiplier && obj.unit === unit) {
          setSelectedTimeRange(obj.label);
          return obj.label;
        }
      }
    } else {
      setSelectedTimeRange('Custom');
      return TIME_RANGE[0].label;
    }
  };
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <DateRangeContext.Provider
      value={{
        selectedDateRange,
        setSelectedDateRange,
        timeFrom,
        setTimeFrom,
        timeTo,
        setTimeTo,
        combineDateWithTime,
      }}
    >
      <ThemeProvider theme={dpTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDateRangePicker
            slots={{
              toolbar: RelativeTimeToolbar,
            }}
            slotProps={{
              shortcuts: {
                items: shortcutsItems,
              },
              actionBar: { actions: [] },
            }}
            calendars={isSmallScreen ? 1 : 2} // Switch to 1 calendar on small screens
            onChange={handleDateRangeChange}
            value={selectedDateRange} // Pass selectedDateRange to reflect it on the calendar
            disableFuture={true}
            sx={{
              display: isSmallScreen ? 'flex' : 'grid',
              flexDirection: isSmallScreen ? 'column' : 'undefined',
              marginBottom: '30px',
              '.MuiStack-root': {
                order: 2,
              },
              '.MuiPickersLayout-shortcuts': {
                order: isSmallScreen ? 1 : 'undefined',
                display: 'flex',
                flexDirection: 'row', // Change to 'column' to stack the shortcuts vertically
                justifyContent: 'flex-start', // Adjust as needed
                alignItems: 'flex-start', // Adjust as needed
                gridRow: '1',
                gridColumn: '1/3',
                marginBottom: '30px',
                maxWidth: '800px',
                overflowX: 'auto', // Enable horizontal scrolling only on small screens
                scrollbarWidth: 'thin', // Firefox scrollbar width
                scrollbarColor: 'transparent transparent', // Firefox scrollbar color
                msOverflowStyle: 'none', // Hide scrollbar in IE and Edge
              },
              '.MuiPickersLayout-contentWrapper': {
                alignItems: 'center',
                order: isSmallScreen ? 3 : 'undefined',
              },
              '.MuiPickersLayout-shortcuts::-webkit-scrollbar': {
                display: 'none',
              },
              '.MuiListItem-root': {
                width: 'auto',
              },
              '.MuiPickersLayout-toolbar': {
                gridRow: '2',
                gridColumn: '1',
                alignSelf: 'center',
              },
              '.Mui-selected.MuiDateRangePickerDay-day': {
                backgroundColor: '#006582',
              },
              '.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
                backgroundColor: '#DDF8FB',
              },
              '.MuiDateRangePickerDay-hiddenDayFiller': {
                backgroundColor: 'white !important',
              },
              '.MuiChip-clickableColorDefault': {
                // backgroundColor: "#006582",
              },
            }}
          />
          <Stack
            direction={isSmallScreen ? 'column' : 'row'} // Stack vertically on small screens
            justifyContent="space-between"
            spacing={isSmallScreen ? 2 : 0}
          >
            <Stack direction="row" spacing={4}>
              <Granularity
                selectedDateRange={selectedDateRange}
                selectedGranularity2={selectedGranularity2}
                setSelectedGranularity2={setSelectedGranularity2}
                minimumGranularity={minimumGranularity}
                parent={parent}
              />
              {parent === 'widget' && (
                <Chart selectChart={selectChart} setSelectChart2={setSelectChart2} />
              )}
            </Stack>
            <Stack direction="row" alignSelf="flex-end" spacing={2}>
              <DialogNegativeButtonStyle
                sx={[DateAndTimeStyles.ButtonStyles]}
                onClick={handleCancel}
              >
                <Typography variant="h6">Cancel</Typography>
              </DialogNegativeButtonStyle>
              <PrimaryButton
                type="submit"
                sx={DateAndTimeStyles.ButtonStyles}
                onClick={handleApplyClick}
                disabled={!selectedDateRange[0] || !selectedDateRange[1]}
              >
                <Typography variant="h6">Apply</Typography>
              </PrimaryButton>
            </Stack>
          </Stack>
        </LocalizationProvider>
      </ThemeProvider>
    </DateRangeContext.Provider>
  );
}
