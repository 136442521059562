export default function WaterDropIcon({ fillColor = '#3DA385', width = '14', height = '18' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.9391 11.725C7.15579 12.5442 6.09831 13 5.00065 13C3.903 13 2.84551 12.5442 2.0622 11.725C1.27817 10.905 0.833984 9.7883 0.833984 8.61946C0.833984 7.40123 1.41151 6.20847 2.65483 5.16823C3.68857 4.30334 4.47344 2.78284 4.96464 1.36718C5.48472 2.78878 6.31641 4.30642 7.34647 5.16823C8.58979 6.20847 9.16732 7.40123 9.16732 8.61946C9.16732 9.7883 8.72313 10.905 7.9391 11.725ZM4.96149 1.13851C4.96151 1.13851 4.96153 1.13851 4.96155 1.13851C4.95979 1.13875 4.95974 1.13852 4.96149 1.13851Z"
        stroke={fillColor}
      />
    </svg>
  );
}
