import { Box, Stack, TextField, Select, InputLabel, FormControl, MenuItem } from '@mui/material';
import { useEffect } from 'react';
import { WIDGET_THEMES } from '../../../constants/widgetConfig.constants';
import { UNIT_OPTION_LIST } from '../../../constants/units.contants';

interface WidgetLevelSettingsForSankey {
  widget: any;
  setRefresh: Function;
}

export default function WidgetLevelSettingsForSankey({
  widget,
  setRefresh,
}: WidgetLevelSettingsForSankey) {
  useEffect(() => {
    console.log('189 : ', widget);
  }, []);

  const handleWidgetPropertyChange = (property: string, value: any) => {
    if (widget?.[property]) widget[property] = value;
    setRefresh((state: boolean) => !state);
  };

  const handleWidgetSubPropertyChange = (property: string, subProperty: string, value: any) => {
    if (widget?.[property]?.[subProperty]) widget[property][subProperty] = value;
    else if (widget?.[property]) {
      widget[property] = { ...widget[property], [subProperty]: value };
    }
    setRefresh((state: boolean) => !state);
  };

  return (
    <Stack direction="column" gap={2}>
      <TextField
        id="outlined-basic"
        label="Widget Name"
        variant="outlined"
        type="text"
        fullWidth
        defaultValue={widget.widgetName || ''}
        onBlur={event => {
          handleWidgetPropertyChange('widgetName', event.target.value);
        }}
      />
      <Box sx={{ width: '100%' }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select">Select Widget Theme</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select Widget Theme"
            value={widget.widgetLook?.theme}
            onChange={e => {
              handleWidgetSubPropertyChange('widgetLook', 'theme', e.target.value);
            }}
          >
            {Object.keys(WIDGET_THEMES).map(key => {
              return <MenuItem value={key}>{key}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ width: '100%' }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select">Select Unit Of this Widget</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select Unit Of this Widget"
            value={widget.commonInfo?.widgetUnit || 'none'}
            onChange={e => {
              handleWidgetSubPropertyChange('commonInfo', 'widgetUnit', e.target.value);
            }}
          >
            {UNIT_OPTION_LIST.map(unit => (
              <MenuItem value={unit.value}>{unit.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Stack>
  );
}
