const UNIT_OPTION_LIST = [
  { label: 'Litres', value: 'l' },
  { label: 'Kilo Litres', value: 'kl' },
  { label: 'Kl/h', value: 'kl/h' },
  { label: 'Metres', value: 'm' },
  { label: '%', value: '%' },
  { label: 'Kg', value: 'kg' },
  { label: 'NTU', value: 'NTU' },
  { label: 'None', value: '' },
  { label: 'pH', value: 'pH' },
];

export { UNIT_OPTION_LIST };
