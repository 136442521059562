const isNameProperlyFormatted = (name: string) => {
  // Allow alphanumeric characters, spaces, and common punctuation
  // var regex = /^[a-zA-Z0-9 ,.'"\-()]+$/;

  // Check if the name is at least 2 characters long
  if (name.trim().length < 2) return false;

  // Test the name against the regex
  // if (!regex.test(name)) return false;

  // If all checks pass, return true
  return true;
};

export { isNameProperlyFormatted };
