//@ts-nocheck
import React from 'react';
import { useState, useRef } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Chip,
  IconButton,
  Tooltip,
  setRef,
  Dialog,
  DialogContent,
} from '@mui/material';
import { useEffect } from 'react';
import axiosInstance from '../../../constants/axiosInstance';

import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { toast } from 'react-toastify';
import TableRowsIcon from '@mui/icons-material/TableRows';
import PinIcon from '@mui/icons-material/Pin';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import MetricConfigComponent from '../../metricConfigComponent/metricConfigComponent';
import MetricConfigComponentV2 from './metricConfigComponentv2';
import { pageConfigDataInterface } from '../../../Interfaces/dashboardPageInterfaces';
import { widgetInterface } from '../../../Interfaces/widgetInterfaces';
import {
  lastMovedWidgetInterface,
  metricDiagnosisDataInterface,
} from '../../../Interfaces/pageConfigPageInterfaces';
import GranularitySettings from './granularitySetting';
import TimeRangeSettings from './timeRangeSetting';
import MetricSearchBar from './metricSearchBar';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import DeleteConfirmation from '../../../globalComponents/dialogs/DeleteConfirmation';
import { IInflatedDashboadPage } from '../../../Interfaces/newDashboardInterfaces/inflatedDashbaordPage.interfaces';
import SellIcon from '@mui/icons-material/Sell';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import StandaloneGeneralisedWidget from '../../GeneralisedWidgetComponents/StandaloneGeneralisedWidget';
import { useDashboardStore } from '../../../store/DashboardStore';
import { usePlantLayoutStore } from '../../../store/PlantLayoutStore';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import lodash from 'lodash';
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import { ArrowCircleUpRounded } from '@mui/icons-material';
import { ReactComponent as ArrowUpIcon } from '../../../constants/Images/ArrowDownIcon.svg';
import { ReactComponent as ArrowDownIcon } from '../../../constants/Images/ArrowDownIcon.svg';
import FactoryIcon from '@mui/icons-material/Factory';
import { add530Hours } from '../../../utils/dashboard.utils';
import { start } from 'repl';
import ValidRangeSettings from '../../widgetConfigComponentsV2/ValidRangeSettings';
import GeneralMetricSearchBar from '../../workspace/GeneralMetricSearchBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MapMetricConfig from './MapMetricConfig';
import MapPlantConfig from './MapPlantConfig';
import { MAX_ALLOWED_SENSOR_VALUE, MIN_ALLOWED_SENSOR_VALUE } from '../../../constants/constants';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import MapWidgetLevelConfig from './MapWidgetLevelConfig';
import { resolvePackageData } from 'vite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapFiltersConfig from './MapFiltersConfig';
import { RANGE_OBJ } from '../constants/range.constant';
import { truncateText } from '../../../utilities/string.utils';

const styles = {
  widgetConfigComponentAccordion: {
    maxHeight: '544px',
    overflowY: 'scroll',
    background: '#F0F0F0',
    border: '1px solid #BDBDBD',
  },
  widgetConfigSummaryContainer: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
  },
  widgetConfigSummaryActionButtonContainer: {
    display: 'flex',
    marginLeft: 'auto',
    color: '#444',
    alignItems: 'center',
  },
  textBoxStyle: {
    background: 'white',
    '& fieldset': { border: 'none' },
  },

  metricDiagnosisLoading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#eee',
    width: '400px',
    minHeight: '100px',
    borderRadius: '5px',
  },
  metricDiagnosisNotOk: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFCCCB',
    width: '400px',
    minHeight: '100px',
    borderRadius: '5px',
  },
  metricDiagnosisOk: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#addfad',
    width: '400px',
    minHeight: '100px',
    borderRadius: '5px',
  },
};

interface propsInterface {
  pageData: IInflatedDashboadPage['pageConfig'];
  setPageData: React.Dispatch<React.SetStateAction<IInflatedDashboadPage['pageConfig'] | null>>;
  widgetCluster: string;
  widgetClusterIndex: number;
  widget: widgetInterface;
  widgetIndex: number;
  moveWidgetPosition: (widgetClusterIndec: number, widgetIndex: number, action: string) => void;
  lastMovedWidget: lastMovedWidgetInterface;
  sensorList: any[];
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  setDeletedWidgets: Function;
}

const currentTime = new Date();
const midnight = new Date();
midnight.setHours(0, 0, 0, 0);
// const startDate = new Date(endDate.valueOf() - 24 * 60 * 60 * 1000);
// const { startDate, endDate } = add530Hours(midnight, currentTime);
// console.log('startDate : ', startDate);

export default function DnDMapWidgetConfigPopup(props: propsInterface) {
  const {
    pageData,
    setPageData,
    widgetCluster,
    widgetClusterIndex,
    widget,
    widgetIndex,
    moveWidgetPosition,
    lastMovedWidget,
    refresh,
    setRefresh,
    sensorList,
    setDeletedWidgets,
    open,
    handleClose,
  } = props;
  const valueTypes = ['raw', 'avg', 'min', 'max', 'timeWeightedSum', 'cumulative'];
  const [search, setSearch] = useState('');
  const [openDiagnosisBox, setOpenDiagnosisBox] = useState(false);
  const [diagnosing, setDiagnosing] = useState(false);
  const [areMultipleSensorSelected, setAreMultipleSensorSelected] = useState();
  const [metricDiagnosisData, setMetricDiagnosisData] =
    useState<metricDiagnosisDataInterface | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [refreshStandAloneWidget, setRefreshStandAloneWidget] = useState(false);
  const [selectedPlantIndex, setSelectedPlantIndex] = useState(0);
  const [selectedSettings, setSelectedSettings] = useState('widgetLevelSettings');
  const [selectedSensorIndex, setSelectedSensorIndex] = useState(0);
  const [selectedMapFilterIndex, setSelectedMapFilterIndex] = useState(0);
  const [plantSearch, setPlantSearch] = useState('');

  const nickNameRef = useRef(null);

  const changeWidgetProperty = (
    widgetClusterIndex: number,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex][property] = value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  const changeAdvancedWidgetProperty = (
    widgetClusterIndex: number,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex]['advancedWidgetSettings'][property] = value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(lastState => !lastState);
  };

  const changeWidgetMinAllowedGranularitySettings = (
    widgetClusterIndex: string,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex]['factoryGranularitySettings'][
      'minAllowedGranularity'
    ][property] = value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  const changeWidgetCustomGranularitySettings = (
    widgetClusterIndex: string,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex]['customGranularitySettings'][property] =
      value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  const changeWidgetMinAllowedTimeRangeSettings = (
    widgetClusterIndex: string,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex]['factoryTimeRangeSettings'][
      'minAllowedTimeRange'
    ][property] = value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  const changeWidgetCustomTimeRangeSettings = (
    widgetClusterIndex: string,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex]['customTimeRangeSettings'][property] = value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  const deleteMapWidget = (widgetClusterIndex: string, widgetIndex: number) => {
    const widgetToBeDeleted = pageData.data[widgetClusterIndex].widgets[widgetIndex];
    let widgetName = widgetToBeDeleted.widgetName;

    pageData.data[widgetClusterIndex].widgets.splice(widgetIndex, 1);

    const summaryDeletedWidget = {
      id: widgetToBeDeleted._id,
      widgetType: widgetToBeDeleted.widgetType,
    };

    setDeletedWidgets(previousDeletedWidgets => {
      return [...previousDeletedWidgets, summaryDeletedWidget];
    });
    setRefresh(!refresh);
    toast(`${widgetName} deleted Successfully!`);
  };

  const initiateMetricDiagnosis = (metricId: string) => {
    setOpenDiagnosisBox(true);
    setDiagnosing(true);
    axiosInstance()
      .get(`/dashboard/getMetricDiagnosis?sensorId=${metricId}`)
      .then(({ data }) => {
        console.log('metric diagnostic data : ', data);
        setMetricDiagnosisData(data.data);
        setDiagnosing(false);
      })
      .catch(err => {});
  };

  const isNameIsProperlyFormatted = (name: string) => {
    if (name === '') return false;
    else return true;
  };

  const cloneWidget = (widget: any, widgetClusterIndex: number) => {
    let cloneWidget = lodash.cloneDeep(widget);
    cloneWidget['cloneOf'] = cloneWidget._id;
    cloneWidget['widgetOrigin'] = 'custom';
    pageData.data[widgetClusterIndex].widgets.push(cloneWidget);
    setRefresh(!refresh);
    toast(`${widget.widgetName} cloned Successfully!`);
  };

  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const handleShowTicketCheckBox = checked => {
    widget.plants[selectedPlantIndex]['showTickets'] = checked;
    setRefresh(!refresh);
  };

  const handleIsPlantScoreCheckBoxChange = (checked: boolean) => {
    if (widget.plants[selectedPlantIndex]?.sensors[selectedSensorIndex])
      widget.plants[selectedPlantIndex].sensors[selectedSensorIndex]['isPlantHealthScoreSensor'] =
        checked;
    setRefresh(!refresh);
  };

  const handleSensorSubPropertyChange = (property, subProperty, value) => {
    if (widget.plants[selectedPlantIndex]?.sensors[selectedSensorIndex]?.[property] != null) {
      widget.plants[selectedPlantIndex].sensors[selectedSensorIndex][property][subProperty] = value;
      setRefresh(!refresh);
    } else if (widget.plants[selectedPlantIndex]?.sensors[selectedSensorIndex] != null) {
      widget.plants[selectedPlantIndex].sensors[selectedSensorIndex][property] = {
        [subProperty]: value,
      };
      //   setPageData(pageData);
      setRefresh(!refresh);
    }
  };

  const handleMapFilterRangeChange = (property, subProperty, value) => {
    if (widget.mapFilters?.[selectedMapFilterIndex]?.['range']?.[property]?.[subProperty] != null) {
      widget.mapFilters[selectedMapFilterIndex]['range'][property][subProperty] = value;
    }
    setRefresh(!refresh);
  };

  const handleSensorValueTypeCustPropertyChange = (property, subProperty, value) => {
    if (
      widget.plants[selectedPlantIndex]?.sensors[selectedSensorIndex]['valueTypeCust']?.[
        property
      ] != null
    ) {
      widget.plants[selectedPlantIndex].sensors[selectedSensorIndex]['valueTypeCust'][property][
        subProperty
      ] = value;
    } else if (
      widget.plants[selectedPlantIndex]?.sensors[selectedSensorIndex]['valueTypeCust'] != null
    ) {
      widget.plants[selectedPlantIndex].sensors[selectedSensorIndex]['valueTypeCust'][property] = {
        [subProperty]: value,
      };
    }
    setRefresh(!refresh);
  };

  const changeMetricProperty = (property: string, value: any) => {
    if (widget.plants[selectedPlantIndex]?.sensors[selectedSensorIndex] != null) {
      widget.plants[selectedPlantIndex].sensors[selectedSensorIndex][property] = value;
      setRefresh(!refresh);
    }
  };

  const changeMetricFilterProperty = (values: any) => {
    if (widget.plants[selectedPlantIndex]?.sensors[selectedSensorIndex] != null) {
      widget.plants[selectedPlantIndex].sensors[selectedSensorIndex]['filtersConfig'] = {
        representsFiltersForPlant: values,
      };

      setRefresh(!refresh);
    }
  };

  const filterSensorListForSelectedPlant = () => {
    return sensorList.filter(
      sensor =>
        sensor.storeId || sensor.plantId?.toString() == widget.plants[selectedPlantIndex]?.plantId
    );
  };

  const handleWidgetLookChange = (property: string, value: any) => {
    if (widget.widgetLook != null) {
      widget.widgetLook[property] = value;
    } else {
      widget.widgetLook = { [property]: value };
    }
    setRefresh(!refresh);
  };

  const handleMapFilterPropertyChange = (property: string, value: any) => {
    if (widget.mapFilters?.[selectedMapFilterIndex] != null) {
      if (property == 'name') {
        const prevName = widget.mapFilters?.[selectedMapFilterIndex]?.name;

        for (const plant of widget.plants) {
          plant.sensors?.forEach(sensor => {
            if (sensor?.filtersConfig?.representsFiltersForPlant?.includes(prevName)) {
              const index = sensor?.filtersConfig?.representsFiltersForPlant?.indexOf(prevName);
              sensor?.filtersConfig?.representsFiltersForPlant?.splice(index, 1);
              sensor?.filtersConfig?.representsFiltersForPlant?.push(value);
            }
          });
        }
      }

      if (property == 'default' && value == true) {
        widget.mapFilters?.forEach(filter => {
          filter['default'] = false;
        });
      }
      widget.mapFilters[selectedMapFilterIndex][property] = value;
    }
    setRefresh(!refresh);
  };

  const handleMetricFilterRangeChange = (property, subProperty, value) => {
    if (
      widget.plants[selectedPlantIndex]?.sensors[selectedSensorIndex]?.['filtersConfig']?.[
        'filtersRangeConfig'
      ]?.[property]?.[subProperty] != null
    ) {
      widget.plants[selectedPlantIndex].sensors[selectedSensorIndex]['filtersConfig'][
        'filtersRangeConfig'
      ][property][subProperty] = value;
    } else {
      widget.plants[selectedPlantIndex].sensors[selectedSensorIndex]['filtersConfig'][
        'filtersRangeConfig'
      ] = RANGE_OBJ;
    }
    setRefresh(!refresh);
  };

  const handleWidgetPropertyChange = (property: string, value: any) => {
    if (widget[property] != null) {
      widget[property] = value;
    }
    setRefresh(!refresh);
  };

  const handlePlantPropertyChange = (property: string, value: any) => {
    if (widget?.plants?.[selectedPlantIndex] != null) {
      widget.plants[selectedPlantIndex][property] = value;
    }
    setRefresh(!refresh);
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        sx={{}}
        fullWidth
        PaperProps={{
          style: {
            width: '80%', // Increase the width
            maxWidth: '80%', // Ensure the dialog doesn't grow beyond this width
          },
        }}
      >
        <Accordion
          sx={styles.widgetConfigComponentAccordion}
          elevation={0}
          TransitionProps={{ mountOnEnter: true, timeout: 500 }}
          disableGutters={true}
          expanded={true}
        >
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <div style={styles.widgetConfigSummaryContainer}>
              <Checkbox
                checked={widget.checked}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  e.stopPropagation();
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  changeWidgetProperty(
                    widgetClusterIndex,
                    widgetIndex,
                    'checked',
                    e.target.checked
                  );
                }}
              />
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} gap={1}>
                <div>{widget.widgetName}</div>
              </Stack>
              <span style={styles.widgetConfigSummaryActionButtonContainer}>
                <Tooltip title="Clone">
                  <IconButton
                    color="inherit"
                    onClick={e => {
                      e.stopPropagation();
                      cloneWidget(widget, widgetClusterIndex);
                    }}
                  >
                    <FileCopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                {(widget.widgetOrigin == 'custom' || widget.widgetType == 'MapWidget') && (
                  <Tooltip title="Delete">
                    <IconButton
                      color="inherit"
                      onClick={e => {
                        e.stopPropagation();
                        setShowDeleteModal(true);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Shift Up">
                  <IconButton
                    color="inherit"
                    onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                      e.stopPropagation();
                      moveWidgetPosition(widgetClusterIndex, widgetIndex, 'up');
                    }}
                  >
                    <ArrowUpIcon
                      fill={
                        widget.widgetName == lastMovedWidget.widgetName &&
                        lastMovedWidget.direction == 'up'
                          ? '#018749'
                          : '#333'
                      }
                      width="18px"
                      height="18px"
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Shift Down">
                  <IconButton
                    color="inherit"
                    onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                      e.stopPropagation();
                      moveWidgetPosition(widgetClusterIndex, widgetIndex, 'down');
                    }}
                  >
                    <ArrowDownIcon
                      fill={
                        widget.widgetName == lastMovedWidget.widgetName &&
                        lastMovedWidget.direction == 'down'
                          ? '#de1738'
                          : '#333'
                      }
                      width="18px"
                      height="18px"
                    />
                  </IconButton>
                </Tooltip>
                <DeleteConfirmation
                  showDeleteModal={showDeleteModal}
                  setShowDeleteModal={setShowDeleteModal}
                  alertMessage="Are you sure you want to delete this widget? This action is irreversible!"
                  processing={processing}
                  setProcessing={setProcessing}
                  onConfirmAction={() => {
                    deleteMapWidget(widgetClusterIndex, widgetIndex);
                  }}
                />
              </span>
              <span style={{}}>
                {(widget.widgetType == 'tableD' || widget.widgetType == 'tableC') && (
                  <TableRowsIcon fontSize="medium" />
                )}
                {widget.widgetType == 'graph' && <AssessmentIcon fontSize="medium" />}
                {widget.widgetType == 'number' && <PinIcon fontSize="medium" />}
                {widget.widgetType == 'MapWidget' && <LocationOnIcon fontSize="medium" />}
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowGap={2}>
              <Grid item xs={12} sm={4} md={3} lg={3} p={1}>
                <Box mb={2} sx={{ fontWeight: 500 }}></Box>
                <Stack
                  direction="column"
                  border="1px solid black"
                  maxHeight={280}
                  sx={{ overflowY: 'scroll' }}
                >
                  {widget.plants
                    .filter(plant =>
                      plant.plantName?.toLowerCase().includes(plantSearch?.toLowerCase())
                    )
                    .map((plant: any, plantIndex: number) => {
                      return (
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          onClick={event => {
                            setSelectedPlantIndex(plantIndex);
                            setSelectedSensorIndex(0);
                            setSelectedSettings('plantLevelSettings');
                            setRefresh(!refresh);
                          }}
                          p={1}
                          borderBottom={plantIndex != widget.plants?.length - 1 ? 1 : 0}
                          sx={{
                            background: plantIndex == selectedPlantIndex ? 'lightblue' : '#fff',
                          }}
                        >
                          <Box>{plant.plantName}</Box>
                          <Box>{plant.sensors?.length || 0}</Box>
                        </Stack>
                      );
                    })}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} p={1}>
                {widget.plants?.[selectedPlantIndex] && (
                  <Box>
                    <GeneralMetricSearchBar
                      sensorList={filterSensorListForSelectedPlant()}
                      keywordFilterList={[]}
                      addedSensorList={widget?.plants?.[selectedPlantIndex]?.sensors || []}
                      handleAddSensor={sensor => {
                        let modifiedSensor = lodash.cloneDeep(sensor);

                        modifiedSensor['valueTypes'] = ['raw'];

                        modifiedSensor['valueTypeCust'] = {};
                        modifiedSensor['filtersConfig'] = {
                          representsFiltersForPlant: [],
                          filtersRangeConfig: RANGE_OBJ,
                        };

                        if (widget.plants[selectedPlantIndex]?.sensors) {
                          widget.plants[selectedPlantIndex].sensors.push(modifiedSensor);
                        } else {
                          widget.plants[selectedPlantIndex].sensors = [];
                          widget.plants[selectedPlantIndex].sensors.push(modifiedSensor);
                        }
                        setRefresh(!refresh);
                      }}
                    />
                    <Box
                      // border={1}
                      p={1}
                      paddingLeft={2}
                      borderRadius={2}
                      sx={{
                        background: '#ddd',
                        width: '100%',
                        boxSizing: 'border-box',
                      }}
                      mt={1}
                    >
                      <FormLabel component="legend">
                        Added Sensors {`${widget.plants[selectedPlantIndex]?.sensors?.length || 0}`}
                      </FormLabel>
                      <Box
                        sx={{
                          height: '250px',
                          overflowY: 'scroll',
                        }}
                      >
                        {widget.plants[selectedPlantIndex]?.sensors?.map((metric, metricIndex) => {
                          let metricName = metric.nickName ? metric.nickName : metric.sensorTag;
                          if (metricName?.toLowerCase().includes(search.toLowerCase()))
                            return (
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                onClick={() => {
                                  setSelectedSensorIndex(metricIndex);
                                  setSelectedSettings('sensorLevelSettings');
                                  setRefresh(!refresh);
                                }}
                                p={1}
                                sx={{
                                  background:
                                    selectedSensorIndex == metricIndex ? 'lightBlue' : '#fff',
                                }}
                              >
                                <Stack direction="row" alignItems="center" gap={1}>
                                  {' '}
                                  <Tooltip title={metric.sensorTag}>
                                    <Box
                                      sx={
                                        metric.sensorNickName
                                          ? {
                                              color: 'green',
                                              maxWidth: '250px',
                                              overflowWrap: 'break-word',
                                            }
                                          : { maxWidth: '250px', overflowWrap: 'break-word' }
                                      }
                                    >
                                      {metric.sensorNickName
                                        ? metric.sensorNickName
                                        : metric.sensorTag}
                                    </Box>
                                  </Tooltip>
                                  {metric?.filtersConfig?.representsFiltersForPlant?.includes(
                                    widget?.mapFilters?.[selectedMapFilterIndex]?.name
                                  ) && <DeviceThermostatIcon fontSize="22" sx={{ color: 'red' }} />}
                                </Stack>
                                <IconButton
                                  color="inherit"
                                  onClick={e => {
                                    e.stopPropagation();
                                    widget.plants[selectedPlantIndex]?.sensors.splice(
                                      metricIndex,
                                      1
                                    );
                                    setRefresh(!refresh);
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Stack>
                            ); //// metric config component
                        })}
                      </Box>
                    </Box>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={4} md={5} lg={5} p={1}>
                <Stack direction="column" gap={2}>
                  <Tabs
                    value={selectedSettings}
                    onChange={(event, newValue) => {
                      setSelectedSettings(newValue);
                    }}
                    aria-label="full width tabs example"
                    sx={{ alignSelf: 'flex-start', maxWidth: '500px' }}
                    //   variant="fullWidth"
                  >
                    <Tab
                      label="Widget Config"
                      value="widgetLevelSettings"
                      wrapped
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={
                        <Stack direction="column">
                          <Box>Plant Config</Box>
                          <Box
                            sx={{ fontSize: '9px' }}
                          >{`(${truncateText(widget?.plants?.[selectedPlantIndex]?.plantName || '', 15)})`}</Box>
                        </Stack>
                      }
                      value="plantLevelSettings"
                      wrapped
                      // {...a11yProps(0)}
                    />
                    <Tab
                      label={
                        <Stack direction="column">
                          <Box>Sensor Config</Box>
                          <Box sx={{ fontSize: '9px' }}>
                            {`(${truncateText(
                              widget?.plants?.[selectedPlantIndex]?.sensors?.[selectedSensorIndex]
                                ?.sensorNickName ||
                                widget?.plants?.[selectedPlantIndex]?.sensors?.[selectedSensorIndex]
                                  ?.sensorTag ||
                                '',
                              15
                            )})`}
                          </Box>
                        </Stack>
                      }
                      value="sensorLevelSettings"
                      wrapped
                      // {...a11yProps(0)}
                    />
                    <Tab
                      label={
                        <Stack direction="column">
                          <Box>Map Filters</Box>
                          <Box
                            sx={{ fontSize: '9px' }}
                          >{`(${widget?.mapFilters?.[selectedMapFilterIndex]?.name})`}</Box>
                        </Stack>
                      }
                      value="mapFilters"
                      wrapped
                      // {...a11yProps(0)}
                    />
                  </Tabs>
                  {selectedSettings === 'widgetLevelSettings' && (
                    <MapWidgetLevelConfig
                      widgetConfig={widget}
                      handleWidgetLookChange={handleWidgetLookChange}
                      handleWidgetPropertyChange={handleWidgetPropertyChange}
                    />
                  )}
                  {selectedSettings === 'plantLevelSettings' && (
                    <MapPlantConfig
                      plantConfig={widget.plants[selectedPlantIndex]}
                      handleChange={handleShowTicketCheckBox}
                      handlePlantPropertyChange={handlePlantPropertyChange}
                    />
                  )}{' '}
                  {selectedSettings === 'sensorLevelSettings' &&
                    widget.plants[selectedPlantIndex]?.sensors?.[selectedSensorIndex] && (
                      <MapMetricConfig
                        sensorConfig={
                          widget.plants[selectedPlantIndex]?.sensors?.[selectedSensorIndex]
                        }
                        handleSensorSubPropertyChange={handleSensorSubPropertyChange}
                        changeMetricProperty={changeMetricProperty}
                        handleSensorValueTypeCustPropertyChange={
                          handleSensorValueTypeCustPropertyChange
                        }
                        mapFilters={widget.mapFilters}
                        changeMetricFilterProperty={changeMetricFilterProperty}
                        handleMetricFilterRangeChange={handleMetricFilterRangeChange}
                      />
                    )}
                  {selectedSettings === 'mapFilters' && (
                    <MapFiltersConfig
                      mapFilters={widget.mapFilters}
                      setSelectedMapFilterIndex={setSelectedMapFilterIndex}
                      handleMapFilterRangeChange={handleMapFilterRangeChange}
                      selectedMapFilterIndex={selectedMapFilterIndex}
                      handleMapFilterPropertyChange={handleMapFilterPropertyChange}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Modal
          open={openDiagnosisBox}
          onClose={() => {
            setOpenDiagnosisBox(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {diagnosing ? (
            <Box p={1} sx={styles.metricDiagnosisLoading}>
              <p style={{ textAlign: 'center' }}>running sensor diagnosis.... </p>
            </Box>
          ) : (
            <Box
              p={1}
              sx={
                metricDiagnosisData && metricDiagnosisData?.problems?.length > 0
                  ? styles.metricDiagnosisNotOk
                  : styles.metricDiagnosisOk
              }
            >
              <Box>
                <h3>Sensor Diagnosis completed</h3>
                <p>sensor exists in Db : {metricDiagnosisData?.sensorExists ? 'YES' : 'No'}</p>
                <p>sensorId : {metricDiagnosisData?.sensorId}</p>
                <p>created on :{metricDiagnosisData?.dateCreated}</p>
                <p>sensorType : {metricDiagnosisData?.sensorType}</p>
                <p>last observed Value : {metricDiagnosisData?.lastValue}</p>
                <p>last Observed time : {metricDiagnosisData?.lastValueTime}</p>
                <p>
                  problem Diagnosis -
                  {metricDiagnosisData && metricDiagnosisData?.problems?.length > 0 ? (
                    metricDiagnosisData?.problems?.map((problem: string, index: number) => {
                      return <p>{index + 1 + '. ' + problem}</p>;
                    })
                  ) : (
                    <span>No problems found, sensor Ok</span>
                  )}
                </p>
              </Box>
            </Box>
          )}
        </Modal>
      </Dialog>
    </>
  );
}
