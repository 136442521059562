import { useRef, useState } from 'react';
import { IconButton, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import useOutsideClickDetector from '../../utilities/customHooks/useOutsideClickDetector';
import { delay } from '../../utils/time.utils';
import HeadingWidgetConfigPopup from './subComponents/HeadingWidgetConfigPopup';
import { HeadingWidget } from '../../Interfaces/newDashboardInterfaces/inflatedDashbaordPage.interfaces';
import { isValidColorString } from '../../utils/color.utils';
import { useUnifiedDashboardStore } from '../../store/UnifiedDashboardStore';

interface propsInterface {
  widget: HeadingWidget;
  widgetClusterIndex: number;
  widgetIndex: number;
  text: string;
  changeHeadingWidgetHeading: Function;
  deleteCustomWidget: Function;
}

export default function DnDHeadingWidgetConfig(props: propsInterface) {
  const {
    widget,
    widgetClusterIndex,
    widgetIndex,
    text,
    changeHeadingWidgetHeading,
    deleteCustomWidget,
  } = props;

  //@ts-ignore
  const [allowEdit, setAllowEdit] = useState<boolean>(false);
  const textFieldRef = useRef(null);
  const [openWidgetConfig, setOpenWidgetConfig] = useState(false);
  //@ts-ignore
  const [showConfigHeader, setShowConfigHeader] = useState(false);
  const { setAllowDrag } = useUnifiedDashboardStore();

  useOutsideClickDetector(textFieldRef, async () => {
    await delay(500);
    setAllowEdit(false);
  });

  return (
    // <Stack direction="row" alignItems="flex-start" gap={2} px={1}>
    <Box
      sx={{ alignItems: 'center' }}
      onMouseEnter={() => {
        setShowConfigHeader(true);
      }}
      onMouseLeave={() => {
        setShowConfigHeader(false);
      }}
    >
      <span
        style={{
          fontSize: !isNaN(parseInt(widget.font?.size)) ? parseInt(widget.font?.size) : '20px',
          color: isValidColorString(widget.font?.color) ? widget.font?.color : '#000',
          fontWeight: !isNaN(widget.font?.fontWeight) ? widget.font?.fontWeight : '500',
          // display: 'inline',
          wordBreak: 'break-all',
        }}
      >
        {text}
      </span>
      {showConfigHeader && (
        <span style={{ marginLeft: '5px' }}>
          <IconButton
            sx={{
              zIndex: 1000,
              width: 22,
              height: 22,
            }}
            onMouseEnter={() => {
              setAllowDrag(false);
            }}
            onMouseLeave={() => {
              setAllowDrag(true);
            }}
            onClick={event => {
              event.stopPropagation();
              console.log('clicked pen');
              console.log('here1234');
              setShowConfigHeader(false);
              setOpenWidgetConfig(true);
            }}
          >
            <EditIcon
              sx={{
                fontSize: 18,
                display: 'inline',
                textAlign: 'center',
              }}
            />
          </IconButton>
        </span>
      )}

      <HeadingWidgetConfigPopup
        widget={widget}
        widgetClusterIndex={widgetClusterIndex}
        widgetIndex={widgetIndex}
        text={text}
        changeHeadingWidgetHeading={changeHeadingWidgetHeading}
        deleteCustomWidget={deleteCustomWidget}
        open={openWidgetConfig}
        handleClose={() => {
          setOpenWidgetConfig(false);
          setShowConfigHeader(true)
        }}
      />
    </Box>
    //  </Stack>
  );
}
