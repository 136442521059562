import { Dialog } from '@mui/material';
import SankeyWidgetConfigView from './SankeyWidgetConfigView';

interface DnDSankeyWidgetConfigDialogProps {
  widget: any;
  open: boolean;
  handleClose: () => void;
  sensorList: any[];
  refresh: boolean;
  setRefresh: Function;
  pageData: any;
  setPageData: Function;
}

export default function DnDSankeyWidgetConfigDialog({
  widget,
  open,
  handleClose,
  sensorList,
  refresh,
  setRefresh,
}: DnDSankeyWidgetConfigDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: '#f0f0f0', // Replace with your desired color
          padding: '16px',
          // width: { xs: '90%', sm: '100%', padding: 0 },
        },
      }}
      PaperProps={{
        style: {
          width: '80%', // Increase the width
          maxWidth: '80%', // Ensure the dialog doesn't grow beyond this width
        },
      }}
    >
      <SankeyWidgetConfigView
        widget={widget}
        sensorList={sensorList}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </Dialog>
  );
}
