export default function ChemicalIcon({ fillColor = '#3DA385', width = '9', height = '17' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.52341 0.5V1.9916C7.52341 3.0404 7.52341 3.5648 7.65241 4.0682C7.78021 4.5722 8.03221 5.0342 8.53561 5.9582L9.21721 7.211C10.5 9.5666 11.1414 10.745 10.6152 11.618L10.6068 11.6312C10.0734 12.5 8.71501 12.5 5.99941 12.5C3.28381 12.5 1.92541 12.5 1.39141 11.6312L1.38361 11.618C0.857407 10.745 1.49881 9.5666 2.78161 7.211L3.46321 5.9582C3.96721 5.0342 4.21801 4.5722 4.34641 4.0682C4.47481 3.5642 4.47481 3.0404 4.47481 1.9922V0.5M4.19941 8.9012L4.20481 8.9M7.79941 10.1012L7.80481 10.1M3.59941 0.5H8.39941"
        stroke={fillColor}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.29883 6.23305C3.89883 5.54125 4.85883 6.03985 5.99883 6.69025C7.49883 7.54585 8.39883 6.88945 8.69883 6.26845"
        stroke={fillColor}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
