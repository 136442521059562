import { useEffect, useRef, useState } from 'react';
import { TextField, IconButton, Stack, Box, Dialog, DialogTitle, Typography } from '@mui/material';

import { isNameProperlyFormatted } from '../../../utils/name.utils';
import { toast } from 'react-toastify';

import useOutsideClickDetector from '../../../utilities/customHooks/useOutsideClickDetector';
import { delay } from '../../../utils/time.utils';
import ColorPicker from '../../../globalComponents/colorPicker/ColorPicker';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmation from '../../../globalComponents/dialogs/DeleteConfirmation';
import { truncateText } from '../../../utilities/string.utils';
import { isValidColorString } from '../../../utils/color.utils';

interface propsInterface {
  widget: any;
  widgetClusterIndex: number;
  widgetIndex: number;
  text: string;
  changeHeadingWidgetHeading: Function;
  open: boolean;
  handleClose: any;
  deleteCustomWidget: Function;
}

export default function HeadingWidgetConfigPopup(props: propsInterface) {
  const {
    widget,
    widgetClusterIndex,
    widgetIndex,
    text,
    changeHeadingWidgetHeading,
    open,
    handleClose,
    deleteCustomWidget,
  } = props;
  //@ts-ignore
  const [allowEdit, setAllowEdit] = useState<boolean>(false);
  const textFieldRef = useRef(null);
  const [refresh, setRefresh] = useState(false);
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);

  useOutsideClickDetector(textFieldRef, async () => {
    await delay(500);
    setAllowEdit(false);
  });

  const changeHeadingWidgetFontSettings = (property: string, value: any) => {
    if (widget.font != null) {
      widget.font[property] = value;
      setRefresh(!refresh);
    }
  };

  useEffect(() => {
    console.log('here1234');
  }, []);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{}}
      fullWidth
      PaperProps={{
        style: {
          width: '50%', // Increase the width
          maxWidth: '50%', // Ensure the dialog doesn't grow beyond this width
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Box sx={{ fontSize: '20px' }}>{truncateText(widget.heading?.english, 50)}</Box>
          <IconButton
            onClick={event => {
              event.stopPropagation();
              setShowDeleteModal(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack direction="column" gap={3} p={5}>
        <TextField
          fullWidth
          type="text"
          defaultValue={text! ?? ''}
          inputRef={textFieldRef}
          // variant="filled"
          multiline
          rows={3}
          label="Text"
          disabled={false}
          inputProps={{
            style: {
              fontSize: !isNaN(parseInt(widget.font?.size)) ? parseInt(widget.font?.size) : '20px',
              color: isValidColorString(widget.font?.color) ? widget.font?.color : '#000',
              fontWeight: !isNaN(widget.font?.fontWeight) ? widget.font?.fontWeight : '500',
              display: 'inline',
            },
          }}
          onBlur={e => {
            console.log('abc blur');
            // e.stopPropagation();
            if (isNameProperlyFormatted(e.target.value)) {
              changeHeadingWidgetHeading(
                widgetClusterIndex,
                widgetIndex,
                'english',
                e.target.value
              );
              // setAllowEdit(false);
            } else {
              toast.error('Nicknames cannot be a empty string!');
              if (textFieldRef && textFieldRef.current != null) {
                let a: any = textFieldRef.current;
                a.value = text;
                a.focus();
              }
            }
          }}
        />
        <Stack direction="row" alignItems="center" gap={3}>
          <TextField
            fullWidth
            type="number"
            value={widget.font?.size! ?? 20}
            // inputRef={textFieldRef}
            // variant="filled"
            disabled={true}
            label="Font Size"
            InputProps={{ inputProps: { min: 0, max: 100 } }}
            onChange={e => {
              // e.stopPropagation();
              console.log('abc blur');
              changeHeadingWidgetFontSettings('size', e.target.value);
              // setAllowEdit(false);
            }}
          />
          <TextField
            fullWidth
            type="number"
            value={widget.font?.fontWeight! ?? 500}
            // inputRef={textFieldRef}
            // variant="filled"
            disabled={true}
            label="Font Weight"
            InputProps={{ inputProps: { min: 0, max: 100 } }}
            onChange={e => {
              // e.stopPropagation();
              console.log('abc blur');
              changeHeadingWidgetFontSettings('fontWeight', e.target.value);
              // setAllowEdit(false);
            }}
          />
        </Stack>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography>
            Text Color - {widget?.font?.color?.length > 3 ? widget?.font?.color : '#000000'}
          </Typography>
          <Box
            sx={{
              background: widget?.font?.color?.length > 3 ? widget?.font?.color : '#000000',
              borderRadius: '5px',
              padding: '5px',
              width: '10px',
              height: '10px',
            }}
          ></Box>
          <IconButton
            size="small"
            onClick={() => {
              setOpenColorPicker(true);
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Stack>
        <ColorPicker
          initialColor={widget?.font?.color?.length > 3 ? widget?.font?.color : '#000000'}
          open={openColorPicker}
          onClose={() => {
            setOpenColorPicker(false);
          }}
          onColorChange={newColor => {
            changeHeadingWidgetFontSettings('color', newColor);
          }}
        />
        <DeleteConfirmation
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          alertMessage="Are you sure you want to delete this widget? This action is irreversible!"
          processing={processing}
          setProcessing={setProcessing}
          onConfirmAction={() => {
            deleteCustomWidget(widgetClusterIndex, widgetIndex);
          }}
        />
      </Stack>
    </Dialog>
  );
}
