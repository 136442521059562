import { Box } from '@mui/material';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import Tooltip from '@mui/material/Tooltip';
import whiteLinkIcon from '../../../constants/Images/whiteLinkIcon.svg';
import InfoIcon from '@mui/icons-material/Info';
import Skeleton from '@mui/material/Skeleton';
import FactoryIcon from '@mui/icons-material/Factory';
// import {
//   widgetDataInterface,
//   widgetStructureInterface,
// } from "../../../Interfaces/widgetInterfaces";
import { useState } from 'react';

const styles = {
  showIconStyle: {
    visibility: 'visible',
    alignSelf: 'center',
  },
  numberViewWrapper: {
    width: 'inherit',
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '16px',
    gap: '10px',
    fontSize: 'clamp(12px,10px + 0.1vw,25px)',
    fontWeight: 'bold',
    color: 'white',
    boxSizing: 'border-box',
    fontFamily: 'Roboto, Regular',
    padding: '12px',
  },
  numberViewHeading: {
    display: 'flex',
    alignItems: 'flex-start',
    width: 'auto',
    //   background : "black",
    padding: '0',
    height: 'auto',
    fontSize: 'clamp(13px,0.7vw,19px)',
  },
  linkIconStyle: {
    width: '18px',
    height: '18px',
    marginLeft: 'auto',
  },
  numberViewBody: {
    width: 'auto',
    height: '100%',
    // fontSize : "20px",
    fontSize: 'clamp(18px,10vw,30px)',
    paddingTop: 'auto',
    color: '#FFFFFF',
    overflow: 'hidden',
    fontWeight: 'normal',
  },
  emptyNumberViewBody: {
    width: '100%',
    height: 'inherit',
    display: 'grid',
    placeItems: 'center',
    // fontSize : "13px",
    fontSize: 'clamp(13px,0.7vw,22px)',
    color: '#eee',
  },
};

// interface numberWidgetpropInterface {
//   widgetData: widgetDataInterface | null;
//   widgetStructure: widgetStructureInterface;
//   isLoading: boolean;
//   showLinkIcon?: boolean;
//   linkOnWidget?: string;
// }

export default function NumberWidget(props: any) {
  const {
    // widgetStructure,
    widgetData,
    isLoading,
    showLinkIcon,
    linkOnWidget,
    background,
    // setSelectedChart,
    // handleEditClick,
    // showClock,
    // setShowClock,
    // customTimeRangeSettings,
    // handleClockClick,
    // customGranularity,
  } = props;

  const numberOfMetricsSelected = widgetData?.metricsData?.datasets?.length ?? 0;

  // const [resetZoom, setResetZoom] = useState(false);
  //@ts-ignore
  const [showIcon, setShowIcon] = useState(false);

  const clickedOnlinkIcon = () => {
    window.location.href = linkOnWidget!;
  };

  if (isLoading)
    return (
      <Box
        sx={{
          ...styles.numberViewWrapper,
          bgcolor: 'white',
        }}
        p={1}
      >
        <Box
          className="numberHeading"
          style={{
            ...styles.numberViewHeading,
            color: 'black',
            fontWeight: 'normal',
          }}
        >
          <div>{widgetData.widgetNickName ? widgetData.widgetNickName : widgetData.widgetName}</div>
          {showLinkIcon && (
            <Tooltip title="Click to know more">
              <img
                src={whiteLinkIcon}
                style={{ width: '18px', height: '18px', marginLeft: 'auto' }}
                onClick={() => {
                  clickedOnlinkIcon();
                }}
              ></img>
            </Tooltip>
          )}
          <Tooltip title={widgetData.widgetDescription} style={{ color: '#eee' }}>
            <InfoIcon fontSize="small" />
          </Tooltip>
        </Box>
        <Box style={styles.emptyNumberViewBody}>
          <Skeleton
            variant="rectangular"
            style={{
              width: 'inherit',
              height: 'inherit',
            }}
          />
        </Box>
      </Box>
    );
  else if (widgetData)
    return (
      <Box
        sx={{
          ...styles.numberViewWrapper,
          bgcolor: background || 'white',
        }}
        p={1}
        onMouseEnter={() => setShowIcon(true)}
        onMouseLeave={() => setShowIcon(false)}
      >
        <Box className="numberHeading" style={styles.numberViewHeading}>
          <div>{widgetData.widgetNickName ? widgetData.widgetNickName : widgetData.widgetName}</div>
          {showLinkIcon && (
            <Tooltip title="Click to know more" style={{}}>
              <img
                src={whiteLinkIcon}
                style={styles.linkIconStyle}
                onClick={() => {
                  clickedOnlinkIcon();
                }}
              ></img>
            </Tooltip>
          )}
          <Tooltip title={widgetData.widgetDescription}>
            <InfoIcon fontSize="small" style={{ color: '#eee' }} />
          </Tooltip>
          {widgetData?.plantName && (
            <Tooltip sx={styles.showIconStyle} title={widgetData.plantName}>
              <FactoryIcon
                fontSize="small"
                sx={{
                  width: '16px',
                  height: '16px',
                  cursor: 'pointer',
                  color: '#fff',
                  alignSelf: 'center',
                  marginTop: '-2px',
                }}
              />
            </Tooltip>
          )}
        </Box>
        {numberOfMetricsSelected > 0 ? (
          <Box sx={{ ...styles.numberViewBody }}>
            <Swiper
              grabCursor={true}
              centeredSlides={true}
              pagination={{ clickable: true, dynamicBullets: true }}
              autoplay={true}
              loop={true}
              slidesPerView={1}
              spaceBetween={0}
              style={{ height: '100%' }}
            >
              {widgetData.metricsData.datasets.map((data: any) => {
                return (
                  <SwiperSlide>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 'clamp(15px,1.1vw,30px)',
                        height: 'inherit',
                      }}
                    >
                      <span
                        style={{
                          // fontSize : "13px"
                          fontSize: 'clamp(13px,0.7vw,19px)',
                        }}
                      >
                        {data.label}{' '}
                      </span>
                      {data.data[0] != null ? (
                        <span style={{ fontSize: 'clamp(23px,1.3vw,40px)' }}>{data.data[0]}</span>
                      ) : (
                        <span
                          style={{
                            ...styles.emptyNumberViewBody,
                            fontWeight: 'bold',
                            marginBottom: '30%',
                          }}
                        >
                          {'No Data Available'}
                        </span>
                      )}
                    </Box>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box>
        ) : (
          <Box sx={{ ...styles.emptyNumberViewBody }}>
            <p>No Metric Selected</p>
          </Box>
        )}
      </Box>
    );
  else return null;
}
