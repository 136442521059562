import {
  Box,
  Button,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import LayoutComponent from "../components/LayoutComponent";
import { useNavigate } from "react-router";
import { getMasterData, postMasterData } from "../services/fixedTaskMaster";
import { DAYS, WEEKS, ASSIGN_ROLE, ESCALATION } from "../constants/constants";
import TaskSection from "../components/fixedTaskMaster/TaskSection";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/lab/Autocomplete";
import {
  FixedTaskInterface,
  FixedTaskMasterData,
  SubTask,
} from "../Interfaces/fixedTask";
import dpTheme from "../styles/theme/dpTheme";
import {
  PrimaryButton,
  PrimaryRoundedButton,
} from "../styles/global/components/dpButtons";
import { styledBox, styledTitle } from "../styles/global/components/dpBox";

const FixedTaskMasterStyle = {
  TextFieldStyle: {
    margin: "0.6rem 0",
  },
  ButtonStyle: {
    color: "white",
  },
};

export default function FixedTaskMaster() {
  const navigate = useNavigate();
  const [masterData, setMasterData] = useState<FixedTaskMasterData | any>({});
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    getMasterData().then((data) => {
      setMasterData(data);
    });
  }, []);

  const addnewSubtask = (
    section: string,
    subsection: string,
    indexofTask: number
  ) => {
    const oldData = masterData;
    if (section === "Core Equipments" || section === "Tanks") {
      oldData[section][subsection][indexofTask]["subTasks"].push({
        name: "Enter Subtask",
        namehindi: "सबटास्क दर्ज करें",
      });
    } else {
      oldData[section][indexofTask]["subTasks"].push({
        name: "Enter Subtask",
        namehindi: "सबटास्क दर्ज करें",
      });
    }
    setMasterData(oldData);
    setRefresh(!refresh);
  };

  const UpdateTask = (
    section: string,
    subsection: string,
    index: number,
    sectiona: string,
    valuefor: string,
    value: any
  ) => {
    console.log("here");
    let oldData = masterData;

    console.log("oldData : ", oldData);
    console.log("value for : ", valuefor);
    console.log("section : ", section);
    console.log("subsection : ", subsection);
    console.log("value : ", value);

    if (section === "Core Equipments" || section === "Tanks") {
      oldData[section][subsection][index][sectiona][valuefor] = value;
    } else {
      //    if(oldData != null && oldData[subsection] != null )
      //      oldData[subsection][index][sectiona][valuefor]=value
      if (section === "General" || section === "Blowers" || section === "Pumps")
        oldData[section][index][sectiona][valuefor] = value;
    }

    setMasterData(oldData);
    setRefresh(!refresh);
  };

  const addTask = (section: string, subsection: string) => {
    let newTask = {
      toUser: "Operator",
      recurringDays: DAYS,
      weeks: WEEKS,
      recurringTimes: "08:00:00",
      dueTimeFromPosting: "02:00:00",
      escalations: ESCALATION,
      heading: {
        eng: "Enter Heading",
        hindi: "शीर्षक दर्ज करें",
      },
      description: {
        eng: "Enter Description",
        hindi: "विवरण दर्ज करें",
      },
      subTasks: [
        {
          name: "Enter Subtask",
          namehindi: "सबटास्क दर्ज करें",
        },
      ],
    };

    let oldData = masterData;
    if (section === "Core Equipments" || section === "Tanks") {
      oldData[section][subsection].push(newTask);
    } else {
      oldData[section].push(newTask);
    }

    setMasterData(oldData);
    setRefresh(!refresh);
  };

  const UpdateSubTask = (
    section: string,
    subsection: string,
    taskindex: number,
    subTaskIndex: number,
    key: string,
    value: any
  ) => {
    let oldData = masterData;
    if (section === "Core Equipments" || section === "Tanks") {
      oldData[section][subsection][taskindex]["subTasks"][subTaskIndex][key] =
        value;
    } else {
      oldData[section][taskindex]["subTasks"][subTaskIndex][key] = value;
    }
    setMasterData(oldData);
    setRefresh(!refresh);
  };

  const delteSubTask = (
    section: string,
    subsection: string,
    indexofTask: number,
    indexofSubTask: number
  ) => {
    let oldData = masterData;
    if (section === "Core Equipments" || section === "Tanks") {
      oldData[section][subsection][indexofTask]["subTasks"].splice(
        indexofSubTask,
        1
      );
    } else {
      oldData[section][indexofTask]["subTasks"].splice(indexofSubTask, 1);
    }
    setMasterData(oldData);
    setRefresh(!refresh);
  };

  const updateDirectValue = (
    section: string,
    subsection: string,
    taskindex: number,
    key: string,
    value: any
  ) => {
    let oldData = masterData;
    if (section === "Core Equipments" || section === "Tanks") {
      oldData[section][subsection][taskindex][key] = value;
    } else {
      oldData[section][taskindex][key] = value;
    }
    setMasterData(oldData);
    setRefresh(!refresh);
  };

  const deleteTask = (section: string, subsection: string, index: number) => {
    let oldData = masterData;
    if (section === "Core Equipments" || section === "Tanks") {
      oldData[section][subsection].splice(index, 1);
    } else {
      oldData[section].splice(index, 1);
    }

    console.log(oldData);
    setMasterData(oldData);
    setRefresh(!refresh);
  };

  const update = () => {
    postMasterData(masterData).then(() => {
      navigate("/plants");
    });
  };

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent>
        <Typography variant="h1" sx={styledTitle}>
          Fixed Task Master
        </Typography>
        {masterData &&
          Object.keys(masterData).map((section) => {
            if (["Core Equipments", "Tanks"].includes(section)) {
              return (
                <Box sx={styledBox}>
                  <Typography variant="h3">{section}</Typography>
                  {Object.keys(masterData[section]).map((subsection) => {
                    return (
                      <TaskSection
                        data={masterData[section][subsection]}
                        section={section}
                        subsection={subsection}
                        addTask={addTask}
                        UpdateTask={UpdateTask}
                        deleteTask={deleteTask}
                        addnewSubtask={addnewSubtask}
                        delteSubTask={delteSubTask}
                        UpdateSubTask={UpdateSubTask}
                        updateDirectValue={updateDirectValue}
                        isCoreEquipment={true}
                      />
                    );
                  })}
                </Box>
              );
            } else {
              return (
                <Box sx={styledBox}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h3">{section}</Typography>
                    <span style={{ float: "right" }}>
                      <PrimaryButton
                        onClick={() => {
                          addTask(section, "");
                        }}
                      >
                        <AddIcon />
                        Add Fixed Task
                      </PrimaryButton>
                    </span>
                  </Stack>
                  {masterData[section].map(
                    (task: FixedTaskInterface, indexTask: number) => {
                      return (
                        <Box m={1}>
                          <Accordion TransitionProps={{ unmountOnExit: true }}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography variant="subtitle2">
                                {task.heading.eng}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Box>
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Typography variant="h4">Task</Typography>
                                  <Button
                                    onClick={() => {
                                      deleteTask(section, "", indexTask);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </Stack>
                                <Box m={1} p={1}>
                                  <div
                                    style={{
                                      font: "Montserrat Bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    <Typography variant="h5" mb={3}>
                                      Heading
                                    </Typography>
                                    <div
                                      style={{
                                        font: "Montserrat",
                                        fontSize: 16,
                                      }}
                                    >
                                      <TextField
                                        fullWidth
                                        required
                                        value={task.heading.eng}
                                        label="Heading English"
                                        onChange={(e) => {
                                          UpdateTask(
                                            section,
                                            "",
                                            indexTask,
                                            "heading",
                                            "eng",
                                            e.target.value
                                          );
                                        }}
                                        style={
                                          FixedTaskMasterStyle.TextFieldStyle
                                        }
                                      />
                                    </div>

                                    <div
                                      style={{
                                        font: "Montserrat",
                                        fontSize: 16,
                                      }}
                                    >
                                      <TextField
                                        fullWidth
                                        required
                                        value={task.heading.hindi}
                                        label="Heading Hindi"
                                        onChange={(e) => {
                                          UpdateTask(
                                            section,
                                            "",
                                            indexTask,
                                            "heading",
                                            "hindi",
                                            e.target.value
                                          );
                                        }}
                                        style={
                                          FixedTaskMasterStyle.TextFieldStyle
                                        }
                                      />
                                    </div>
                                  </div>
                                </Box>
                                <Box m={1} p={1}>
                                  <div
                                    style={{
                                      font: "Montserrat Bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    <Typography variant="h5" mb={3}>
                                      Description
                                    </Typography>
                                    <div
                                      style={{
                                        font: "Montserrat",
                                        fontSize: 16,
                                      }}
                                    >
                                      <TextField
                                        fullWidth
                                        required
                                        value={task.description.eng}
                                        label="Description English"
                                        onChange={(e) => {
                                          UpdateTask(
                                            section,
                                            "",
                                            indexTask,
                                            "description",
                                            "eng",
                                            e.target.value
                                          );
                                        }}
                                        style={
                                          FixedTaskMasterStyle.TextFieldStyle
                                        }
                                      />
                                    </div>

                                    <div
                                      style={{
                                        font: "Montserrat",
                                        fontSize: 16,
                                      }}
                                    >
                                      <TextField
                                        fullWidth
                                        required
                                        value={task.description.hind}
                                        label="Description Hindi"
                                        onChange={(e) => {
                                          UpdateTask(
                                            section,
                                            "",
                                            indexTask,
                                            "description",
                                            "hind",
                                            e.target.value
                                          );
                                        }}
                                        style={
                                          FixedTaskMasterStyle.TextFieldStyle
                                        }
                                      />
                                    </div>
                                  </div>
                                </Box>
                                <Box m={1} p={1}>
                                  <div
                                    style={{
                                      font: "Montserrat Bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    SubTasks
                                    <span style={{ float: "right" }}>
                                      <Button
                                        style={FixedTaskMasterStyle.ButtonStyle}
                                        variant="contained"
                                        onClick={() =>
                                          addnewSubtask(section, "", indexTask)
                                        }
                                      >
                                        <AddIcon />
                                        Add New SubTask
                                      </Button>
                                    </span>
                                  </div>
                                </Box>
                                {task["subTasks"].map(
                                  (subTask: SubTask, subTaskIndex: number) => {
                                    return (
                                      <Box m={1} p={1}>
                                        <div
                                          style={{
                                            font: "Montserrat Bold",
                                            fontSize: 16,
                                          }}
                                        >
                                          SubTask {subTaskIndex + 1}
                                          <span style={{ float: "right" }}>
                                            <Button
                                              style={
                                                FixedTaskMasterStyle.ButtonStyle
                                              }
                                              variant="contained"
                                              onClick={() =>
                                                delteSubTask(
                                                  section,
                                                  "",
                                                  indexTask,
                                                  subTaskIndex
                                                )
                                              }
                                            >
                                              Delete SubTask
                                            </Button>
                                          </span>
                                        </div>
                                        <div
                                          style={{
                                            font: "Montserrat",
                                            fontSize: 16,
                                          }}
                                        >
                                          <TextField
                                            fullWidth
                                            required
                                            value={subTask.name}
                                            label="Description English"
                                            onChange={(e) => {
                                              UpdateSubTask(
                                                section,
                                                "",
                                                indexTask,
                                                subTaskIndex,
                                                "name",
                                                e.target.value
                                              );
                                            }}
                                            style={
                                              FixedTaskMasterStyle.TextFieldStyle
                                            }
                                          />
                                        </div>

                                        <div
                                          style={{
                                            font: "Montserrat",
                                            fontSize: 16,
                                          }}
                                        >
                                          <TextField
                                            fullWidth
                                            required
                                            value={subTask.namehindi}
                                            label="Description Hindi"
                                            onChange={(e) => {
                                              UpdateSubTask(
                                                section,
                                                "",
                                                indexTask,
                                                subTaskIndex,
                                                "namehindi",
                                                e.target.value
                                              );
                                            }}
                                            style={
                                              FixedTaskMasterStyle.TextFieldStyle
                                            }
                                          />
                                        </div>
                                      </Box>
                                    );
                                  }
                                )}
                                <Box m={1} p={1}>
                                  <Box m={1} p={1}>
                                    <div>
                                      <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={WEEKS}
                                        value={task["weeks"]}
                                        onChange={(event, newValue) => {
                                          updateDirectValue(
                                            section,
                                            "",
                                            indexTask,
                                            "weeks",
                                            newValue
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                            label="Recurring Weeks"
                                          />
                                        )}
                                      />

                                      <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={DAYS}
                                        value={task["recurringDays"]}
                                        onChange={(event, newValue) => {
                                          updateDirectValue(
                                            section,
                                            "",
                                            indexTask,
                                            "recurringDays",
                                            newValue
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                            label="Recurring Days"
                                            style={
                                              FixedTaskMasterStyle.TextFieldStyle
                                            }
                                          />
                                        )}
                                      />
                                    </div>
                                    <div>
                                      <TextField
                                        fullWidth
                                        required
                                        value={task["recurringTimes"]}
                                        label="Recurring Time"
                                        onChange={(e) => {
                                          updateDirectValue(
                                            section,
                                            "",
                                            indexTask,
                                            "recurringTimes",
                                            e.target.value
                                          );
                                        }}
                                        style={
                                          FixedTaskMasterStyle.TextFieldStyle
                                        }
                                      />
                                    </div>
                                  </Box>
                                  <Box m={1} p={1}>
                                    <TextField
                                      fullWidth
                                      required
                                      value={task["dueTimeFromPosting"]}
                                      label="OverDue Time"
                                      onChange={(e) => {
                                        updateDirectValue(
                                          section,
                                          "",
                                          indexTask,
                                          "dueTimeFromPosting",
                                          e.target.value
                                        );
                                      }}
                                      style={
                                        FixedTaskMasterStyle.TextFieldStyle
                                      }
                                    />
                                  </Box>
                                  <Box m={1} p={1}>
                                    <TextField
                                      fullWidth
                                      required
                                      value={task["videoUrl"]}
                                      label="Video Url"
                                      onChange={(e) => {
                                        updateDirectValue(
                                          section,
                                          "",
                                          indexTask,
                                          "videoUrl",
                                          e.target.value
                                        );
                                      }}
                                      style={
                                        FixedTaskMasterStyle.TextFieldStyle
                                      }
                                    />
                                  </Box>

                                  <Box m={1} p={1}>
                                    <Autocomplete
                                      id="tags-standard"
                                      options={ASSIGN_ROLE}
                                      value={task["toUser"]}
                                      onChange={(event, newValue) => {
                                        updateDirectValue(
                                          section,
                                          "",
                                          indexTask,
                                          "toUser",
                                          newValue
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          required
                                          {...params}
                                          variant="standard"
                                          label="For Role"
                                          style={
                                            FixedTaskMasterStyle.TextFieldStyle
                                          }
                                        />
                                      )}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      );
                    }
                  )}
                </Box>
              );
            }
          })}
        <Box m={1} p={1}>
          <PrimaryRoundedButton
            onClick={update}
            style={{ background: "#519BD0" }}
          >
            Update Master
          </PrimaryRoundedButton>
        </Box>
      </LayoutComponent>
    </ThemeProvider>
  );
}
