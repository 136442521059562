import { useState } from 'react';

import { Tooltip, Box, Button } from '@mui/material';

function ButtonTabs({ tabs, onChange }: any) {
  // Use the value of the first tab as the initial active tab
  const [activeTab, setActiveTab] = useState(tabs[0].value);

  const handleTabClick = (value: any) => {
    setActiveTab(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        border: '1px solid black',
        overflow: 'hidden',
      }}
      borderRadius={2}
    >
      {tabs.map((tab: any) => (
        <Tooltip title={tab.tooltip || ''}>
          <Box sx={{ width: '100%' }}>
            <Button
              key={tab.value} // Use value for key if it's unique
              onClick={() => handleTabClick(tab.value)}
              fullWidth
              disabled={tab.disabled || false}
              sx={{
                borderRadius: '0',
                textTransform: 'none',
                backgroundColor: activeTab === tab.value ? 'black' : 'white',
                color: activeTab === tab.value ? 'white' : 'black',
                '&:hover': {
                  backgroundColor: activeTab === tab.value ? 'black' : '#f0f0f0',
                  color: activeTab === tab.value ? 'white' : 'black',
                },
              }}
            >
              {tab.label}
            </Button>
          </Box>
        </Tooltip>
      ))}
    </Box>
  );
}

export default ButtonTabs;
