//@ts-nocheck
import React from 'react';
import { useState, useRef } from 'react';
import { Box, Grid, InputLabel, Stack, TextField, Chip, IconButton, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import axiosInstance from '../../constants/axiosInstance';

import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { toast } from 'react-toastify';
import TableRowsIcon from '@mui/icons-material/TableRows';
import PinIcon from '@mui/icons-material/Pin';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import MetricConfigComponent from '../metricConfigComponent/metricConfigComponent';
import MetricConfigComponentV2 from './metricConfigComponentv2';
import { pageConfigDataInterface } from '../../Interfaces/dashboardPageInterfaces';
import { widgetInterface } from '../../Interfaces/widgetInterfaces';
import {
  lastMovedWidgetInterface,
  metricDiagnosisDataInterface,
} from '../../Interfaces/pageConfigPageInterfaces';
import GranularitySettings from './granularitySetting';
import TimeRangeSettings from './timeRangeSetting';
import MetricSearchBar from './metricSearchBar';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import DeleteConfirmation from '../../globalComponents/dialogs/DeleteConfirmation';
import { IInflatedDashboadPage } from '../../Interfaces/newDashboardInterfaces/inflatedDashbaordPage.interfaces';
import SellIcon from '@mui/icons-material/Sell';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import StandaloneGeneralisedWidget from '../GeneralisedWidgetComponents/StandaloneGeneralisedWidget';
import { useDashboardStore } from '../../store/DashboardStore';
import { usePlantLayoutStore } from '../../store/PlantLayoutStore';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import lodash from 'lodash';
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import { ArrowCircleUpRounded } from '@mui/icons-material';
import { ReactComponent as ArrowUpIcon } from '../../constants/Images/ArrowUpIcon.svg';
import { ReactComponent as ArrowDownIcon } from '../../constants/Images/ArrowDownIcon.svg';
import FactoryIcon from '@mui/icons-material/Factory';
import { add530Hours } from '../../utils/dashboard.utils';
import { start } from 'repl';
import ValidRangeSettings from '../widgetConfigComponentsV2/ValidRangeSettings';
import ExtraInfoConfig from './extraInfoConfig';
import ButtonTabs from '../../core-components/theme/components/Tabs/ButtonTabs';
import { WIDGET_THEMES } from '../../constants/widgetConfig.constants';
import { UNIT_OPTION_LIST } from '../../constants/units.contants';

const styles = {
  widgetConfigComponentAccordion: {
    margin: '10px',
    maxHeight: '620px',
    overflowY: 'scroll',
    background: '#F0F0F0',
    borderRadius: '10px',
    border: '1px solid #BDBDBD',
  },
  widgetConfigSummaryContainer: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
  },
  widgetConfigSummaryActionButtonContainer: {
    display: 'flex',
    marginLeft: 'auto',
    color: '#444',
    alignItems: 'center',
  },
  textBoxStyle: {
    background: 'white',
    '& fieldset': { border: 'none' },
  },

  metricDiagnosisLoading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#eee',
    width: '400px',
    minHeight: '100px',
    borderRadius: '5px',
  },
  metricDiagnosisNotOk: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFCCCB',
    width: '400px',
    minHeight: '100px',
    borderRadius: '5px',
  },
  metricDiagnosisOk: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#addfad',
    width: '400px',
    minHeight: '100px',
    borderRadius: '5px',
  },
};

interface propsInterface {
  pageData: IInflatedDashboadPage['pageConfig'];
  setPageData: React.Dispatch<React.SetStateAction<IInflatedDashboadPage['pageConfig'] | null>>;
  widgetCluster: string;
  widgetClusterIndex: number;
  widget: widgetInterface;
  widgetIndex: number;
  moveWidgetPosition: (widgetClusterIndec: number, widgetIndex: number, action: string) => void;
  lastMovedWidget: lastMovedWidgetInterface;
  sensorList: any[];
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  setDeletedWidgets: Function;
}

const currentTime = new Date();
const midnight = new Date();
midnight.setHours(0, 0, 0, 0);
// const startDate = new Date(endDate.valueOf() - 24 * 60 * 60 * 1000);
// const { startDate, endDate } = add530Hours(midnight, currentTime);
// console.log('startDate : ', startDate);

export default function WidgetConfigComponentV3(props: propsInterface) {
  const {
    pageData,
    setPageData,
    widgetCluster,
    widgetClusterIndex,
    widget,
    widgetIndex,
    moveWidgetPosition,
    lastMovedWidget,
    refresh,
    setRefresh,
    sensorList,
    setDeletedWidgets,
  } = props;
  const valueTypes = ['raw', 'avg', 'min', 'max', 'timeWeightedSum', 'cumulative'];
  const [search, setSearch] = useState('');
  const [openDiagnosisBox, setOpenDiagnosisBox] = useState(false);
  const [diagnosing, setDiagnosing] = useState(false);
  const [areMultipleSensorSelected, setAreMultipleSensorSelected] = useState(
    Object.keys(widget.metrics).length > 1 ? true : false
  );
  const [metricDiagnosisData, setMetricDiagnosisData] =
    useState<metricDiagnosisDataInterface | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [refreshStandAloneWidget, setRefreshStandAloneWidget] = useState(false);
  const [widgetMiddleGridTabValue, setWidgetMiddleGridTabValue] = useState('widgetThemeConfig');
  const [widgetFirstGridTabValue, setWidgetFirstGridTabValue] = useState('sensorsConfig');

  const nickNameRef = useRef(null);

  // useEffect(() => {
  //   console.log("widget name ", widget.widgetName);
  // }, []);

  const changeWidgetProperty = (
    widgetClusterIndex: number,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex][property] = value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  const changeAdvancedWidgetProperty = (
    widgetClusterIndex: number,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex]['advancedWidgetSettings'][property] = value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(lastState => !lastState);
  };

  const changeExtraInfoWidgetProperty = (
    widgetClusterIndex: number,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex]['extraInfo'][property] = value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(lastState => !lastState);
  };

  const changeWidgetMinAllowedGranularitySettings = (
    widgetClusterIndex: string,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex]['factoryGranularitySettings'][
      'minAllowedGranularity'
    ][property] = value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  const changeWidgetCustomGranularitySettings = (
    widgetClusterIndex: string,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex]['customGranularitySettings'][property] =
      value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  const changeWidgetMinAllowedTimeRangeSettings = (
    widgetClusterIndex: string,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex]['factoryTimeRangeSettings'][
      'minAllowedTimeRange'
    ][property] = value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  const changeWidgetCustomTimeRangeSettings = (
    widgetClusterIndex: string,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex]['customTimeRangeSettings'][property] = value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  const deleteCustomWidget = (widgetClusterIndex: string, widgetIndex: number) => {
    const widgetToBeDeleted = pageData.data[widgetClusterIndex].widgets[widgetIndex];
    let widgetName = widgetToBeDeleted.widgetName;

    pageData.data[widgetClusterIndex].widgets.splice(widgetIndex, 1);

    const summaryDeletedWidget = {
      id: widgetToBeDeleted._id,
      widgetType: widgetToBeDeleted.widgetType,
    };

    setDeletedWidgets(previousDeletedWidgets => {
      return [...previousDeletedWidgets, summaryDeletedWidget];
    });
    setRefresh(!refresh);
    toast(`${widgetName} deleted Successfully!`);
  };

  const changeWidgetSubProperty = (
    widgetClusterIndex: number,
    widgetIndex: number,
    property: string,
    subProperty: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    if (temp.data[widgetClusterIndex].widgets[widgetIndex][property]?.[subProperty])
      temp.data[widgetClusterIndex].widgets[widgetIndex][property][subProperty] = value;
    else temp.data[widgetClusterIndex].widgets[widgetIndex][property] = { [subProperty]: value };
    console.log(
      (temp.data[widgetClusterIndex].widgets[widgetIndex][property] = { [subProperty]: value })
    );
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  const initiateMetricDiagnosis = (metricId: string) => {
    setOpenDiagnosisBox(true);
    setDiagnosing(true);
    axiosInstance()
      .get(`/dashboard/getMetricDiagnosis?sensorId=${metricId}`)
      .then(({ data }) => {
        console.log('metric diagnostic data : ', data);
        setMetricDiagnosisData(data.data);
        setDiagnosing(false);
      })
      .catch(err => {});
  };

  const isNameIsProperlyFormatted = (name: string) => {
    if (name === '') return false;
    else return true;
  };

  const cloneWidget = (widget: any, widgetClusterIndex: number) => {
    let cloneWidget = lodash.cloneDeep(widget);
    cloneWidget['cloneOf'] = cloneWidget._id;
    cloneWidget['widgetOrigin'] = 'custom';
    pageData.data[widgetClusterIndex].widgets.push(cloneWidget);
    setRefresh(!refresh);
    toast(`${widget.widgetName} cloned Successfully!`);
  };

  return (
    <>
      <Accordion
        sx={styles.widgetConfigComponentAccordion}
        elevation={0}
        TransitionProps={{ mountOnEnter: true, timeout: 500 }}
        disableGutters={true}
      >
        <AccordionSummary
          expandIcon={
            <IconButton>
              <ExpandMoreIcon
                sx={{
                  width: '30px',
                  height: '30px',
                  color: '#333333',
                }}
              />
            </IconButton>
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div style={styles.widgetConfigSummaryContainer}>
            <Checkbox
              checked={widget.checked}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation();
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                changeWidgetProperty(widgetClusterIndex, widgetIndex, 'checked', e.target.checked);
              }}
            />
            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} gap={1}>
              <div>{widget.widgetName}</div>
              <Chip
                icon={<SellIcon sx={{ width: '17px', paddingLeft: '5px' }} />}
                label={widget.widgetNickName}
                variant="outlined"
                sx={{ background: 'white' }}
              />
              {widget?.plantName && (
                <Chip
                  icon={<FactoryIcon sx={{ width: '17px', paddingLeft: '5px' }} />}
                  label={widget?.plantName}
                  variant="outlined"
                  sx={{ background: 'white' }}
                />
              )}
            </Stack>
            <span style={styles.widgetConfigSummaryActionButtonContainer}>
              <Tooltip title="Clone">
                <IconButton
                  color="inherit"
                  onClick={e => {
                    e.stopPropagation();
                    cloneWidget(widget, widgetClusterIndex);
                  }}
                >
                  <FileCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              {widget.widgetOrigin == 'custom' && (
                <Tooltip title="Delete">
                  <IconButton
                    color="inherit"
                    onClick={e => {
                      e.stopPropagation();
                      setShowDeleteModal(true);
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Shift Up">
                <IconButton
                  color="inherit"
                  onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                    e.stopPropagation();
                    moveWidgetPosition(widgetClusterIndex, widgetIndex, 'up');
                  }}
                >
                  <ArrowUpIcon
                    fill={
                      widget.widgetName == lastMovedWidget.widgetName &&
                      lastMovedWidget.direction == 'up'
                        ? '#018749'
                        : '#333'
                    }
                    width="18px"
                    height="18px"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Shift Down">
                <IconButton
                  color="inherit"
                  onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                    e.stopPropagation();
                    moveWidgetPosition(widgetClusterIndex, widgetIndex, 'down');
                  }}
                >
                  <ArrowDownIcon
                    fill={
                      widget.widgetName == lastMovedWidget.widgetName &&
                      lastMovedWidget.direction == 'down'
                        ? '#de1738'
                        : '#333'
                    }
                    width="18px"
                    height="18px"
                  />
                </IconButton>
              </Tooltip>
              <DeleteConfirmation
                showDeleteModal={showDeleteModal}
                setShowDeleteModal={setShowDeleteModal}
                alertMessage="Are you sure you want to delete this widget? This action is irreversible!"
                processing={processing}
                setProcessing={setProcessing}
                onConfirmAction={() => {
                  deleteCustomWidget(widgetClusterIndex, widgetIndex);
                }}
              />
            </span>
            <span style={{}}>
              {(widget.widgetType == 'tableD' || widget.widgetType == 'tableC') && (
                <TableRowsIcon fontSize="medium" />
              )}
              {widget.widgetType == 'graph' && <AssessmentIcon fontSize="medium" />}
              {['NumberGauge', 'number2', 'number'].includes(widget.widgetType) && (
                <PinIcon fontSize="medium" />
              )}
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container rowGap={2}>
            <Grid item xs={12} sm={4} md={4} lg={4} p={1} sx={{}}>
              {/* <FormLabel component="legend">
                * select that sensors that you want to track
              </FormLabel> */}
              <Box sx={{ width: '100%', marginBottom: '10px' }}>
                <ButtonTabs
                  tabs={[
                    { label: 'Add Sensors', value: 'sensorsConfig' },
                    {
                      label: 'Add Tickets',
                      value: 'ticketsConfig',
                      disabled: !(widget.widgetType == 'tableC' || widget.widgetType == 'number'),
                      tooltip: 'Tickets are only allowed for number and table widget',
                    },
                  ]}
                  onChange={value => {
                    setWidgetFirstGridTabValue(value);
                  }}
                />
              </Box>
              {widgetFirstGridTabValue == 'sensorsConfig' && (
                <Stack direction="column" alignItems="center" gap={1}>
                  <Box sx={{ width: '100%' }}>
                    <MetricSearchBar
                      sensorList={sensorList}
                      keywordFilterList={widget.keyWords}
                      widgetMetrics={widget.metrics}
                      refreshPage={refresh}
                      setRefreshPage={setRefresh}
                      refreshStandAloneWidget={refreshStandAloneWidget}
                      setRefreshStandAloneWidget={setRefreshStandAloneWidget}
                      widgetPlantIds={widget.plantIds}
                      setAreMultipleSensorSelected={setAreMultipleSensorSelected}
                      allowMultipleSensor={!['NumberGauge', 'number2'].includes(widget.widgetType)}
                    />
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <FormLabel component="legend">
                      <Checkbox
                        checked={widget.allowMetricLevelCustomisation}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          changeWidgetProperty(
                            widgetClusterIndex,
                            widgetIndex,
                            'allowMetricLevelCustomisation',
                            e.target.checked
                          );
                        }}
                      />
                      Respect Metric Level Customisation
                    </FormLabel>
                  </Box>
                  <Box
                    // border={1}
                    p={1}
                    paddingLeft={2}
                    borderRadius={2}
                    sx={{
                      background: '#ddd',
                      width: '100%',
                      boxSizing: 'border-box',
                    }}
                  >
                    <FormLabel component="legend">
                      Added Sensors {`(${Object.keys(widget.metrics).length})`}
                    </FormLabel>
                    <Box
                      sx={{
                        height: '290px',
                        overflowY: 'scroll',
                      }}
                    >
                      {Object.keys(widget.metrics).map((metric, metricIndex) => {
                        let metricName = widget.metrics[metric].nickName
                          ? widget.metrics[metric].nickName
                          : metric;
                        if (metricName?.toLowerCase().includes(search.toLowerCase()))
                          return (
                            <MetricConfigComponentV2
                              key={metricName + metricIndex}
                              pageData={pageData}
                              setPageData={setPageData}
                              widgetClusterIndex={widgetClusterIndex}
                              widget={widget}
                              widgetIndex={widgetIndex}
                              metric={metric}
                              initiateMetricDiagnosis={initiateMetricDiagnosis}
                              refresh={refresh}
                              setRefresh={setRefresh}
                              setAreMultipleSensorSelected={setAreMultipleSensorSelected}
                              setRefreshStandAloneWidget={setRefreshStandAloneWidget}
                            />
                          ); //// metric config component
                      })}
                    </Box>
                  </Box>
                </Stack>
              )}
              {widgetFirstGridTabValue == 'ticketsConfig' && (
                <ExtraInfoConfig
                  widget={widget}
                  widgetClusterIndex={widgetClusterIndex}
                  widgetIndex={widgetIndex}
                  changeExtraInfoWidgetProperty={changeExtraInfoWidgetProperty}
                />
              )}
            </Grid>
            <Grid
              item
              direction="column"
              xs={12}
              sm={4}
              md={4}
              lg={4}
              sx={{
                display: 'flex',
                alignItems: 'center',
                maxHeight: '500px',
                overflow: 'scroll',
              }}
              p={1}
              pl={1.5}
              pr={1.5}
            >
              <Box sx={{ width: '100%' }} mb={3}>
                <ButtonTabs
                  tabs={[
                    { label: 'Widget Theme', value: 'widgetThemeConfig' },
                    { label: 'Widget Details', value: 'widgetDetailsConfig' },
                  ]}
                  onChange={value => {
                    setWidgetMiddleGridTabValue(value);
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: '100%',
                  overflowY: 'scroll',
                  maxHeight: '500px',
                  boxSizing: 'border-box',
                  background: '#fff',
                  border: '1px solid #ddd',
                }}
                p={3}
                borderRadius={3}
              >
                {widgetMiddleGridTabValue == 'widgetThemeConfig' && (
                  <Stack sx={{ width: '100%' }} direction="column" alignItems="center" gap={3}>
                    <Box sx={{ width: '100%' }}>
                      <TextField
                        fullWidth
                        inputRef={nickNameRef}
                        type="text"
                        defaultValue={widget.widgetNickName ? widget.widgetNickName : ''}
                        variant="outlined"
                        label="Nick Name"
                        multiline
                        maxRows={1}
                        inputProps={{
                          maxLength: 50,
                          step: '0.01',
                        }}
                        style={{}}
                        onBlur={e => {
                          if (isNameIsProperlyFormatted(e.target.value)) {
                            changeWidgetProperty(
                              widgetClusterIndex,
                              widgetIndex,
                              'widgetNickName',
                              e.target.value
                            );
                          } else {
                            toast.error('Nicknames cannot be a empty string!');
                            nickNameRef.current.value = widget.widgetNickName;
                            nickNameRef.focus();
                          }
                        }}
                      />
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select">Select Unit Of this Widget</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select Unit Of this Widget"
                          value={widget.commonInfo?.widgetUnit || 'none'}
                          disabled={['tableC', 'graph'].includes(widget.widgetType)}
                          onChange={e => {
                            changeWidgetSubProperty(
                              widgetClusterIndex,
                              widgetIndex,
                              'commonInfo',
                              'widgetUnit',
                              e.target.value
                            );
                          }}
                        >
                          {UNIT_OPTION_LIST.map(unit => (
                            <MenuItem value={unit.value}>{unit.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select">Select Widget Category</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select Widget Category"
                          value={widget.commonInfo?.widgetCategoryTheme || 'none'}
                          disabled={['tableC', 'graph'].includes(widget.widgetType)}
                          onChange={e => {
                            changeWidgetSubProperty(
                              widgetClusterIndex,
                              widgetIndex,
                              'commonInfo',
                              'widgetCategoryTheme',
                              e.target.value
                            );
                          }}
                        >
                          <MenuItem value={'waterQualityMetrics'}>Water Quality</MenuItem>
                          <MenuItem value={'energyMetrics'}>Energy Metrics</MenuItem>
                          <MenuItem value={'plantAutomationMetrics'}>Plant Automation</MenuItem>
                          <MenuItem value={'mechanicalMetrics'}>Mechnical Metrics</MenuItem>
                          <MenuItem value={'ticketMetrics'}>Ticket Metrics</MenuItem>
                          <MenuItem value={'physicalMetrics'}>Physical Metrics</MenuItem>
                          <MenuItem value={'chemicalMetrics'}>Chemical Metrics</MenuItem>
                          <MenuItem value={'none'}>None</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select">
                          Select the type of Widget you want to see
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select the type of Widget you want to see"
                          value={widget.widgetType}
                          onChange={e => {
                            changeWidgetProperty(
                              widgetClusterIndex,
                              widgetIndex,
                              'widgetType',
                              e.target.value
                            );
                          }}
                        >
                          <MenuItem value={'graph'}>graph</MenuItem>
                          <MenuItem value={'number'}>Number</MenuItem>
                          <MenuItem value={'tableD'}>Table(developer version)</MenuItem>
                          <MenuItem value={'tableC'}>Table</MenuItem>
                          <MenuItem value={'number2'}>Number(Range)</MenuItem>
                          <MenuItem value={'NumberGauge'}>Number(Gauge)</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    {widget.widgetType === 'graph' ? (
                      <Box sx={{ width: '100%' }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select">
                            Select the type of graph you want to see
                          </InputLabel>
                          <Select
                            // labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Select the type of graph you want to see"
                            value={widget.graphType}
                            onChange={(e: SelectChangeEvent) => {
                              changeWidgetProperty(
                                widgetClusterIndex,
                                widgetIndex,
                                'graphType',
                                e.target.value
                              );
                            }}
                          >
                            <MenuItem value={'bar'}>Bar</MenuItem>
                            <MenuItem value={'line'}>Line</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    ) : null}
                    {(widget.widgetType === 'NumberGauge' || widget.widgetType == 'number2') && (
                      <Box m={1} sx={{ width: '100%' }}>
                        <FormLabel fullWidth style={{ marginBottom: '10px' }} component="legend">
                          <Checkbox
                            checked={widget.widgetLook?.showPercentageChange}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              changeWidgetSubProperty(
                                widgetClusterIndex,
                                widgetIndex,
                                'widgetLook',
                                'showPercentageChange',
                                e.target.checked
                              );
                            }}
                          />
                          Show percentage change?
                        </FormLabel>
                      </Box>
                    )}
                    <Box sx={{ width: '100%' }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select">Select Widget Theme</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select Widget Theme"
                          value={widget.widgetLook?.theme}
                          disabled={['tableC', 'graph'].includes(widget.widgetType)}
                          onChange={e => {
                            changeWidgetSubProperty(
                              widgetClusterIndex,
                              widgetIndex,
                              'widgetLook',
                              'theme',
                              e.target.value
                            );
                          }}
                        >
                          {Object.keys(WIDGET_THEMES)
                            .filter(key => key != 'default')
                            .map(key => {
                              return (
                                <MenuItem value={WIDGET_THEMES[key].displayName}>{key}</MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Stack>
                )}
                {widgetMiddleGridTabValue == 'widgetDetailsConfig' && (
                  <Stack sx={{ width: '100%' }} direction="column" alignItems="center" gap={4}>
                    <Box sx={{ width: '100%' }}>
                      <Autocomplete
                        multiple
                        id="tags-filled"
                        options={widget.allowedValueTypes ? widget.allowedValueTypes : valueTypes}
                        // getOptionLabel={(option) => option.title}
                        // defaultValue={ [valueTypes[0]] }
                        filterSelectedOptions
                        value={widget.valueTypes}
                        onChange={(e, newValue) => {
                          changeWidgetProperty(
                            widgetClusterIndex,
                            widgetIndex,
                            'valueTypes',
                            newValue
                          );
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Select the type of values you want to see"
                            placeholder=""
                          />
                        )}
                      />
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <TextField
                        fullWidth
                        type="text"
                        defaultValue={widget.widgetDescription}
                        variant="outlined"
                        label="description"
                        placeholder="widget description"
                        multiline
                        rows={5}
                        style={{}}
                        onBlur={e => {
                          changeWidgetProperty(
                            widgetClusterIndex,
                            widgetIndex,
                            'widgetDescription',
                            e.target.value
                          );
                        }}
                      />
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <TextField
                        fullWidth
                        type="text"
                        defaultValue={widget.aiHelperText}
                        variant="outlined"
                        label="ai helper text"
                        placeholder="Ai helper text"
                        multiline
                        rows={5}
                        style={{}}
                        onBlur={e => {
                          changeWidgetProperty(
                            widgetClusterIndex,
                            widgetIndex,
                            'aiHelperText',
                            e.target.value
                          );
                        }}
                      />
                    </Box>
                  </Stack>
                )}
              </Box>
            </Grid>
            <Grid p={1} item xs={12} sm={4} md={4} lg={4} sx={{}} pl={1.5} pr={1.5}>
              <Stack direction="column" alignItems="center" gap={1}>
                <Box sx={{ width: '100%' }}>
                  {/* <FormLabel style={{marginTop : "20px"}} component="legend">* Add a link to this widget</FormLabel> */}
                  <TextField
                    fullWidth
                    type="text"
                    defaultValue={widget.moreInfo}
                    variant="outlined"
                    label="Link"
                    placeholder="add a link for see more button"
                    multiline
                    inputProps={{
                      step: '0.01',
                      rows: 1,
                    }}
                    style={styles.textBoxStyle}
                    onBlur={e => {
                      changeWidgetProperty(
                        widgetClusterIndex,
                        widgetIndex,
                        'moreInfo',
                        e.target.value
                      );
                    }}
                  />
                </Box>
                <Box
                  borderRadius={1}
                  style={{
                    width: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <Accordion elevation={0}>
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      // expandIcon={<ExpandMoreIcon />}
                      expandIcon={<SettingsIcon fontSize="small" />}
                    >
                      Advanced Widget Settings
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box m={1}>
                        <FormLabel style={{ marginBottom: '10px' }} component="legend">
                          <Checkbox
                            checked={widget.advancedWidgetSettings?.applyCustomSettings}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              changeAdvancedWidgetProperty(
                                widgetClusterIndex,
                                widgetIndex,
                                'applyCustomSettings',
                                e.target.checked
                              );
                            }}
                          />
                          Apply Custom Time Settings
                        </FormLabel>
                      </Box>

                      <Box mt={2}>
                        <Accordion>
                          <AccordionSummary expandIcon={<SettingsIcon fontSize="small" />}>
                            TimeRange Settings
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack gap={2} direction="column">
                              <Box>
                                <p> Minimum Allowed TimeRange</p>
                                {widget?.widgetType !== 'flocDetector' && (
                                  <TimeRangeSettings
                                    minAllowedTimeRangeSettings={
                                      widget?.factoryTimeRangeSettings?.minAllowedTimeRange
                                    }
                                    minAllowedGranularitySettings={
                                      widget?.factoryGranularitySettings.minAllowedGranularity
                                    }
                                    changeMinAllowedTimeRangeSettings={
                                      changeWidgetMinAllowedTimeRangeSettings
                                    }
                                    changeWidgetMinAllowedGranularitySettings={
                                      changeWidgetMinAllowedGranularitySettings
                                    }
                                    widgetClusterIndex={widgetClusterIndex}
                                    widgetIndex={widgetIndex}
                                  />
                                )}
                                <TimeRangeSettings
                                  minAllowedTimeRangeSettings={
                                    widget?.factoryTimeRangeSettings?.minAllowedTimeRange
                                  }
                                  minAllowedGranularitySettings={
                                    widget?.factoryGranularitySettings?.minAllowedGranularity
                                  }
                                  changeMinAllowedTimeRangeSettings={
                                    changeWidgetMinAllowedTimeRangeSettings
                                  }
                                  changeWidgetMinAllowedGranularitySettings={
                                    changeWidgetMinAllowedGranularitySettings
                                  }
                                  widgetClusterIndex={widgetClusterIndex}
                                  widgetIndex={widgetIndex}
                                />
                              </Box>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                      <Box mt={2}>
                        <Accordion>
                          <AccordionSummary expandIcon={<SettingsIcon fontSize="small" />}>
                            Granularity Settings
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack gap={2} direction="column">
                              <Box>
                                <p> Minimum Allowed Granularity</p>
                                <GranularitySettings
                                  minAllowedGranularitySettings={
                                    widget?.factoryGranularitySettings?.minAllowedGranularity
                                  }
                                  changeWidgetMinAllowedGranularitySettings={
                                    changeWidgetMinAllowedGranularitySettings
                                  }
                                  widgetClusterIndex={widgetClusterIndex}
                                  widgetIndex={widgetIndex}
                                  minAllowedTimeRangeSettings={
                                    widget?.factoryTimeRangeSettings?.minAllowedTimeRange
                                  }
                                />
                              </Box>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box
                  borderRadius={1}
                  style={{
                    width: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <Accordion elevation={0}>
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      // expandIcon={<ExpandMoreIcon />}
                      expandIcon={<SettingsIcon fontSize="small" />}
                    >
                      <Typography>Range Settings</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <ValidRangeSettings
                        widgetClusterIndex={widgetClusterIndex}
                        widgetIndex={widgetIndex}
                        pageData={pageData}
                        setPageData={setPageData}
                        areMultipleSensorSelected={areMultipleSensorSelected}
                        setRefreshStandAloneWidget={setRefreshStandAloneWidget}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box
                  borderRadius={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '280px',
                    background: '#fff',
                    padding: 20,
                    boxSizing: 'border-box',
                  }}
                >
                  <Box
                    sx={
                      ['NumberGauge', 'number2', 'number'].includes(widget.widgetType)
                        ? { width: '200px', height: '200px' }
                        : { width: '100%', height: '100%' }
                    }
                  >
                    <StandaloneGeneralisedWidget
                      widgetStructure={widget}
                      timeFrame={{
                        startDate: midnight,
                        endDate: currentTime,
                      }}
                      refresh={refreshStandAloneWidget}
                      plantId={null}
                    />
                  </Box>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Modal
        open={openDiagnosisBox}
        onClose={() => {
          setOpenDiagnosisBox(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {diagnosing ? (
          <Box p={1} sx={styles.metricDiagnosisLoading}>
            <p style={{ textAlign: 'center' }}>running sensor diagnosis.... </p>
          </Box>
        ) : (
          <Box
            p={1}
            sx={
              metricDiagnosisData && metricDiagnosisData?.problems?.length > 0
                ? styles.metricDiagnosisNotOk
                : styles.metricDiagnosisOk
            }
          >
            <Box>
              <h3>Sensor Diagnosis completed</h3>
              <p>sensor exists in Db : {metricDiagnosisData?.sensorExists ? 'YES' : 'No'}</p>
              <p>sensorId : {metricDiagnosisData?.sensorId}</p>
              <p>created on :{metricDiagnosisData?.dateCreated}</p>
              <p>sensorType : {metricDiagnosisData?.sensorType}</p>
              <p>last observed Value : {metricDiagnosisData?.lastValue}</p>
              <p>last Observed time : {metricDiagnosisData?.lastValueTime}</p>
              <p>
                problem Diagnosis -
                {metricDiagnosisData && metricDiagnosisData?.problems?.length > 0 ? (
                  metricDiagnosisData?.problems?.map((problem: string, index: number) => {
                    return <p>{index + 1 + '. ' + problem}</p>;
                  })
                ) : (
                  <span>No problems found, sensor Ok</span>
                )}
              </p>
            </Box>
          </Box>
        )}
      </Modal>
    </>
  );
}
