import { Stack, Box, Divider, FormControl, Select, MenuItem } from '@mui/material';
import { BREAKPOINT_OPTIONS } from '../../constants/breakPoints.constants';

export default function ScreenLayoutSelect(props: any) {
  const { selectedView, onChange } = props;

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      px={2}
      border="1px solid  #BDBDBD"
      borderRadius={1}
      sx={{
        marginLeft: 'auto',
        paddingTop: '6px',
        paddingBottom: '6px',
        background: 'white',
      }}
    >
      <Box>Screen View</Box>
      <Divider orientation="vertical" variant="middle" flexItem />
      <FormControl size="small" sx={{ width: '130px' }}>
        {/* <InputLabel>Select a Filter</InputLabel> */}
        <Select
          value={selectedView}
          onChange={(e: any) => {
            onChange(e.target.value);
          }}
          // label="Select a Filter"
          sx={{
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            background: '#fefefe',
            color: '#777',
          }}
          inputProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: '#fefefe',
                  color: '#777',
                },
              },
            },
          }}
        >
          {Object.keys(BREAKPOINT_OPTIONS).map(key => {
            return <MenuItem value={key}>{BREAKPOINT_OPTIONS[key].displayName}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </Stack>
  );
}
