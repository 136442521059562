export default function AddWidgetIcon({ fillColor = '#183650', width = '9', height = '17' }: any) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0H8V8H0V0ZM10 0H18V8H10V0ZM0 10H8V18H0V10ZM15 10H13V13H10V15H13V18H15V15H18V13H15V10Z"
        fill={fillColor}
      />
    </svg>
  );
}
