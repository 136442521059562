import { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Stack,
  Tooltip,
  IconButton,
  Box,
} from '@mui/material';
import SankeyWidgetConfigView from './subComponents/SankeyWidgetConfigView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteConfirmation from '../../globalComponents/dialogs/DeleteConfirmation';
//@ts-ignore
import { ReactComponent as ArrowUpIcon } from '../../constants/Images/ArrowUpIcon.svg';
//@ts-ignore
import { ReactComponent as ArrowDownIcon } from '../../constants/Images/ArrowDownIcon.svg';
import lodash from 'lodash';
import { IInflatedDashboadPage } from '../../Interfaces/newDashboardInterfaces/inflatedDashbaordPage.interfaces';
import { widgetInterface } from '../../Interfaces/widgetInterfaces';
import { lastMovedWidgetInterface } from '../../Interfaces/pageConfigPageInterfaces';
import { toast } from 'react-toastify';
import WebIcon from '@mui/icons-material/Web';

const styles = {
  widgetConfigComponentAccordion: {
    margin: '10px',
    maxHeight: '544px',
    overflowY: 'scroll',
    background: '#F0F0F0',
    borderRadius: '10px',
    border: '1px solid #BDBDBD',
  },
  widgetConfigSummaryContainer: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
  },
  widgetConfigSummaryActionButtonContainer: {
    display: 'flex',
    marginLeft: 'auto',
    color: '#444',
    alignItems: 'center',
  },
};

interface propsInterface {
  pageData: IInflatedDashboadPage['pageConfig'];
  setPageData: React.Dispatch<React.SetStateAction<IInflatedDashboadPage['pageConfig'] | null>>;
  widgetCluster: string;
  widgetClusterIndex: number;
  widget: widgetInterface;
  widgetIndex: number;
  moveWidgetPosition: (widgetClusterIndex: number, widgetIndex: number, action: string) => void;
  lastMovedWidget: lastMovedWidgetInterface;
  sensorList: any[];
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  setDeletedWidgets: Function;
}

export default function SankeyWidgetConfig(props: propsInterface) {
  const {
    widget,
    sensorList,
    refresh,
    setRefresh,
    pageData,
    setPageData,
    widgetClusterIndex,
    widgetIndex,
    moveWidgetPosition,
    lastMovedWidget,
    setDeletedWidgets,
  } = props;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [refreshStandAloneWidget, setRefreshStandAloneWidget] = useState(false);

  const changeWidgetProperty = (
    widgetClusterIndex: number,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex][property] = value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  const deleteWidget = (widgetClusterIndex: number, widgetIndex: number) => {
    const widgetToBeDeleted = pageData.data[widgetClusterIndex].widgets[widgetIndex];
    let widgetName = widgetToBeDeleted.widgetName;

    pageData.data[widgetClusterIndex].widgets.splice(widgetIndex, 1);

    const summaryDeletedWidget = {
      id: widgetToBeDeleted._id,
      widgetType: widgetToBeDeleted.widgetType,
    };

    setDeletedWidgets((previousDeletedWidgets: any) => {
      return [...previousDeletedWidgets, summaryDeletedWidget];
    });
    setRefresh(!refresh);
    toast(`${widgetName} deleted Successfully!`);
  };
  const cloneWidget = (widget: any, widgetClusterIndex: number) => {
    let cloneWidget = lodash.cloneDeep(widget);
    cloneWidget['cloneOf'] = cloneWidget._id;
    cloneWidget['widgetOrigin'] = 'custom';
    pageData.data[widgetClusterIndex].widgets.push(cloneWidget);
    setRefresh(!refresh);
    toast(`${widget.widgetName} cloned Successfully!`);
  };

  return (
    <Accordion
      sx={styles.widgetConfigComponentAccordion}
      elevation={0}
      TransitionProps={{ mountOnEnter: true, timeout: 500 }}
      disableGutters={true}
    >
      <AccordionSummary
        expandIcon={
          <IconButton>
            <ExpandMoreIcon
              sx={{
                width: '30px',
                height: '30px',
                color: '#333333',
              }}
            />
          </IconButton>
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box sx={styles.widgetConfigSummaryContainer}>
          <Checkbox
            checked={widget.checked}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              changeWidgetProperty(widgetClusterIndex, widgetIndex, 'checked', e.target.checked);
            }}
          />
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} gap={1}>
            <div>{widget.widgetName}</div>
          </Stack>
          <span style={styles.widgetConfigSummaryActionButtonContainer}>
            <Tooltip title="Clone">
              <IconButton
                color="inherit"
                onClick={e => {
                  e.stopPropagation();
                  cloneWidget(widget, widgetClusterIndex);
                }}
              >
                <FileCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {(widget.widgetOrigin == 'custom' ||
              widget.widgetType == 'MapWidget' ||
              widget.widgetType == 'SankeyWidget') && (
              <Tooltip title="Delete">
                <IconButton
                  color="inherit"
                  onClick={e => {
                    e.stopPropagation();
                    setShowDeleteModal(true);
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Shift Up">
              <IconButton
                color="inherit"
                onClick={(e: any) => {
                  e.stopPropagation();
                  moveWidgetPosition(widgetClusterIndex, widgetIndex, 'up');
                }}
              >
                <ArrowUpIcon
                  fill={
                    widget.widgetName == lastMovedWidget.widgetName &&
                    lastMovedWidget.direction == 'up'
                      ? '#018749'
                      : '#333'
                  }
                  width="18px"
                  height="18px"
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Shift Down">
              <IconButton
                color="inherit"
                onClick={(e: any) => {
                  e.stopPropagation();
                  moveWidgetPosition(widgetClusterIndex, widgetIndex, 'down');
                }}
              >
                <ArrowDownIcon
                  fill={
                    widget.widgetName == lastMovedWidget.widgetName &&
                    lastMovedWidget.direction == 'down'
                      ? '#de1738'
                      : '#333'
                  }
                  width="18px"
                  height="18px"
                />
              </IconButton>
            </Tooltip>
            <DeleteConfirmation
              showDeleteModal={showDeleteModal}
              setShowDeleteModal={setShowDeleteModal}
              alertMessage="Are you sure you want to delete this widget? This action is irreversible!"
              processing={processing}
              setProcessing={setProcessing}
              onConfirmAction={() => {
                deleteWidget(widgetClusterIndex, widgetIndex);
              }}
            />
          </span>
          <span style={{}}>
            {widget.widgetType == 'SankeyWidget' && <WebIcon fontSize="medium" />}
          </span>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <SankeyWidgetConfigView
          widget={widget}
          sensorList={sensorList}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </AccordionDetails>
    </Accordion>
  );
}
