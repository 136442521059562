import React, { useState } from 'react';
import { Box, Stack, IconButton, Popover, List, ListItem, ListItemText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import PhotoSizeSelectSmallIcon from '@mui/icons-material/PhotoSizeSelectSmall';
import { WIDGET_SIZE_OPTIONS, WIDGET_THEMES } from '../../../constants/widgetConfig.constants';
import { useUnifiedDashboardStore } from '../../../store/UnifiedDashboardStore';

interface GeneralisedExpandedWidgetHeaderProps {
  onConfigButtonPress: Function;
  onWidgetSizeChange: Function;
  onWidgetThemeChange: Function;
  widgetType: string;
  onDeleteButtonPress: Function;
  onCloseHeader: Function;
}

export default function GeneralisedWidgetConfigHeader(props: GeneralisedExpandedWidgetHeaderProps) {
  const {
    onConfigButtonPress,
    onWidgetSizeChange,
    widgetType,
    onDeleteButtonPress,
    onWidgetThemeChange,
    onCloseHeader,
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { setAllowDrag } = useUnifiedDashboardStore();
  const [openSizeMenu, setOpenSizeMenu] = useState(false);
  const [openThemeMenu, setOpenThemeMenu] = useState(false);

  const handleConfigButtonPress = (event: any) => {
    setAllowDrag(false);
    event.stopPropagation();
    console.log('config button press');
    onConfigButtonPress();
  };

  const handleSizeIconClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenSizeMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleColorIconClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenThemeMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenSizeMenu(false);
    setOpenThemeMenu(false);
    onCloseHeader();
  };

  const handleSizeChange = (width: number, height: number) => {
    onWidgetSizeChange(width, height);
    handleClose();
    setAllowDrag(true);
  };

  const handleDeleteButtonPress = (event: any) => {
    event.stopPropagation();
    onDeleteButtonPress();
  };

  return (
    <Box
      position="relative"
      // sx={{ width: 'inherit' }}
      sx={{
        width: 'calc(100% - 1px)',
      }}
      onMouseEnter={(event: any) => {
        setAllowDrag(false);
      }}
      onMouseLeave={(event: any) => {
        setAllowDrag(true);
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        py={1}
        sx={{
          background: '#F4F5F5',
          position: 'absolute',
          top: 1,
          left: 1,
          width: 'inherit',

          zIndex: '1000',
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
        }}
      >
        <IconButton onClick={handleDeleteButtonPress}>
          <DeleteIcon sx={{ fontSize: '20px', color: '#ACB3B9' }} />
        </IconButton>
        <IconButton onClick={handleColorIconClick}>
          <FormatColorFillIcon sx={{ fontSize: '20px', color: '#ACB3B9' }} />
        </IconButton>
        <IconButton onClick={handleSizeIconClick}>
          <PhotoSizeSelectSmallIcon sx={{ fontSize: '20px', color: '#ACB3B9' }} />
        </IconButton>
        <IconButton onClick={handleConfigButtonPress}>
          <AppRegistrationIcon sx={{ fontSize: '20px', color: '#ACB3B9' }} />
        </IconButton>
      </Stack>
      <Popover
        id={openSizeMenu ? 'simple-popover' : undefined}
        open={openSizeMenu}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPopover-paper': {
            border: '1px solid #1836501A',
            borderRadius: '13px',
            paddingLeft: '10px',
            paddingRight: '10px',
            // boxShadow: 'none',
          },
        }}
      >
        <List>
          {WIDGET_SIZE_OPTIONS[widgetType]?.map((option: any) => {
            return (
              <ListItem
                button
                onClick={() => handleSizeChange(option.w, option.h)}
                sx={{ height: '30px', color: '#00000061' }}
              >
                <ListItemText primary={option.label} />
              </ListItem>
            );
          })}
        </List>
      </Popover>
      <Popover
        id={openThemeMenu ? 'simple-popover1' : undefined}
        open={openThemeMenu}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPopover-paper': {
            border: '1px solid #1836501A',
            borderRadius: '13px',
            paddingLeft: '10px',
            paddingRight: '10px',
            // boxShadow: 'none',
          },
        }}
      >
        <List>
          {Object.keys(WIDGET_THEMES)?.map((option: any) => {
            return (
              <ListItem
                button
                onClick={() => onWidgetThemeChange(option)}
                sx={{ height: '30px', color: '#00000061' }}
              >
                <ListItemText primary={WIDGET_THEMES[option].displayName} />
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </Box>
  );
}
