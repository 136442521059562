import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Stack, TextField } from '@mui/material';
import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import lodash from 'lodash';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import DnDWidgetConfig from '../components/DnDWidgetConfig/DnDWidgetConfig';
import { lastMovedWidgetInterface } from '../Interfaces/pageConfigPageInterfaces';
import {
  findClusterIdByPageId,
  getPageConfigDataV2,
  saveUnifiedPageConfigDataV2,
} from '../services/unifiedPageConfig/unifiedPageConfig';
import {
  AnalyticsWidget,
  HeadingWidget,
  IInflatedDashboadPage,
  IInflatedWidgetCluster,
  MapWidget,
  SankeyWidget,
} from '../Interfaces/newDashboardInterfaces/inflatedDashbaordPage.interfaces';
import { getSensorListForUserId } from '../services/sensors';
import { getMasterCustomWidget } from '../services/dashboard/widget';
import DeleteConfirmation from '../globalComponents/dialogs/DeleteConfirmation';
import { deleteDashboardPage } from '../services/DashboardPageList';
import { delay } from '../utils/time.utils';
import DnDHeadingWidgetConfig from '../components/DnDWidgetConfig/DnDHeadingWidgetConfig';
import RefreshPageComponent from '../utilities/RefreshPage/RefreshPageComponent';
import useIsMobile from '../utilities/customHooks/useIsMobile';
import useIsTablet from '../utilities/customHooks/useIsTablet';
import RefreshPageVariables from '../utilities/RefreshPage/RefreshPageVariables';
import WidgetSelectPopup from '../components/DnDWidgetConfig/WidgetSelectPopup';
import DnDMapWidgetConfig from '../components/DnDMapWidgetConfig/DnDMapWidgetConfig';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { BREAKPOINT_OPTIONS } from '../constants/breakPoints.constants';
import PageLayoutSettings from '../components/DndUnifiedPageConfigComponents/PageLayoutSettings';
import DnDSankeyWidgetConfig from '../components/SankeyWidgetConfig/DnDSankeyWidgetConfig';
import { useUnifiedDashboardStore } from '../store/UnifiedDashboardStore';
import ScreenLayoutSelect from '../components/DndUnifiedPageConfigComponents/ScreenLayoutSelect';
import PageConfigToolBar from '../components/DndUnifiedPageConfigComponents/PageConfigToolBar';
import { getLayoutFromPageData } from '../utils/dashboard.utils';
import CustomGridLayout from '../components/CustomLayouts/CustomGridLayout';

const styles = {
  pageConfigLoadingView: {
    width: '100%',
    height: '500px',
    display: 'grid',
    placeItems: 'center',
  },
  pageConfigHeader: {
    background: '#eee',
  },
  pageConfigHeaderLeftPart: {
    width: { xs: '90%', sm: '75%' },
    background: 'red',
  },
  addWidgetIcon: {
    marginRight: '20px',
  },
  widgetClusterHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10px',
    boxSizing: 'border-box',
  },
  saveButtonLoadingCircle: {
    marginLeft: '10px',
    color: '#ddd',
  },
};

export default function DnDUnifiedPageConfig() {
  const {
    expireTime,
    setExpireTime,
    checkboxChecked,
    setCheckboxChecked,
    customRefreshInterval,
    setCustomRefreshInterval,
    enableCustomRefreshOnly,
    setEnableCustomRefreshOnly,
  } = RefreshPageVariables();

  const refreshProps = {
    checkboxChecked,
    customRefreshInterval,
    enableCustomRefreshOnly,
  };

  const { pageId: paramsPageId } = useParams();
  const navigate = useNavigate();
  const { selectedUnifiedPageId, allowDrag, setSelectedUnifiedPageId, setPageMode } =
    useUnifiedDashboardStore();

  const [pageData, setPageData] = useState<IInflatedDashboadPage['pageConfig'] | null>(null);
  const [completePageData, setCompletePageData] = useState<any>(null);
  //@ts-ignore
  const [previousPageState, setPreviousPageState] = useState<any>(null);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [lastMovedWidget, setLastMovedWidget] = useState<lastMovedWidgetInterface>({
    widgetName: '',
    direction: '',
  });
  const [sensorList, setSensorList] = useState<any[]>([]);
  const [customWidget, setCustomWidget] = useState<any>(null);
  const [initialPageData, setInitialPageData] = useState<any>(null);
  const [deletedWidgets, setDeletedWidgets] = useState<any[]>([]);
  const [showPageDeleteModal, setShowPageDeleteModal] = useState<boolean>(false);
  const [
    //@ts-ignore
    processingDeleteConfirmationCallBackBack,
    setProcessingDeleteConfirmationCallBack,
  ] = useState<boolean>(false);
  const [clusterId, setClusterId] = useState<string | null>(null);
  const [openWidgetSelectPopup, setOpenWidgetSelectPopup] = useState(false);
  const [selectedWidgetClusterIndex, setSelectedWidgetClusterIndex] = useState(0);
  const [masterMapWidget, setMasterMapWidget] = useState<any>(null);
  const [masterHeadingWidget, setMasterHeadingWidget] = useState<any>(null);
  const [selectedView, setSelectedView] = useState('xl');
  const [preventCollision, setPreventCollision] = useState('prevent');
  const [compactType, setCompactType] = useState<'horizontal' | 'vertical'>('horizontal');
  const [openPageLayoutSettins, setOpenPageLayoutSettings] = useState(false);
  const [gridLayout, setGridLayout] = useState<any[]>([]);
  const [masterSankeyWidget, setMasterSankeyWidget] = useState<any>(null);

  useEffect(() => {
    getPageConfigDataV2(selectedUnifiedPageId!, 'pageConfig', 'ALL_GRID').then(data => {
      setCheckboxChecked(data?.refreshProps?.checkboxChecked);
      setCustomRefreshInterval(data?.refreshProps?.customRefreshInterval);
      setEnableCustomRefreshOnly(
        localStorage.getItem('enableCustomRefreshOnly') === 'true'
          ? true
          : data?.refreshProps?.enableCustomRefreshOnly
      );
      setCompletePageData(data);
      setPageData(data?.pageConfig);
      setGridLayout(getLayoutFromPageData(data?.pageConfig));
      setInitialPageData(lodash.cloneDeep(data?.pageConfig));
      findClusterIdByPageId(data?._id).then(data => {
        setClusterId(data);
      });
      setLoading(false);
      setRefresh(!refresh);
    });

    getSensorListForUserId().then((data: any) => {
      setSensorList(data);
      setRefresh(!refresh);
    });
    getMasterCustomWidget('graph', 'ALL_GRID').then(data => {
      setCustomWidget(data);
    });
    getMasterCustomWidget('MapWidget', 'ALL_GRID').then(data => {
      setMasterMapWidget(data);
    });

    getMasterCustomWidget('heading', 'ALL_GRID').then(data => {
      setMasterHeadingWidget(data);
    });
    getMasterCustomWidget('SankeyWidget', 'ALL_GRID').then(data => {
      setMasterSankeyWidget(data);
    });

    setRefresh(!refresh);
  }, []);

  useEffect(() => {
    setRefresh(!refresh);
  }, [allowDrag]);

  // useEffect(() => {
  //   getPageConfigDataV2(pageId!, 'pageConfig', 'ALL_GRID').then(data => {
  //     setCheckboxChecked(data?.refreshProps?.checkboxChecked);
  //     setCustomRefreshInterval(data?.refreshProps?.customRefreshInterval);
  //     setEnableCustomRefreshOnly(
  //       localStorage.getItem('enableCustomRefreshOnly') === 'true'
  //         ? true
  //         : data?.refreshProps?.enableCustomRefreshOnly
  //     );
  //     setCompletePageData(data);
  //     setPageData(data?.pageConfig);
  //     setGridLayout(getLayoutFromPageData(data?.pageConfig));
  //     setInitialPageData(lodash.cloneDeep(data?.pageConfig));
  //     findClusterIdByPageId(data?._id).then(data => {
  //       setClusterId(data);
  //     });
  //     setLoading(false);
  //     setRefresh(!refresh);
  //   });
  // }, [pageId]);

  const savePageConfig = () => {
    if (checkboxChecked && (customRefreshInterval < 60 || customRefreshInterval > 600)) {
      toast.error('Refresh range should be between 60 to 600 seconds');
      return;
    }
    setSaving(true);
    const isStateChanged = lodash.isEqual(initialPageData, pageData);
    const saveHistory = !isStateChanged;

    saveUnifiedPageConfigDataV2(
      selectedUnifiedPageId!,
      completePageData,
      deletedWidgets,
      saveHistory,
      refreshProps
    ).then(data => {
      setSaving(false);
      toast.success('Saved Successfully!');
      if (paramsPageId == '11223344') {
        setSelectedUnifiedPageId(data);
        navigate(`/UnifiedDashboardPage/${data}`);
      }
    });
  };

  const changePageProperty = (property: string, value: any) => {
    if (pageData != null) {
      pageData[property] = value;
      setPageData(pageData);
      setRefresh(!refresh);
    }
  };

  const addCustomWidget = (
    selectedWidgetType: string,
    widgetClusterIndex: number,
    plantIds: string[],
    plantName: string
  ) => {
    let csWidgetCount = pageData!.data[widgetClusterIndex].widgets.reduce(
      (total: any, currentWidget: any) => {
        if (currentWidget.widgetOrigin == 'custom') return total + 1;
        else return total;
      },
      0
    );

    let heightOfExistingLayout = pageData!.data[widgetClusterIndex].widgets.reduce(
      (total: any, currentWidget: any) => {
        return currentWidget.gridProps.xl.y + currentWidget.gridProps.xl.h > total
          ? currentWidget.gridProps.xl.y + currentWidget.gridProps.xl.h
          : total;
      },
      0
    );

    let newCustomWidget = lodash.cloneDeep(customWidget);
    newCustomWidget['widgetName'] = 'Custom Widget ' + (csWidgetCount + 1);
    newCustomWidget['widgetNickName'] = 'Custom Widget' + (csWidgetCount + 1);
    newCustomWidget['plantIds'] = plantIds;
    newCustomWidget['plantName'] = plantName;
    newCustomWidget.gridProps.xl.y = heightOfExistingLayout;
    newCustomWidget['widgetType'] = selectedWidgetType;
    if (
      selectedWidgetType == 'number' ||
      selectedWidgetType == 'number2' ||
      selectedWidgetType == 'NumberGauge'
    ) {
      newCustomWidget.gridProps.xl.w = 4;
      newCustomWidget.gridProps.xl.h = 4;
    }

    pageData?.data[widgetClusterIndex].widgets.push(newCustomWidget);
    let newLayout = getLayoutFromPageData(pageData);
    setGridLayout(newLayout);
    toast(`Added new custom widget`);
    setRefresh(!refresh);
  };

  const addCustomMapWidget = (widgetClusterIndex: number, plantsForUser: any[]) => {
    let csWidgetCount = pageData!.data[widgetClusterIndex].widgets.reduce(
      (total: any, currentWidget: any) => {
        if (currentWidget.widgetType == 'MapWidget') return total + 1;
        else return total;
      },
      0
    );

    let newMapWidget = lodash.cloneDeep(masterMapWidget);
    newMapWidget['widgetName'] = 'Map Widget ' + (csWidgetCount + 1);
    newMapWidget.plants = lodash.cloneDeep(plantsForUser);

    let heightOfExistingLayout = pageData!.data[widgetClusterIndex].widgets.reduce(
      (total: any, currentWidget: any) => {
        return currentWidget.gridProps.xl.y + currentWidget.gridProps.xl.h > total
          ? currentWidget.gridProps.xl.y + currentWidget.gridProps.xl.h
          : total;
      },
      0
    );

    newMapWidget.gridProps.xl.y = heightOfExistingLayout;

    pageData?.data[widgetClusterIndex].widgets.push(newMapWidget);
    let newLayout = getLayoutFromPageData(pageData);
    setGridLayout(newLayout);
    toast(`Added new custom Map widget`);
    setRefresh(!refresh);
  };

  const addCustomHeadingWidget = (widgetClusterIndex: number, plantsForUser: any[]) => {
    let csWidgetCount = pageData!.data[widgetClusterIndex].widgets.reduce(
      (total: any, currentWidget: any) => {
        if (currentWidget.widgetType == 'HeadingWidget') return total + 1;
        else return total;
      },
      0
    );

    let newWidget = lodash.cloneDeep(masterHeadingWidget);
    newWidget['widgetName'] = 'Heading Widget ' + (csWidgetCount + 1);
    newWidget.plants = lodash.cloneDeep(plantsForUser);

    pageData?.data[widgetClusterIndex].widgets.push(newWidget);
    let newLayout = getLayoutFromPageData(pageData);
    setGridLayout(newLayout);
    toast(`Added new custom Map widget`);
    setRefresh(!refresh);
  };

  const addCustomSankeyWidget = (widgetClusterIndex: number) => {
    let csWidgetCount = pageData!.data[widgetClusterIndex].widgets.reduce(
      (total: any, currentWidget: any) => {
        if (currentWidget.widgetType == 'SankeyWidget') return total + 1;
        else return total;
      },
      0
    );

    let newWidget = lodash.cloneDeep(masterSankeyWidget);
    newWidget['widgetName'] = 'Sankey Widget ' + (csWidgetCount + 1);
    pageData?.data[widgetClusterIndex].widgets.push(newWidget);
    let newLayout = getLayoutFromPageData(pageData);
    setGridLayout(newLayout);
    toast(`Added new custom Sankey widget`);
    setRefresh(!refresh);
  };

  const moveWidgetPosition = (
    widgetClusterIndex: number,
    widgetIndex: number,
    action: string
  ): void => {
    let totalWidgetCount = pageData?.data[widgetClusterIndex].widgets.length || 0;
    let tempPageData = pageData!;
    let currWidget = lodash.clone(tempPageData.data[widgetClusterIndex].widgets[widgetIndex]);
    if (action == 'up') {
      if (widgetIndex > 1) {
        // because widget 0 is heading
        let upWidget = lodash.clone(tempPageData.data[widgetClusterIndex].widgets[widgetIndex - 1]);
        tempPageData.data[widgetClusterIndex].widgets[widgetIndex] = upWidget;
        tempPageData.data[widgetClusterIndex].widgets[widgetIndex - 1] = currWidget;
      } else {
        toast('Cannot go any higher');
      }
      setLastMovedWidget({
        widgetName: currWidget.widgetName,
        direction: 'up',
      });
    } else if (action == 'down') {
      if (widgetIndex < totalWidgetCount - 1) {
        let downWidget = lodash.clone(
          tempPageData.data[widgetClusterIndex].widgets[widgetIndex + 1]
        );
        tempPageData.data[widgetClusterIndex].widgets[widgetIndex] = downWidget;
        tempPageData.data[widgetClusterIndex].widgets[widgetIndex + 1] = currWidget;
      } else {
        toast('Cannot go any lower');
      }
      setLastMovedWidget({
        widgetName: currWidget.widgetName,
        direction: 'down',
      });
    }

    /// reset widgetOrder
    tempPageData.data[widgetClusterIndex].widgets.forEach((widget, widgetIndex: number) => {
      widget.widgetOrder = widgetIndex;
    });

    setPageData(tempPageData);

    setRefresh(!refresh);
  };

  // const changeDefaultGranularitySettings = (property: string, value: any) => {
  //   if (pageData != null) {
  //     pageData.defaultGranularitySettings[property] = value;
  //     setPageData(pageData);
  //     setRefresh(!refresh);
  //   }
  // };

  const deletePage = async () => {
    let res: any = await deleteDashboardPage(clusterId!, selectedUnifiedPageId!);
    if (res.data) {
      let { data } = res;
      toast(data.data.message);
      await delay(1000);
      navigate(`/UnifiedDashboardPageList`);
    }
  };

  const changeHeadingWidgetHeading = async (
    widgetClusterIndex: number,
    widgetIndex: number,
    language: string,
    value: string
  ) => {
    if (pageData != null) {
      pageData.data[widgetClusterIndex].widgets[widgetIndex].heading[language] = value;
      setPageData(pageData);
      setRefresh(!refresh);
    }
  };

  const handleLayoutChange = (layout: any) => {
    if (pageData != null) {
      let widgets = pageData.data[0]?.widgets;
      for (const layoutItem of layout) {
        let widget = widgets.find(
          (widget: any, widgetIndex: number) => layoutItem.i == `${0}-${widgetIndex}`
        );
        if (widget) {
          widget.gridProps.xl = layoutItem;
        }
      }

      setPageData(pageData);
      setRefresh(!refresh);
    }
  };

  const changeWidgetTheme = (widgetClusterIndex: number, widgetIndex: number, theme: string) => {
    if (pageData) {
      if (pageData.data[widgetClusterIndex].widgets[widgetIndex]?.['widgetLook'])
        pageData.data[widgetClusterIndex].widgets[widgetIndex]['widgetLook']['theme'] = theme;
      else pageData.data[widgetClusterIndex].widgets[widgetIndex]['widgetLook'] = { theme: theme };

      setPageData(pageData);
      setRefresh(!refresh);
    }
  };

  // const handleLayoutChange = (newLayout: any) => {
  //   console.log('newLayout1: ', newLayout);

  //   if (pageData != null) {
  //     const totalColumns = 12; // Assume a 12-column grid
  //     const groupedByY: { [key: number]: any[] } = {};

  //     // Group components by y coordinate
  //     newLayout.forEach(layoutItem => {
  //       if (!groupedByY[layoutItem.y]) {
  //         groupedByY[layoutItem.y] = [];
  //       }
  //       groupedByY[layoutItem.y].push(layoutItem);
  //     });

  //     let currentY = 0;
  //     const reorderedLayout: any[] = [];

  //     Object.keys(groupedByY)
  //       .sort((a, b) => parseInt(a) - parseInt(b)) // Sort by y coordinate
  //       .forEach(yKey => {
  //         const items = groupedByY[yKey];
  //         let totalWidth = items.reduce((sum, item) => sum + item.w, 0);
  //         let availableSpace = totalColumns - totalWidth;

  //         // Ensure no element is wider than the total grid width
  //         items.forEach(item => {
  //           if (item.w > totalColumns) {
  //             item.w = totalColumns;
  //           }
  //         });

  //         // Distribute available space or reduce widths
  //         if (availableSpace > 0) {
  //           items.forEach(item => {
  //             if (availableSpace > 0) {
  //               item.w += 1;
  //               availableSpace -= 1;
  //             }
  //           });
  //         } else if (availableSpace < 0) {
  //           items.forEach(item => {
  //             if (availableSpace < 0 && item.w > 1) {
  //               item.w -= 1;
  //               availableSpace += 1;
  //             }
  //           });
  //         }

  //         let currentX = 0;

  //         items.forEach(item => {
  //           if (currentX + item.w > totalColumns) {
  //             // If item doesn't fit, move to next row
  //             currentY += 1;
  //             currentX = 0;
  //           }

  //           reorderedLayout.push({
  //             ...item,
  //             x: currentX,
  //             y: currentY,
  //           });

  //           currentX += item.w; // Update the x position for the next item
  //         });

  //         currentY += 1; // Move to the next row for the next group
  //       });

  //     if (!lodash.isEqual(reorderedLayout, gridLayout)) {
  //       console.log('newLayout2: ', reorderedLayout);
  //       setGridLayout(reorderedLayout);
  //       setRefresh(!refresh);
  //     }
  //   }
  // };

  const deleteCustomWidget = (widgetClusterIndex: number, widgetIndex: number) => {
    const widgetToBeDeleted = pageData?.data?.[widgetClusterIndex]?.widgets?.[widgetIndex];
    let widgetName = widgetToBeDeleted?.widgetName;

    pageData?.data[widgetClusterIndex]?.widgets?.splice(widgetIndex, 1);
    setGridLayout(getLayoutFromPageData(pageData));

    const summaryDeletedWidget = {
      id: widgetToBeDeleted?._id,
      widgetType: widgetToBeDeleted?.widgetType,
    };

    setDeletedWidgets(previousDeletedWidgets => {
      return [...previousDeletedWidgets, summaryDeletedWidget];
    });
    setRefresh(!refresh);
    toast(`${widgetName} deleted Successfully!`);
  };

  const onChangeLayoutSettings = (property: string, newValue: any) => {
    if (property == 'preventCollision') {
      setPreventCollision(newValue);
    }
    if (property == 'compactType') setCompactType(newValue);
  };

  const handleWidgetSizeChange = (
    widgetClusterIndex: number,
    widgetIndex: number,
    width: number,
    height: number
  ) => {
    if (pageData) {
      pageData.data[widgetClusterIndex].widgets[widgetIndex].gridProps.xl.w = width;
      pageData.data[widgetClusterIndex].widgets[widgetIndex].gridProps.xl.h = height;
      let newLayout = getLayoutFromPageData(pageData);
      setGridLayout(newLayout);
      setPageData(pageData);
      setRefresh(!refresh);
    }
  };

  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const isTablet = useIsTablet();

  if (loading)
    return (
      <Stack direction="column">
        <h1
          style={{
            margin: '0',
            marginTop: isTablet ? '6px' : '2px',
            fontSize: isTablet ? '1.6rem' : '2rem',
            color: '#1A3356',
          }}
        >
          Dashboard
        </h1>
        <Box sx={styles.pageConfigLoadingView}>
          <CircularProgress />
        </Box>
      </Stack>
    );
  else if (pageData)
    return (
      // <LayoutComponent plantId={null} factoryResetContainer={true}>
      <Box
        px={4}
        sx={{
          background: '#F5F6F9',
          // background: 'red',
        }}
      >
        {isMobile ? (
          <>
            <Stack direction="column" gap={2}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                <h1
                  style={{
                    margin: 0,
                    marginTop: '-4px',
                    fontSize: '1.5rem',
                    color: '#1A3356',
                  }}
                >
                  Dashboard
                </h1>

                <ScreenLayoutSelect
                  selectedView={selectedView}
                  onChange={(value: any) => {
                    setSelectedView(value);
                  }}
                />
              </Stack>
              <Box sx={{ marginLeft: 'auto' }}>
                <PageConfigToolBar
                  onSettingsButtonClick={() => {
                    setOpenPageLayoutSettings(true);
                  }}
                  onSaveIconClick={() => {
                    savePageConfig();
                  }}
                  onAddChartIconClick={() => {
                    setSelectedWidgetClusterIndex(0);
                    setOpenWidgetSelectPopup(true);
                  }}
                  onCloseIconClick={() => {
                    setPageMode('view');
                  }}
                  loading={saving}
                />
              </Box>
            </Stack>
          </>
        ) : (
          <Stack direction="row" alignItems="center" gap={2} mb={2}>
            <Stack direction="row" gap={isTablet ? 2 : 4}>
              <h1
                style={{
                  margin: '0',
                  marginTop: isTablet ? '6px' : '2px',
                  fontSize: isTablet ? '1.6rem' : '2rem',
                  color: '#1A3356',
                }}
              >
                Dashboard
              </h1>
            </Stack>

            <ScreenLayoutSelect
              selectedView={selectedView}
              onChange={(value: any) => {
                setSelectedView(value);
              }}
            />
            <PageConfigToolBar
              onSettingsButtonClick={() => {
                setOpenPageLayoutSettings(true);
              }}
              onSaveIconClick={() => {
                savePageConfig();
              }}
              onAddChartIconClick={() => {
                setSelectedWidgetClusterIndex(0);
                setOpenWidgetSelectPopup(true);
              }}
              onCloseIconClick={() => {
                setPageMode('view');
              }}
              loading={saving}
            />
          </Stack>
        )}
        <Stack
          borderRadius={5}
          // border="1px solid #BDBDBD"
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
          gap={2}
          alignItems="center"
          mb={2}
          mt={5}
          className="pageConfigHeader"
        >
          <Box sx={{ background: '#fff', borderRadius: '8px' }} border="1px solid #BDBDBD" p={2}>
            <TextField
              inputProps={{ style: { fontSize: '16px' } }}
              id="outlined-basic"
              label="Page Name"
              variant="outlined"
              key="pageName"
              defaultValue={pageData.pageName}
              sx={{ width: { xs: '85vw', sm: '250px', md: '300px' } }}
              onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
                changePageProperty('pageName', e.target.value);
              }}
            />
          </Box>
          <Box sx={{ background: '#fff', borderRadius: '8px' }} border="1px solid #BDBDBD" p={2}>
            <RefreshPageComponent
              expireTime={expireTime}
              setExpireTime={setExpireTime}
              checkboxChecked={checkboxChecked}
              setCheckboxChecked={setCheckboxChecked}
              customRefreshInterval={customRefreshInterval}
              setCustomRefreshInterval={setCustomRefreshInterval}
              enableCustomRefreshOnly={enableCustomRefreshOnly}
              setEnableCustomRefreshOnly={setEnableCustomRefreshOnly}
            />
          </Box>
        </Stack>

        <CustomGridLayout
          layouts={{ lg: gridLayout }}
          cols={{ lg: 24, md: 16, sm: 8, xs: 8, xxs: 8 }}
          isDraggable={allowDrag}
          preventCollision={preventCollision}
          compactType={null}
          style={{
            // border: '1px solid black',
            width: BREAKPOINT_OPTIONS[selectedView].value,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          onLayoutChange={(layout: any) => {
            handleLayoutChange(layout);
          }}
        >
          {pageData.data.map(
            (widgetCluster: IInflatedWidgetCluster, widgetClusterIndex: number) =>
              widgetCluster.widgets.map(
                (
                  widget: AnalyticsWidget | HeadingWidget | MapWidget | SankeyWidget,
                  widgetIndex: number
                ) => {
                  if (widget.widgetType === 'heading') {
                    return (
                      <Box
                        key={`${widgetClusterIndex}-${widgetIndex}`}
                        // data-grid={widget?.gridProps?.xl}
                      >
                        <DnDHeadingWidgetConfig
                          //@ts-ignore
                          widget={widget}
                          widgetClusterIndex={widgetClusterIndex}
                          widgetIndex={widgetIndex}
                          text={widget.heading?.english}
                          changeHeadingWidgetHeading={changeHeadingWidgetHeading}
                          deleteCustomWidget={deleteCustomWidget}
                        />
                      </Box>
                    );
                  } else if (widget.widgetType == 'MapWidget') {
                    return (
                      <Box
                        key={`${widgetClusterIndex}-${widgetIndex}`}
                        // data-grid={widget.gridProps?.xl}
                      >
                        <DnDMapWidgetConfig
                          key={widget?.widgetName + widgetIndex}
                          pageData={pageData}
                          setPageData={setPageData}
                          widgetCluster={widgetCluster.clusterName}
                          widgetClusterIndex={widgetClusterIndex}
                          //@ts-ignore
                          widget={widget}
                          widgetIndex={widgetIndex}
                          moveWidgetPosition={moveWidgetPosition}
                          lastMovedWidget={lastMovedWidget}
                          sensorList={sensorList}
                          setDeletedWidgets={setDeletedWidgets}
                          handleWidgetSizeChange={handleWidgetSizeChange}
                          deleteCustomWidget={deleteCustomWidget}
                        />
                      </Box>
                    );
                  } else if (widget.widgetType == 'SankeyWidget') {
                    return (
                      <Box
                        key={`${widgetClusterIndex}-${widgetIndex}`}
                        // data-grid={widget.gridProps?.xl}
                        borderRadius={4}
                        sx={{ overflow: 'hidden' }}
                      >
                        {' '}
                        <DnDSankeyWidgetConfig
                          widget={widget}
                          sensorList={sensorList}
                          refresh={refresh}
                          setRefresh={setRefresh}
                          deleteCustomWidget={deleteCustomWidget}
                          widgetClusterIndex={widgetClusterIndex}
                          widgetIndex={widgetIndex}
                          pageData={pageData}
                          setPageData={setPageData}
                          handleWidgetSizeChange={handleWidgetSizeChange}
                          changeWidgetTheme={changeWidgetTheme}
                        />
                      </Box>
                    );
                  } else {
                    return (
                      <Box
                        key={`${widgetClusterIndex}-${widgetIndex}`}
                        // data-grid={widget.gridProps?.xl}
                        borderRadius={4}
                        sx={{ overflow: 'hidden' }}
                      >
                        <DnDWidgetConfig
                          // key={widget?.widgetName + widgetIndex}
                          pageData={pageData}
                          setPageData={setPageData}
                          widgetCluster={widgetCluster.clusterName}
                          widgetClusterIndex={widgetClusterIndex}
                          //@ts-ignore
                          widget={widget}
                          widgetIndex={widgetIndex}
                          moveWidgetPosition={moveWidgetPosition}
                          lastMovedWidget={lastMovedWidget}
                          sensorList={sensorList}
                          refresh={refresh}
                          setRefresh={setRefresh}
                          setDeletedWidgets={setDeletedWidgets}
                          handleWidgetSizeChange={handleWidgetSizeChange}
                          deleteCustomWidget={deleteCustomWidget}
                          setGridLayout={setGridLayout}
                          changeWidgetTheme={changeWidgetTheme}
                        />
                      </Box>
                    );
                  }
                  // end of return for widget
                }
              ) //  end of return for widget cluster
          )}
        </CustomGridLayout>

        <DeleteConfirmation
          showDeleteModal={showPageDeleteModal}
          setShowDeleteModal={setShowPageDeleteModal}
          alertMessage="Are you sure you want to delete this page ?"
          processing={false}
          setProcessing={setProcessingDeleteConfirmationCallBack}
          onConfirmAction={() => {
            deletePage();
          }}
        />
        <WidgetSelectPopup
          open={openWidgetSelectPopup}
          onSelect={(
            selectedWidgetType: string,

            selectedPlants: any,
            plantName: string
          ) => {
            if (['graph', 'tableC', 'number2', 'NumberGauge'].includes(selectedWidgetType))
              addCustomWidget(
                selectedWidgetType,
                selectedWidgetClusterIndex,
                selectedPlants,
                plantName
              );
            else if (selectedWidgetType == 'MapWidget')
              addCustomMapWidget(selectedWidgetClusterIndex, selectedPlants);
            else if (selectedWidgetType == 'SankeyWidget') {
              addCustomSankeyWidget(selectedWidgetClusterIndex);
            } else addCustomHeadingWidget(selectedWidgetClusterIndex, selectedPlants);
          }}
          onClose={() => {
            setOpenWidgetSelectPopup(false);
          }}
          widgetOptions={[
            { label: 'Graph Widget', value: 'graph' },
            { label: 'Table Widget', value: 'tableC' },
            { label: 'Range Number Widget', value: 'number2' },
            { label: 'Gauge Number Widget', value: 'NumberGauge' },
            { label: 'Map Widget', value: 'MapWidget' },
            { label: 'Heading Widget', value: 'HeadingWidget' },
            { label: 'Sankey Widget', value: 'SankeyWidget' },
          ]}
        />
        <PageLayoutSettings
          open={openPageLayoutSettins}
          handleClose={() => {
            setOpenPageLayoutSettings(false);
          }}
          pageLayoutSettings={{ preventCollision: preventCollision, compactType: compactType }}
          onChangeLayoutSettings={onChangeLayoutSettings}
        />
      </Box>
      // </LayoutComponent>
    );
  else return null; //  end of return for page component
}
