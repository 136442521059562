export default function BatteryIcon({ fillColor = '#3DA385', width = '9', height = '17' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5463 2.34869C7.56556 2.29573 7.56556 2.23796 7.56556 2.18501V2.16575C7.56556 1.66024 7.11301 1.2414 6.54974 1.2414H6.07794V0.609388C6.07794 0.548974 6.02897 0.5 5.96856 0.5H4.31938C4.25896 0.5 4.20999 0.548974 4.20999 0.609387V1.2414H3.68523C3.12196 1.2414 2.70311 1.66024 2.70311 2.16575V2.18501C2.70311 2.23796 2.72237 2.29573 2.72237 2.34869H2.44795C2.10614 2.34869 1.72099 2.47386 1.54286 2.72902C1.45139 2.83975 1.39844 2.96492 1.39844 3.12861V13.4505C1.39844 13.5034 1.41768 13.5612 1.41768 13.5949C1.50916 14.0667 1.99541 14.5 2.45277 14.5H7.82072C8.33104 14.5 8.60063 13.956 8.60063 13.4457V3.12379C8.60063 2.61348 8.33104 2.34388 7.82072 2.34388H7.55112L7.5463 2.34869ZM5.60614 7.31705H6.57096C6.6286 7.31705 6.66079 7.38357 6.62503 7.42877L6.3668 7.75515L4.80696 9.78679L4.60957 10.0227L4.40651 10.2771C4.35947 10.336 4.26552 10.2875 4.28637 10.215L4.87917 8.15474H3.42208C3.36466 8.15474 3.3324 8.08866 3.36773 8.04339L3.93075 7.32187L4.13296 7.05227L4.33035 6.79711L4.85511 6.12792L5.58198 5.20449C5.62874 5.14508 5.72321 5.19338 5.70245 5.26607L5.18247 7.08597L5.11026 7.32187H5.60132L5.60614 7.31705Z"
        stroke={fillColor}
      />
    </svg>
  );
}
