import LayoutComponent from '../components/LayoutComponent';
import DataBreakTable from '../components/DataBreak/DataBreakViewTable';
import { ThemeProvider } from '@emotion/react';
import Theme from '../core-components/theme/theme';
import TwoRowLayout from '../core-components/theme/layouts/TwoLayout/TwoLayoutComponent';
import SearchBox from '../core-components/theme/components/Searchbox/Searchbox';
import { useState } from 'react';

export default function DataBreakForPlant() {
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <ThemeProvider theme={Theme}>
      <LayoutComponent>
        <TwoRowLayout
          child1={
            <SearchBox
              onSearch={searchQuery => {
                setSearchQuery(searchQuery);
              }}
              onClear={() => {
                setSearchQuery('');
              }}
              placeholder="Search for Plant"
              fullWidth
            />
          }
          child2={<DataBreakTable searchQuery={searchQuery} />}
          containerProps={{ sx: { mt: 5 } }}
        />
      </LayoutComponent>
    </ThemeProvider>
  );
}
