import {
  Box,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  Autocomplete,
  TextField,
} from '@mui/material';
import GeneralMetricSearchBar from '../../workspace/GeneralMetricSearchBar';

interface SensorSettingsForSankeyProps {
  flowConfig: any;
  sensorList: any[];
  setRefresh: Function;
}

export default function SensorSettingsForSankey({
  flowConfig,
  sensorList,
  setRefresh,
}: SensorSettingsForSankeyProps) {
  const valueTypes = ['raw', 'avg', 'min', 'max', 'timeWeightedSum', 'cumulative'];

  const handleWeghtConfigPropertyChange = (property: string, value: any) => {
    if (flowConfig?.weightConfig?.[property]) {
      flowConfig.weightConfig[property] = value;
    }
    setRefresh((state: boolean) => !state);
  };

  const handleAddSensor = (sensor: any) => {
    if (flowConfig.sensorConfig) {
      flowConfig.sensorConfig['sensor'] = sensor;
      console.log('flowConfig : ', flowConfig);
    }
    setRefresh((state: boolean) => !state);
  };

  const handleSensorConfigPropertyChange = (property: string, value: any) => {
    if (flowConfig.sensorConfig) {
      flowConfig.sensorConfig[property] = value;
      console.log('flowConfig :', flowConfig);
      setRefresh((state: boolean) => !state);
    }
  };

  const getAddedSensorList = () => {
    return [flowConfig.sensorConfig.sensor];
  };

  return (
    <Stack direction="column" gap={2}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Flow Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={flowConfig?.weightConfig?.weightType || 'number'}
          label="Widget Layout"
          onChange={event => {
            handleWeghtConfigPropertyChange('weightType', event.target.value);
          }}
        >
          <MenuItem value={'number'}>Number</MenuItem>
          <MenuItem value={'sensor'}>Sensor</MenuItem>
        </Select>
      </FormControl>
      {flowConfig?.weightConfig?.weightType == 'sensor' && (
        <Stack direction="column" gap={3}>
          <Box>
            <FormLabel style={{ marginBottom: '10px' }} component="legend">
              * select the type of aggregation you want
            </FormLabel>
            <Autocomplete
              id="tags-filled"
              options={valueTypes}
              // getOptionLabel={option => option.title}
              // defaultValue={[valueTypes[0]]}
              filterSelectedOptions
              value={flowConfig?.sensorConfig?.valueTypes || ''}
              onChange={(e, newValue) => {
                handleSensorConfigPropertyChange('valueTypes', [newValue]);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  // label="value types you wanna see"
                  placeholder=""
                />
              )}
            />
          </Box>
          <Box sx={{ border: '1px solid #aaa' }} borderRadius={1}>
            <GeneralMetricSearchBar
              sensorList={sensorList}
              keywordFilterList={[]}
              addedSensorList={getAddedSensorList()}
              handleAddSensor={handleAddSensor}
            />
          </Box>
        </Stack>
      )}
    </Stack>
  );
}
