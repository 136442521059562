import { Stack, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface SensorNameDisplayComponentProps {
  sensor: { sensorNickName: string; sensorTag: string };
}

const styles = {
  infoIcon: {
    fontSize: 18,
    color: '#999',
  },
};

export default function SensorNameDisplayComponent({ sensor }: SensorNameDisplayComponentProps) {
  return (
    <Stack direction="row" alignItems="center" gap={1} width="inherit">
      <span>
        {sensor?.sensorNickName && sensor?.sensorNickName != ''
          ? sensor.sensorNickName
          : sensor.sensorTag}
      </span>
      {sensor.sensorNickName && (
        <Tooltip title={sensor.sensorTag}>
          <InfoOutlinedIcon sx={styles.infoIcon} />
        </Tooltip>
      )}
    </Stack>
  );
}
