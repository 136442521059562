export default function PhysicalIcon({ fillColor = '#3DA385', width = '9', height = '17' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 0.967433H10.8C9.96602 0.967433 9.13202 0.685434 8.40002 0.169434C6.93602 1.19543 5.06402 1.19543 3.60002 0.169434C2.86802 0.685434 2.03402 0.967433 1.20002 0.967433H2.00272e-05V2.16743H1.20002C2.02202 2.16743 2.84402 1.95743 3.60002 1.56743C5.10002 2.34743 6.90002 2.34743 8.40002 1.56743C9.15602 1.95743 9.97202 2.16743 10.8 2.16743H12V0.967433ZM12 4.29878H10.8C9.966 4.29878 9.132 4.01678 8.4 3.50078C6.936 4.52678 5.064 4.52678 3.6 3.50078C2.868 4.01678 2.034 4.29878 1.2 4.29878H0V5.49878H1.2C2.022 5.49878 2.844 5.28878 3.6 4.89878C5.1 5.67878 6.9 5.67878 8.4 4.89878C9.156 5.28878 9.972 5.49878 10.8 5.49878H12V4.29878ZM10.8 7.63012H12V8.83012H10.8C9.972 8.83012 9.156 8.62012 8.4 8.23012C6.9 9.01012 5.1 9.01012 3.6 8.23012C2.844 8.62012 2.022 8.83012 1.2 8.83012H0V7.63012H1.2C2.034 7.63012 2.868 7.34812 3.6 6.83212C5.064 7.85812 6.936 7.85812 8.4 6.83212C9.132 7.34812 9.966 7.63012 10.8 7.63012Z"
        fill={fillColor}
      />
    </svg>
  );
}
