import { useState, useRef } from 'react';
import { Box, Stack, TextField, IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import useOutsideClickDetector from '../../utilities/customHooks/useOutsideClickDetector';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const styles = {
  infoIcon: {
    fontSize: 18,
    color: '#999',
  },
  sensorListContainer: {
    height: '200px',
    overflowY: 'scroll',
    boxSizing: 'border-box',
    background: 'white',
  },
  sensorItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  searchTextField: {
    background: 'white',
    '& fieldset': { border: 'none' },
  },
};
interface sensorItem {
  _id: string;
  sensorId: string;
  sensorTag: string;
  sensorNickName?: string;
}

interface propsInterface {
  sensorList: sensorItem[];
  keywordFilterList: string[];
  addedSensorList: sensorItem[];
  handleAddSensor: Function;
}

function GeneralMetricSearchBar(props: propsInterface) {
  // Sample list of sensors
  const { sensorList, keywordFilterList, addedSensorList, handleAddSensor } = props;
  let keywordFilteredSensorList: sensorItem[] = sensorList;
  if (keywordFilterList.length > 0)
    keywordFilteredSensorList = sensorList.filter((sens: any) => {
      //  console.log("sensorId and tag : ",sens._id.toString() + " " ,sens.sensorTag)
      //@ts-ignore
      return keywordFilterList.some((keyword: any) => {
        if (sens.sensorTag?.includes(keyword)) return true;
      });
    });
  // State to manage the search query
  const [searchQuery, setSearchQuery] = useState('');

  // State to store the selected sensors
  // const [selectedsensors, setSelectedsensors] = useState<any[]>([]);
  const [showSensorlist, setShowSensorList] = useState<Boolean>(false);
  const metricSearchBarResultsDivRef = useRef(null);
  useOutsideClickDetector(metricSearchBarResultsDivRef, () => {
    setShowSensorList(false);
  });

  /// function to update widgetMetricsSensorIds

  // Function to handle sensor selection and adding to the array
  const handleAddNewsensor = (sensor: sensorItem) => {
    handleAddSensor(sensor);
  };

  // Function to handle sensor removal
  // const handleRemovesensor = (sensor: any) => {
  //   const updatedsensors = selectedsensors.filter((item) => item !== sensor);
  //   setSelectedsensors(updatedsensors);
  // };

  // Function to handle search query changes
  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  // Filter the sensors based on the search query
  const filteredsensors = keywordFilteredSensorList.filter(
    (sensor: any) =>
      sensor.sensorTag?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      sensor.sensorNickName?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box borderRadius={1} sx={{ overflow: 'hidden' }} ref={metricSearchBarResultsDivRef}>
      <TextField
        type="text"
        autoComplete="off"
        fullWidth
        value={searchQuery}
        variant="outlined"
        placeholder="Search and add sensors you want to track here"
        // label="filter"
        inputProps={{
          maxLength: 50,
          step: '0.01',
        }}
        sx={styles.searchTextField}
        onChange={handleSearchChange}
        onFocus={() => {
          setShowSensorList(true);
        }}
        onBlur={() => {}}
      />
      {sensorList?.length > 0 && showSensorlist && filteredsensors.length > 0 && (
        <Stack borderTop="0.5px solid #ddd" sx={styles.sensorListContainer}>
          {filteredsensors.map((sensor, index) => (
            <Box key={index} p={1} sx={styles.sensorItem}>
              <Stack direction="row" alignItems="center" gap={1}>
                <span>
                  {sensor?.sensorNickName && sensor?.sensorNickName != ''
                    ? sensor.sensorNickName
                    : sensor.sensorTag}
                </span>
                {sensor.sensorNickName && (
                  <Tooltip title={sensor.sensorTag}>
                    <InfoOutlinedIcon sx={styles.infoIcon} />
                  </Tooltip>
                )}
              </Stack>

              {addedSensorList.find(s => {
                return s?._id === sensor?._id;
              }) ? (
                <IconButton disabled>
                  <CheckIcon fontSize="small" color="success" />
                </IconButton>
              ) : (
                <IconButton onClick={() => handleAddNewsensor(sensor)}>
                  <AddIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          ))}
        </Stack>
      )}
      {sensorList?.length === 0 && <p>No sensorExists</p>}
    </Box>
  );
}

export default GeneralMetricSearchBar;
