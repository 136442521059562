import { Box, Menu, MenuItem, Stack, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LineChart from './lineChartComponent';
import BarChart from './barChartComponent';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useState } from 'react';
import { SecondaryButton } from '../../../styles/global/components/dpButtons';
// import {
//   widgetDataInterface,
//   widgetStructureInterface,
// } from "../../../Interfaces/widgetInterfaces";
import { handleDownloadCSV } from '../../../utilities/helpers';
import ChartIcon from '../../../assets/icons/ChartIcon.svg';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
// import GreenClock from "../../../assets/icons/GreenClock.svg";
import linkIcon from '../../../constants/Images/linkIcon.svg';
import ExpandIcon from '../../../assets/icons/ExpandIcon.svg';
import DownloadIcon from '../../../assets/icons/DownloadCSV.svg';
import ClockIcon from '@mui/icons-material/WatchLater';
import AssistantIcon from '@mui/icons-material/Assistant';
import WidgetAnalyser from './WidgetAnalyser';
import FactoryIcon from '@mui/icons-material/Factory';
import Divider from '@mui/material/Divider';

const styles = {
  graphWidgetLayout: {
    width: 'inherit',
    // maxWidth : "700px",
    height: 'inherit',
    overflowY: 'hidden',
    background: 'white',
    display: 'grid',
    gridTemplateRows: '50px 1fr',
    borderRadius: '16px',
    position: 'relative',
    boxSizing: 'border-box',
    padding: '12px',
  },
  widgetHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    padding: '5px',
    color: '#193458',
    fontFace: 'Roboto',
    fontWeight: '500',
    overflow: 'hidden',
    fontSize: 'clamp(16px,1rem,20px)',
    height: '30px',
  },
  loadingGraphContainer: {
    display: 'grid',
    placeItems: 'center',
    width: '100%',
    height: '100%',
  },
  resetZoomButton: {
    marginLeft: '5px',
    color: 'white',
    fontSize: '10px',
    fontFamily: 'inherit',
    background: '#193458',
  },
  showIconStyle: {
    visibility: 'visible',
    alignSelf: 'center',
  },
  hideIconStyle: {
    visibility: 'hidden',
  },
  truncateTextStyle: {
    display: 'inline-block',
    maxWidth: '120px', // Adjust this value to your desired width
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '1rem',
    cursor: 'pointer',
  },
  textStyle: {
    fontSize: 'clamp(16px,1rem,20px)',
    cursor: 'pointer',
  },
  IconStyle: {
    width: '14px',
    height: '14px',
    marginLeft: '12px',
    '& :hover': { cursor: 'pointer' },
  },
};
// interface genWidgetHeaderInterface {
//   setExpandedViewPopup: Dispatch<SetStateAction<boolean>>;
//   widgetData: any;
//   showLinkIcon: boolean | undefined;
//   linkOnWidget: string | undefined;
//   setSelectedChart?: Function | undefined;
// }

// interface genGraphWidgetpropInterface {
//   widgetData: any;
//   widgetStructure: any;
//   setExpandedViewPopup?: Dispatch<SetStateAction<boolean>>;
//   isLoading: boolean;
//   headerType: "normal" | "expanded";
//   enableZoom: boolean;
//   viewEveryNthTick: number;
//   ticksColor: "light" | "dark";
//   showLinkIcon?: boolean;
//   linkOnWidget?: string;
//   setSelectedChart?: Function | undefined;
// }

export default function GeneralisedGraphWidget(props: any) {
  const {
    // widgetStructure,
    widgetData,
    setExpandedViewPopup,
    isLoading,
    headerType,
    enableZoom,
    viewEveryNthTick,
    ticksColor,
    showLinkIcon,
    linkOnWidget,
    setSelectedChart,
    handleEditClick,
    showClock,
    setShowClock,
    customTimeRangeSettings,
    handleClockClick,
    customGranularity,
    selectChart,
    isBottomBarOpen,
    setIsBottomBarOpen,
    setPreviousSelectedChart,
    widgetTimeFrame,
    featureAccess,
  } = props;

  const numberOfMetricsSelected = widgetData?.metricsData?.datasets?.length ?? 0;

  const [resetZoom, setResetZoom] = useState(false);

  const [showIcon, setShowIcon] = useState(false);
  if (isLoading) {
    return (
      <Box p={1.5} sx={{ ...styles.graphWidgetLayout }}>
        {headerType === 'normal' ? (
          <GeneralisedWidgetHeader
            widgetData={widgetData}
            setExpandedViewPopup={setExpandedViewPopup}
            showLinkIcon={showLinkIcon}
            linkOnWidget={linkOnWidget}
            setSelectedChart={setSelectedChart}
            setPreviousSelectedChart={setPreviousSelectedChart}
            featureAccess={featureAccess}
          />
        ) : (
          <GeneralisedExpandedWidgetHeader
            resetZoom={resetZoom}
            setResetZoom={setResetZoom}
            widgetData={widgetData}
            setExpandedViewPopup={setExpandedViewPopup}
            showLinkIcon={showLinkIcon}
            linkOnWidget={linkOnWidget}
          />
        )}

        <Box style={styles.loadingGraphContainer}>
          <Skeleton variant="rectangular" style={{ width: 'inherit', height: 'inherit' }} />
        </Box>
      </Box>
    );
  } else if (widgetData)
    return (
      <Box
        p={1.5}
        sx={{ ...styles.graphWidgetLayout }}
        onMouseEnter={() => {
          console.log('meeee');
          setShowIcon(true);
        }}
        onMouseLeave={() => {
          setShowIcon(false);
        }}
      >
        {headerType === 'normal' ? (
          <GeneralisedWidgetHeader
            widgetData={widgetData}
            setExpandedViewPopup={setExpandedViewPopup}
            showLinkIcon={showLinkIcon}
            linkOnWidget={linkOnWidget}
            setSelectedChart={setSelectedChart}
            showIcon={showIcon}
            handleEditClick={handleEditClick}
            showClock={showClock}
            selectChart={selectChart}
            setShowClock={setShowClock}
            customTimeRangeSettings={customTimeRangeSettings}
            handleClockClick={handleClockClick}
            customGranularity={customGranularity}
            isBottomBarOpen={isBottomBarOpen}
            setIsBottomBarOpen={setIsBottomBarOpen}
            setPreviousSelectedChart={setPreviousSelectedChart}
            widgetTimeFrame={widgetTimeFrame}
            featureAccess={featureAccess}
          />
        ) : (
          <GeneralisedExpandedWidgetHeader
            resetZoom={resetZoom}
            setResetZoom={setResetZoom}
            widgetData={widgetData}
            setExpandedViewPopup={setExpandedViewPopup}
            showLinkIcon={showLinkIcon}
            linkOnWidget={linkOnWidget}
          />
        )}

        {numberOfMetricsSelected > 0 ? (
          <Box
            className="graphContainer"
            style={{ height: 'auto', width: 'auto', overflow: 'auto' }}
            p={0}
          >
            {!selectChart && (
              <>
                {widgetData?.graphType == 'line' && (
                  <LineChart
                    data={widgetData.metricsData}
                    enableZoom={enableZoom}
                    viewEveryNthTick={viewEveryNthTick}
                    ticksColor={ticksColor === 'dark' ? '#333' : '#68686880'}
                    resetZoom={resetZoom}
                    validRangeSettings={widgetData.validRangeSettings}
                  />
                )}
                {widgetData?.graphType == 'bar' && (
                  <BarChart
                    data={widgetData.metricsData}
                    enableZoom={enableZoom}
                    viewEveryNthTick={viewEveryNthTick}
                    ticksColor={ticksColor === 'dark' ? '#333' : '#68686880'}
                    resetZoom={resetZoom}
                  />
                )}
              </>
            )}
            {selectChart && selectChart == 'line' && (
              <LineChart
                data={widgetData.metricsData}
                enableZoom={enableZoom}
                viewEveryNthTick={viewEveryNthTick}
                ticksColor={ticksColor === 'dark' ? '#333' : '#68686880'}
                resetZoom={resetZoom}
                validRangeSettings={widgetData.validRangeSettings}
              />
            )}
            {selectChart && selectChart == 'bar' && (
              <BarChart
                data={widgetData.metricsData}
                enableZoom={enableZoom}
                viewEveryNthTick={viewEveryNthTick}
                ticksColor={ticksColor === 'dark' ? '#333' : '#68686880'}
                resetZoom={resetZoom}
              />
            )}
          </Box>
        ) : (
          <Box
            style={{
              display: 'grid',
              placeItems: 'center',
              height: 'auto',
              width: 'auto',
              color: '#444',
              fontSize: '16px',
            }}
          >
            <p>No Metric Selected</p>
          </Box>
        )}
      </Box>
    );
  else return null;
}

const TooltipIcon = ({ title, src, style, onClick }: any) => (
  <Tooltip title={title}>
    <img src={src} style={{ ...style, cursor: 'pointer' }} onClick={onClick} />
  </Tooltip>
);

const MenuOption = ({ label, onClick }: any) => <MenuItem onClick={onClick}>{label}</MenuItem>;

function GeneralisedWidgetHeader(props: any) {
  const {
    setExpandedViewPopup,
    widgetData,
    showLinkIcon,
    linkOnWidget,
    setSelectedChart,
    selectChart,
    setPreviousSelectedChart,
    showIcon,
    handleEditClick,
    showClock,
    customGranularity,
    customTimeRangeSettings,
    handleClockClick,
    isBottomBarOpen,
    setIsBottomBarOpen,
    widgetTimeFrame,
    featureAccess,
  } = props;

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const navigateToLink = () => (window.location.href = linkOnWidget!);
  const closeMenu = () => setAnchorEl(null);
  const [aiAnchorEl, setAiAnchorEl] = useState<any>(null);
  const [openAiBox, setOpenAiBox] = useState(false);
  const [refreshHeader, setRefreshHeader] = useState(false);

  useEffect(() => {
    setRefreshHeader(!refreshHeader);
  }, [featureAccess]);

  const selectChartFunc = (chartType: any) => {
    if (setSelectedChart) {
      setPreviousSelectedChart(selectChart);
      setSelectedChart(chartType);
    }
    closeMenu();
  };

  const capitalizeFirstLetter = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const formatTimeRange = () => {
    if (customTimeRangeSettings?.startTime && customTimeRangeSettings?.endTime) {
      const startTime = new Date(customTimeRangeSettings.startTime);
      const endTime = new Date(customTimeRangeSettings.endTime);
      return `${formatDateTime(startTime)} - ${formatDateTime(endTime)} | ${capitalizeFirstLetter(
        customGranularity?.unit || ''
      )}`;
    }
    return '';
  };

  const formatDateTime = (date: Date) => {
    const dateString = date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
    });
    const timeString = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${dateString} ${timeString}`;
  };

  const handleMenuOptionClick = (value: any) => {
    selectChartFunc(value);
  };

  return (
    <Stack>
      <Box className="widgetHeader" sx={{ ...styles.widgetHeader }}>
        <Stack direction="row" spacing={0.6} alignItems="center">
          <Stack direction="row" alignItems="center">
            <Tooltip title={widgetData.widgetNickName || widgetData.widgetName}>
              <Typography variant="h6" sx={styles.textStyle}>
                {widgetData.widgetNickName || widgetData.widgetName}
              </Typography>
            </Tooltip>
            {!showIcon && widgetData?.plantName && (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    marginLeft: '6px',
                    marginRight: '6px',
                    borderRightWidth: 1.8,
                    color: '#7D8188',
                  }}
                />
                <Stack direction="row" alignItems="center" gap={1}>
                  <FactoryIcon
                    fontSize="small"
                    sx={{
                      width: '16px',
                      height: '16px',
                      cursor: 'pointer',
                      color: '#80858CD9',
                      alignSelf: 'center',
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{ color: '#7D8188', fontSize: '14px', fontWeight: 600 }}
                  >
                    {widgetData.plantName}
                  </Typography>
                </Stack>
              </>
            )}
            {showIcon && widgetData?.plantName && (
              <>
                <Tooltip sx={styles.showIconStyle} title={widgetData.plantName}>
                  <FactoryIcon
                    fontSize="small"
                    sx={{
                      width: '16px',
                      height: '16px',
                      cursor: 'pointer',
                      color: '#80858CD9',
                      alignSelf: 'center',
                      marginLeft: '8px !important',
                    }}
                  />
                </Tooltip>
              </>
            )}
          </Stack>

          {showIcon && (
            <>
              <Tooltip sx={styles.showIconStyle} title={widgetData.widgetDescription}>
                <InfoOutlinedIcon
                  fontSize="small"
                  sx={{
                    width: '18px',
                    height: '18px',
                    cursor: 'pointer',
                    color: '#80858CD9',
                    alignSelf: 'center',
                    marginLeft: '8px !important',
                  }}
                />
              </Tooltip>
            </>
          )}
        </Stack>
        {(showIcon || aiAnchorEl) && (
          <Stack
            direction="row"
            spacing={2}
            sx={showIcon ? styles.showIconStyle : styles.hideIconStyle}
          >
            {featureAccess?.['AI analysis tool'] && (
              <Tooltip title="Ai Assistant">
                <AssistantIcon
                  sx={{
                    ...styles.IconStyle,
                    color: openAiBox ? '#038CB2' : '#8a8a8a',
                  }}
                  color="action"
                  onClick={event => {
                    setAiAnchorEl(event.currentTarget);
                    setOpenAiBox(true);
                  }}
                />
              </Tooltip>
            )}
            <TooltipIcon
              title="Download CSV"
              src={DownloadIcon}
              style={styles.IconStyle}
              onClick={() => handleDownloadCSV(widgetData?.metricsData)}
            />
            <TooltipIcon
              title="Expanded View"
              src={ExpandIcon}
              style={styles.IconStyle}
              onClick={() => setExpandedViewPopup(true)}
            />
            <TooltipIcon
              title="Choose chart"
              src={ChartIcon}
              style={styles.IconStyle}
              onClick={(event: any) => {
                console.log(isBottomBarOpen, 'isBottomBarOpen');
                setIsBottomBarOpen(false);
                setAnchorEl(event.currentTarget);
              }}
            />

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
              {selectChart === 'tableC' ? null : (
                <MenuOption
                  label="Table"
                  onClick={() => {
                    handleMenuOptionClick('tableC');
                  }}
                />
              )}
              {selectChart === 'bar' ? null : (
                <MenuOption
                  label="Bar Graph"
                  onClick={() => {
                    handleMenuOptionClick('bar');
                  }}
                />
              )}
              {selectChart === 'line' ? null : (
                <MenuOption
                  label="Line Graph"
                  onClick={() => {
                    handleMenuOptionClick('line');
                  }}
                />
              )}
            </Menu>

            {showLinkIcon && (
              <TooltipIcon
                title="Click to know more"
                src={linkIcon}
                style={styles.IconStyle}
                onClick={navigateToLink}
              />
            )}

            <Tooltip title="Edit Widget" sx={{}}>
              <ModeEditOutlineIcon
                fontSize="small"
                sx={{
                  width: '14px',
                  height: '14px',
                  cursor: 'pointer',
                  color: '#80858CD9',
                  marginLeft: '12px !important',
                }}
                onClick={handleEditClick}
              />
            </Tooltip>
          </Stack>
        )}
      </Box>

      {showClock == 'on' && (
        <Stack direction="row" spacing={1} mb={1}>
          <ClockIcon
            sx={{
              width: '18px',
              height: '18px',
              cursor: 'pointer',
              color: '#5cb85c	',
            }}
            onClick={() => handleClockClick('disabled')}
          />
          <span style={{ fontSize: '13px', color: '#7D8188' }}>{formatTimeRange()}</span>
        </Stack>
      )}
      {showClock === 'disabled' && (
        <Stack direction="row" spacing={1} mb={1}>
          <ClockIcon
            sx={{
              width: '18px',
              height: '18px',
              cursor: 'pointer',
            }}
            color="disabled"
            onClick={() => handleClockClick('on')}
          />
          <span style={{ fontSize: '13px', color: '#7D8188' }}>{formatTimeRange()}</span>
        </Stack>
      )}
      <WidgetAnalyser
        open={openAiBox}
        handleClose={() => {
          console.log('closing');
          setAiAnchorEl(null);
          setOpenAiBox(false);
        }}
        anchorEl={aiAnchorEl}
        widgetData={widgetData}
        widgetTimeFrame={widgetTimeFrame}
      />
    </Stack>
  );
}

function GeneralisedExpandedWidgetHeader(props: any) {
  const { setExpandedViewPopup, widgetData, resetZoom, setResetZoom, showLinkIcon, linkOnWidget } =
    props;

  const clickedOnlinkIcon = () => {
    window.location.href = linkOnWidget;
  };
  return (
    <Box className="widgetHeader" sx={{ ...styles.widgetHeader }}>
      <Stack direction="row" alignItems="center">
        <Tooltip title={widgetData.widgetNickName || widgetData.widgetName}>
          <Typography variant="h6" sx={styles.textStyle}>
            {widgetData.widgetNickName || widgetData.widgetName}
          </Typography>
        </Tooltip>
        {widgetData?.plantName && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                marginLeft: '8px',
                marginRight: '8px',
                borderRightWidth: 2,
                color: '#7D8188',
              }}
            />
            <Stack direction="row" alignItems="center" gap={1}>
              <FactoryIcon
                fontSize="small"
                sx={{
                  width: '16px',
                  height: '16px',
                  cursor: 'pointer',
                  color: '#80858CD9',
                  alignSelf: 'center',
                }}
              />
              <Typography
                variant="h6"
                style={{ color: '#7D8188', fontSize: '14px', fontWeight: 600 }}
              >
                {widgetData.plantName}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>

      <Stack direction="row" alignItems="center" spacing={1}>
        {
          <SecondaryButton
            sx={{ fontSize: '12px' }}
            onClick={() => {
              // if (widgetData.graphType) lineChartRef?.current?.resetZoom();
              // else barChartRef?.current?.resetZoom();
              setResetZoom(!resetZoom);
            }}
          >
            Reset Zoom
          </SecondaryButton>
        }
        {showLinkIcon && (
          <Tooltip title="Click to know more" style={{ padding: '0' }}>
            <img
              src={linkIcon}
              style={{ width: '14px', height: '14px' }}
              onClick={() => {
                clickedOnlinkIcon();
              }}
            ></img>
          </Tooltip>
        )}
        <Tooltip title={widgetData.widgetDescription}>
          <InfoOutlinedIcon fontSize="small" style={{ color: '#98A6B8' }} />
        </Tooltip>
        <Tooltip title="Close window">
          <CloseFullscreenIcon
            sx={{ fontSize: '16px' }}
            onClick={() => {
              setExpandedViewPopup(false);
            }}
          />
        </Tooltip>
      </Stack>
    </Box>
  );
}
