import { Box } from '@mui/material';
import { useState } from 'react';
import DnDSankeyWidgetConfigDialog from './subComponents/DnDSankeyWidgetConfigPopup';
import GeneralisedWidgetConfigHeader from '../GeneralisedWidgetComponents/subComponents/generalisedWidgetConfigHeader';
import DeleteConfirmation from '../../globalComponents/dialogs/DeleteConfirmation';
import SankeyWidget from '../SankeyWidget/SankeyWidget';

interface DnDSankeyWidgetConfigProps {
  widget: any;
  sensorList: any[];
  refresh: boolean;
  setRefresh: Function;
  deleteCustomWidget: Function;
  widgetClusterIndex: number;
  widgetIndex: number;
  pageData: any;
  setPageData: Function;
  handleWidgetSizeChange: Function;
  changeWidgetTheme: Function;
}

const currentTime = new Date();
const midnight = new Date();
midnight.setHours(0, 0, 0, 0);

export default function DnDSankeyWidgetConfig({
  widget,
  sensorList,
  refresh,
  setRefresh,
  deleteCustomWidget,
  widgetClusterIndex,
  widgetIndex,
  pageData,
  setPageData,
  handleWidgetSizeChange,
  changeWidgetTheme,
}: DnDSankeyWidgetConfigProps) {
  const [openWidgetConfig, setOpenWidgetConfig] = useState(false);
  const [showConfigHeader, setShowConfigHeader] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [refreshStandAloneWidget, setRefreshStandAloneWidget] = useState(false);
  //@ts-ignore
  const [timeFrame, setTimeFrame] = useState({
    startDate: midnight,
    endDate: currentTime,
  });

  const onWidgetSizeChange = (width: number, height: number) => {
    handleWidgetSizeChange(widgetClusterIndex, widgetIndex, width, height);
  };

  const onWidgetThemeChange = (theme: string) => {
    changeWidgetTheme(widgetClusterIndex, widgetIndex, theme);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  return (
    <Box
      sx={{ width: 'inherit', height: 'inherit' }}
      onMouseEnter={(event: any) => {
        event.stopPropagation();
        setShowConfigHeader(true);
      }}
      onMouseLeave={(event: any) => {
        event.stopPropagation();
        setShowConfigHeader(false);
      }}
    >
      {showConfigHeader && (
        <Box
          sx={{
            width: 'calc(100% - 1px)',
          }}
        >
          <GeneralisedWidgetConfigHeader
            onConfigButtonPress={() => {
              setOpenWidgetConfig(true);
              setShowConfigHeader(false);
            }}
            onWidgetSizeChange={onWidgetSizeChange}
            widgetType={widget.widgetType}
            onDeleteButtonPress={() => {
              setShowDeleteModal(true);
            }}
            onWidgetThemeChange={onWidgetThemeChange}
            onCloseHeader={() => {
              setShowConfigHeader(false);
            }}
          />
        </Box>
      )}

      <SankeyWidget
        widgetId={null}
        widgetStructure={widget}
        refresh={refreshStandAloneWidget}
        timeFrame={timeFrame}
        enableToolBar={false}
      />
      <DnDSankeyWidgetConfigDialog
        open={openWidgetConfig}
        handleClose={() => {
          setOpenWidgetConfig(false);
        }}
        widget={widget}
        sensorList={sensorList}
        refresh={refreshStandAloneWidget}
        setRefresh={setRefreshStandAloneWidget}
        pageData={pageData}
        setPageData={setPageData}
      />
      <DeleteConfirmation
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        alertMessage="Are you sure you want to delete this widget? This action is irreversible!"
        processing={processing}
        setProcessing={setProcessing}
        onConfirmAction={() => {
          deleteCustomWidget(widgetClusterIndex, widgetIndex);
        }}
      />
    </Box>
  );
}
