function getGaugeWidgetFontSizeBasedOnTextLength(text: string) {
  const textWithoutDots = text.replace(/\./g, '');
  console.log('tre : ', textWithoutDots);
  if (textWithoutDots.length <= 2) return 70;
  else if (textWithoutDots.length <= 4) return 40;
  else return 30;
}

function getNumber2WidgetFontSizeBasedOnTextLength(text: string) {
  const textWithoutDots = text.replace(/\./g, '');
  if (textWithoutDots.length <= 4) return 70;
  else if (textWithoutDots.length <= 6) return 40;
  else return 30;
}

export { getGaugeWidgetFontSizeBasedOnTextLength, getNumber2WidgetFontSizeBasedOnTextLength };
