import { useState, useEffect, useRef } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useUnifiedDashboardStore } from '../../store/UnifiedDashboardStore';

const ResponsiveGridLayout = WidthProvider(Responsive);

const CustomGridLayout = ({
  layouts,
  isDraggable = false,
  preventCollision = true,
  compactType = 'vertical',
  onLayoutChange = () => {},
  breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  cols = { lg: 24, md: 24, sm: 24, xs: 24, xxs: 24 },
  style,
  ...restProps
}: any) => {
  //@ts-ignore
  const [rowHeight, setRowHeight] = useState(30);
  const containerRef = useRef<HTMLDivElement>(null);

  const { allowDrag } = useUnifiedDashboardStore();

  const calculateRowHeight = (containerWidth: number, columns: number) => {
    const margin = 15; // Margin between items
    const padding = 0; // Container pading

    const columnWidth = (containerWidth - (columns - 1) * margin - 2 * padding) / columns;
    return columnWidth;
  };

  useEffect(() => {}, [allowDrag]);

  const updateRowHeight = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;

      // Determine current number of columns based on breakpoint
      let columns;
      if (containerWidth >= breakpoints.lg) {
        columns = cols.lg;
      } else if (containerWidth >= breakpoints.md) {
        columns = cols.md;
      } else if (containerWidth >= breakpoints.sm) {
        columns = cols.sm;
      } else if (containerWidth >= breakpoints.xs) {
        columns = cols.xs;
      } else {
        columns = cols.xxs;
      }

      setRowHeight(calculateRowHeight(containerWidth, columns));
    }
  };

  useEffect(() => {
    updateRowHeight(); // Update row height initially

    window.addEventListener('resize', updateRowHeight); // Update row height on window resize

    return () => window.removeEventListener('resize', updateRowHeight); // Cleanup listener
  }, []);

  return (
    <div ref={containerRef} style={{ width: '100%' }}>
      <ResponsiveGridLayout
        className="layout"
        breakpoints={breakpoints}
        cols={cols}
        rowHeight={rowHeight} // Use the calculated row height
        // rowHeight={30}
        isDraggable={isDraggable}
        isResizable={true}
        preventCollision={preventCollision === 'prevent'}
        compactType={compactType}
        margin={[15, 15]}
        containerPadding={[0, 0]}
        transformScale={1}
        layouts={layouts}
        style={style}
        onLayoutChange={onLayoutChange}
        {...restProps}
      >
        {restProps.children}
      </ResponsiveGridLayout>
    </div>
  );
};

export default CustomGridLayout;
