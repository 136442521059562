import { Box, BoxProps, Grid, ThemeProvider, GridProps } from '@mui/material';
import { ReactNode } from 'react';
import Theme from '../../theme';

interface TwoRowLayoutProps {
  child1: ReactNode;
  child2: ReactNode;
  container?: BoxProps['className'];
  containerProps?: GridProps; // Props for the Grid container
  child1Props?: GridProps; // Props for the first Grid item
  child2Props?: GridProps; // Props for the second Grid item
}

const TwoRowLayout = (props: TwoRowLayoutProps) => {
  return (
    <ThemeProvider theme={Theme}>
      <Grid
        container
        direction="column"
        rowGap={5}
        sx={{ height: '100vh' }}
        {...props.containerProps}
      >
        <Grid item>
          <Box sx={{ display: 'flex', alignItems: 'center' }} {...props.child1Props}>
            {props.child1}
          </Box>
        </Grid>
        <Grid item sx={{ height: '60vh', width: 'inherit' }} {...props.child2Props}>
          {props.child2}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default TwoRowLayout;
