import React from 'react';
import {
  Box,
  Stack,
  Divider,
  Select,
  MenuItem,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface PageLayoutSettingsProps {
  open: boolean;
  handleClose: () => void;
  pageLayoutSettings: any;
  onChangeLayoutSettings: (property: string, newValue: any) => void;
}

const PageLayoutSettings: React.FC<PageLayoutSettingsProps> = props => {
  const { open, handleClose, pageLayoutSettings, onChangeLayoutSettings } = props;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Page Layout Settings
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Stack direction="column" sx={{ width: '100%' }} gap={2}>
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            px={1}
            border="1px solid black"
            sx={{ marginLeft: 'auto', width: 'inherit' }}
          >
            <Box sx={{ width: '200px' }}>Collision</Box>
            <Divider orientation="vertical" variant="middle" flexItem />
            <FormControl size="small" fullWidth>
              <Select
                value={pageLayoutSettings.preventCollision}
                onChange={event => {
                  onChangeLayoutSettings('preventCollision', event.target.value);
                }}
                sx={{
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 0,
                  },
                  background: '#fefefe',
                  color: '#777',
                }}
                inputProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        backgroundColor: '#fefefe',
                        color: '#777',
                      },
                    },
                  },
                }}
              >
                <MenuItem value="prevent">Prevent</MenuItem>
                <MenuItem value="allow">Allow</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            px={1}
            border="1px solid black"
            sx={{ marginLeft: 'auto', width: 'inherit' }}
          >
            <Box sx={{ width: '200px' }}>Compact Type</Box>
            <Divider orientation="vertical" variant="middle" flexItem />
            <FormControl size="small" fullWidth>
              <Select
                value={pageLayoutSettings.compactType}
                onChange={event => {
                  onChangeLayoutSettings('compactType', event.target.value);
                }}
                disabled
                sx={{
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 0,
                  },
                  background: '#fefefe',
                  color: '#777',
                }}
                inputProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        backgroundColor: '#fefefe',
                        color: '#777',
                      },
                    },
                  },
                }}
              >
                <MenuItem value="horizontal">Horizontal</MenuItem>
                <MenuItem value="vertical">Veritcal</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Stack>
        {/* <Stack
          m={1}
          direction="column"
          style={{
            minWidth: '220px',
            marginLeft: 'auto',
          }}
        >
          <Box>
            <Checkbox
              checked={pageData.public}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                changePageProperty('public', e.target.checked);
              }}
            />
            <span>Is this a public page ?</span>
          </Box>
          <Box>
            <Checkbox
              checked={pageData.showEmbeddedLinks}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                changePageProperty('showEmbeddedLinks', e.target.checked);
              }}
            />
            <span>Show Embedded Links ?</span>
          </Box>
        </Stack> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClose} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PageLayoutSettings;
