export default function TicketIcon({ fillColor = '#3DA385', width = '9', height = '17' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.23077 1.80773V2.26927M4.23077 4.11542V4.57696M4.23077 6.42311V6.88465M4.23077 8.73081V9.19235M9.76923 5.96158H6.53846M9.76923 7.34619H7.92308M12.3077 1.34619C12.6898 1.34619 13 1.65635 13 2.0385V3.90065C12.7189 4.06248 12.4855 4.29553 12.3231 4.57631C12.1608 4.8571 12.0753 5.1757 12.0753 5.50004C12.0753 5.82437 12.1608 6.14298 12.3231 6.42376C12.4855 6.70455 12.7189 6.93759 13 7.09942V8.96158C13 9.34373 12.6898 9.65388 12.3077 9.65388H1.69231C1.31015 9.65388 1 9.34373 1 8.96158V7.09942C1.28107 6.93759 1.51454 6.70455 1.67687 6.42376C1.83921 6.14298 1.92468 5.82437 1.92468 5.50004C1.92468 5.1757 1.83921 4.8571 1.67687 4.57631C1.51454 4.29553 1.28107 4.06248 1 3.90065V2.0385C1 1.65635 1.31015 1.34619 1.69231 1.34619H12.3077Z"
        stroke={fillColor}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
