import { useEffect, useState, useRef } from 'react';
import { Box } from '@mui/material';

const LinearRangeView = ({
  width = 'inherit', // Default to 'inherit' if no width is provided
  height = 3,
  minValue,
  maxValue,
  lowerLimit,
  upperLimit,
  currentValue,
}: any) => {
  const containerRef = useRef<HTMLDivElement>(null);
  //@ts-ignore
  const [containerWidth, setContainerWidth] = useState(300); // Default width if no width is inherited

  const updateWidth = () => {
    if (width === 'inherit' && containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    } else {
      setContainerWidth(width);
    }
  };

  useEffect(() => {
    updateWidth();

    // Add resize event listener
    window.addEventListener('resize', updateWidth);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [width]);

  // Calculate positions for the segments and the pointer as percentages
  const range = upperLimit - lowerLimit;
  const minPosPercent = ((minValue - lowerLimit) / range) * 100;
  const maxPosPercent = ((maxValue - lowerLimit) / range) * 100;
  const pointerPosPercent = ((currentValue - lowerLimit) / range) * 100;
  const pointerRadius = 8;
  const pointerBorder = 3;
  const redColor = '#FF6961';
  const greenColor = '#77DD77';

  // Determine the pointer color
  const pointerColor =
    currentValue >= Math.min(minValue, maxValue) && currentValue <= Math.max(minValue, maxValue)
      ? greenColor
      : redColor;

  return (
    <Box ref={containerRef} sx={{ position: 'relative', width: '100%', height: height }}>
      {/* Background Bar */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: redColor,
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        {/* Green region when minValue is greater than maxValue */}
        {minValue > maxValue ? (
          <>
            {/* Green region after minValue */}
            <Box
              sx={{
                position: 'absolute',
                left: `${minPosPercent}%`,
                width: `${100 - minPosPercent}%`,
                height: '100%',
                backgroundColor: greenColor,
              }}
            />
            {/* Green region before maxValue */}
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                width: `${maxPosPercent}%`,
                height: '100%',
                backgroundColor: greenColor,
              }}
            />
          </>
        ) : (
          // Normal green region between minValue and maxValue
          <Box
            sx={{
              position: 'absolute',
              left: `${minPosPercent}%`,
              width: `${maxPosPercent - minPosPercent}%`,
              height: '100%',
              backgroundColor: greenColor,
            }}
          />
        )}
      </Box>

      {/* Circular Pointer */}
      <Box
        sx={{
          position: 'absolute',
          top: `-${(pointerRadius + pointerBorder) / 2}px`, // Centering the pointer vertically relative to the line
          left: `calc(${pointerPosPercent}% - ${pointerRadius / 2}px)`, // Centering the pointer horizontally relative to the calculated position
          width: `${pointerRadius}px`,
          height: `${pointerRadius}px`,
          backgroundColor: pointerColor,
          borderRadius: '50%',
          border: `${pointerBorder}px solid #000`,
        }}
      />
    </Box>
  );
};

export default LinearRangeView;
