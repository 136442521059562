import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  customFormulaInterface,
  globalFormulaInterface,
  globalVariableInterface,
} from '../Interfaces/formulaConfigInterfaces';
import { useState } from 'react';
import DeleteConfirmation from '../globalComponents/dialogs/DeleteConfirmation';

const styles = {
  formulaContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    border: { xs: '1px solid black', sm: 'none' },
    gap: '20px',
    width: '100%',
  },
  actionButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
};

export default function FormulaViewComponent(props: {
  formula: customFormulaInterface | globalFormulaInterface | globalVariableInterface;
  updateFormulaValue: Function;
  removeFormula: Function;
  index: number;
}) {
  const { formula, updateFormulaValue, removeFormula, index } = props;
  // function getTagValue(lhs: string) {
  //   if (lhs.includes("_")) {
  //     console.log(lhs.split("_")[0]);
  //     return lhs.split("_")[0];
  //   }
  // }
  let tagnames = [
    'LT',
    'HLS',
    'LLS',
    'PH',
    'BOD',
    'COD',
    'TSS',
    'TUR',
    'TDS',
    'ORP',
    'PT',
    'CA',
    'DO',
    'SV',
    'PTI',
    'PTO',
    'BS',
    'ONOFF',
    'PS',
    'PTBWS',
    'CATPT',
    'BWS',
    'TURI',
    'CHLI',
    'TDSI',
    'PHI',
    'MAN',
    'TRP',
    'VBS',
    'TMP',
    'FMF',
    '',
  ];

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);

  return (
    <Box my={2} mx={1} p={1} sx={styles.formulaContainer}>
      <Tooltip title={formula['lhs']}>
        <TextField
          id="outlined-basic"
          label="LHS"
          variant="outlined"
          value={formula['lhs']}
          key={formula.type + index + 'lhs'}
          required
          onChange={e => updateFormulaValue(formula.type, index, 'lhs', e.target.value)}
          InputProps={{
            readOnly: formula.type === 'Global Formula' || formula.type === 'Global Variable',
          }}
          sx={{
            width: { xs: '100%', sm: '100px', md: 'auto' },
            background:
              formula.type === 'Global Formula' || formula.type === 'Global Variable'
                ? 'rgba(1,1,1,0.2)'
                : 'white',
            pointerEvents: 'auto',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        />
      </Tooltip>
      <Tooltip title={formula['rhs']}>
        <TextField
          id="outlined-basic"
          label="RHS"
          variant="outlined"
          value={formula['rhs']}
          key={formula.type + index + 'rhs'}
          InputProps={{
            readOnly: formula.type === 'Global Formula',
          }}
          required
          sx={{
            background: formula.type === 'Global Formula' ? 'rgba(1,1,1,0.2)' : 'white',
            width: { xs: '100%', sm: '600px' },
          }}
          helperText={formula['message']}
          onChange={e => updateFormulaValue(formula.type, index, 'rhs', e.target.value)}
        />
      </Tooltip>
      <Select
        id={'formula tag' + index}
        value={formula['tag']}
        key={formula.type + index + 'tag'}
        onChange={e => updateFormulaValue(formula.type, index, 'tag', e.target.value)}
        sx={{ width: '100px' }}
        disabled={formula.type === 'Global Formula' || formula.type === 'Global Variable'}
        label="Sensor Tag"
      >
        {tagnames.map(tag => {
          return <MenuItem value={tag}>{tag}</MenuItem>;
        })}
      </Select>
      <Box m={1} sx={styles.actionButtonContainer}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formula['isVirtualSensor']}
              key={formula.type + index + 'virtualSensor'}
              onChange={e => {
                updateFormulaValue(
                  formula.type,
                  index,
                  'isVirtualSensor',
                  !formula['isVirtualSensor']
                );
              }}
              value={formula['isVirtualSensor']}
              disabled={formula.type === 'Global Formula' || formula.type === 'Global Variable'}
            />
          }
          label={'Virtual Sensor'}
        />

        {formula.type === 'Global Formula' || formula.type === 'Global Variable' ? null : (
          <Button
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            <DeleteIcon />
          </Button>
        )}
        <DeleteConfirmation
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          alertMessage={'You are deleting the formula'}
          processing={processing}
          setProcessing={setProcessing}
          onConfirmAction={() => {
            removeFormula(formula.type, index);
            setProcessing(false);
          }}
        />
      </Box>
    </Box>
  );
}
