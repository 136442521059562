import { Box, Checkbox, TextField, Tooltip } from '@mui/material';

interface RefreshPageProps {
  expireTime: number;
  setExpireTime: React.Dispatch<React.SetStateAction<number>>;
  checkboxChecked: boolean;
  setCheckboxChecked: React.Dispatch<React.SetStateAction<boolean>>;
  customRefreshInterval: number;
  setCustomRefreshInterval: React.Dispatch<React.SetStateAction<number>>;
  enableCustomRefreshOnly: boolean;
  setEnableCustomRefreshOnly: React.Dispatch<React.SetStateAction<boolean>>;
}

const RefreshPageComponent: React.FC<RefreshPageProps> = ({
  checkboxChecked,
  setCheckboxChecked,
  customRefreshInterval,
  setCustomRefreshInterval,
}: RefreshPageProps) => {
  const handleCustomIntervalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseInt(e.target.value, 10);
    if (!isNaN(inputValue)) {
      setCustomRefreshInterval(inputValue);
    } else {
      setCustomRefreshInterval(0);
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: '5px', width: { xs: '85vw', sm: 'auto' } }}>
      <Checkbox checked={checkboxChecked} onChange={e => setCheckboxChecked(!checkboxChecked)} />
      <Tooltip
        title="Refresh range should be between 60 to 600 seconds"
        arrow
        open={customRefreshInterval < 60 || customRefreshInterval > 600}
      >
        <TextField
          id="outlined-basic"
          label="Auto Refresh Seconds"
          variant="outlined"
          value={customRefreshInterval}
          onChange={handleCustomIntervalChange}
          fullWidth
          disabled={!checkboxChecked}
        />
      </Tooltip>
    </Box>
  );
};

export default RefreshPageComponent;
