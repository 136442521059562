export default function PlantAutomationIcon({ fillColor = '#3DA385', width = '9', height = '17' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.31579 11.6745C6.74421 12.0712 5.82526 11.8589 5.62632 11.0366C5.58595 10.87 5.50681 10.7153 5.39534 10.5851C5.28387 10.4548 5.14323 10.3527 4.98485 10.2871C4.82647 10.2215 4.65484 10.1942 4.48392 10.2075C4.31301 10.2207 4.14763 10.2741 4.00126 10.3634C3.02674 10.9571 1.91074 9.84168 2.50442 8.86653C2.59354 8.72022 2.64687 8.55494 2.66009 8.38414C2.67332 8.21334 2.64605 8.04183 2.58051 7.88355C2.51497 7.72527 2.41301 7.58469 2.28291 7.47323C2.15281 7.36177 1.99825 7.28259 1.83179 7.24211C0.722737 6.97305 0.722737 5.39537 1.83179 5.12632C1.9984 5.08595 2.15312 5.00681 2.28337 4.89534C2.41361 4.78387 2.5157 4.64323 2.58131 4.48485C2.64693 4.32647 2.67422 4.15484 2.66097 3.98392C2.64772 3.81301 2.5943 3.64763 2.50505 3.50126C1.91137 2.52674 3.02674 1.41074 4.00189 2.00442C4.63347 2.38842 5.452 2.04863 5.62632 1.33179C5.89537 0.222737 7.47305 0.222737 7.74211 1.33179C7.78247 1.4984 7.86161 1.65312 7.97308 1.78337C8.08455 1.91361 8.22519 2.0157 8.38357 2.08131C8.54195 2.14693 8.71358 2.17422 8.8845 2.16097C9.05541 2.14772 9.22079 2.0943 9.36716 2.00505C10.3417 1.41137 11.4577 2.52674 10.864 3.50189C10.7749 3.6482 10.7215 3.81348 10.7083 3.98428C10.6951 4.15508 10.7224 4.32659 10.7879 4.48487C10.8535 4.64315 10.9554 4.78373 11.0855 4.89519C11.2156 5.00665 11.3702 5.08583 11.5366 5.12632C12.4789 5.35495 12.6204 6.52779 11.9623 7.04"
        stroke={fillColor}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.78906 6.18429C4.78906 6.6868 4.98869 7.16874 5.34402 7.52407C5.69935 7.8794 6.18128 8.07902 6.6838 8.07902C7.18631 8.07902 7.66825 7.8794 8.02358 7.52407C8.37891 7.16874 8.57854 6.6868 8.57854 6.18429C8.57854 5.68177 8.37891 5.19984 8.02358 4.84451C7.66825 4.48917 7.18631 4.28955 6.6838 4.28955C6.18128 4.28955 5.69935 4.48917 5.34402 4.84451C4.98869 5.19984 4.78906 5.68177 4.78906 6.18429ZM9.84169 12.5001L12.9996 10.6053L9.84169 8.7106V12.5001Z"
        stroke={fillColor}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
