import React from 'react';
import { Box, Stack } from '@mui/material';
import { getGaugeWidgetFontSizeBasedOnTextLength } from '../../../../utils/widget.utils';

const SemiCircularGauge = ({
  width = '100%',
  height = '100%',
  currentValue,
  lowerLimit,
  upperLimit,
  themeColor = '#000',
  unit = '',
}: any) => {
  const containerRef = React.useRef(null);
  const [containerWidth, setContainerWidth] = React.useState(220);
  const [containerHeight, setContainerHeight] = React.useState(100);

  const updateDimensions = React.useCallback(() => {
    if (containerRef.current) {
      //@ts-ignore
      const newWidth = Math.floor(containerRef.current.offsetWidth);
      const newHeight =
        height === 'inherit' || height.includes('%') ? Math.floor(newWidth / 2) : parseInt(height);

      if (newWidth !== containerWidth || newHeight !== containerHeight) {
        setContainerWidth(newWidth);
        setContainerHeight(newHeight);
      }
    }
  }, [containerWidth, containerHeight, height]);

  React.useLayoutEffect(() => {
    updateDimensions(); // Initial dimensions calculation
  }, [updateDimensions]);

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateDimensions();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [updateDimensions]);

  // Calculate the percentage of the currentValue within the lower and upper limits
  const range = upperLimit - lowerLimit;
  const percentage = ((currentValue - lowerLimit) / range) * 100;

  // Calculate the angle for the current value
  const angle = (percentage / 100) * 180;

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'relative',
        width: width,
        height: height,
      }}
    >
      {/* SVG for the semi-circular gauge */}
      <svg width="100%" height="100%" viewBox={`0 0 ${containerWidth} ${containerHeight}`}>
        {/* Background semi-circle (grey) */}
        <circle
          //   cx={containerWidth / 2}
          //   cy={containerHeight}
          //   r={radius}
          cx="50%"
          cy="100%"
          r="50%" // Use relative unit for radius
          fill="transparent"
          stroke="#ddd"
          strokeWidth={containerWidth / 30}
          strokeDasharray={`${Math.PI * 50}%`}
          strokeDashoffset={0}
          transform={`rotate(180, ${containerWidth / 2}, ${containerHeight})`}
        />

        {/* Foreground semi-circle (colored) */}
        <circle
          //   cx={containerWidth / 2}
          //   cy={containerHeight}
          //   r={radius}
          cx="50%"
          cy="100%"
          r="50%" // Use relative unit for radius
          fill="transparent"
          stroke={themeColor}
          strokeWidth={containerWidth / 10}
          strokeDasharray={`${Math.PI * 50}%`}
          transform={`rotate(${angle}, ${containerWidth / 2}, ${containerHeight})`}
          //   strokeLinecap="round"
        />
      </svg>

      {/* Value display */}

      <Stack
        direction="row"
        alignItems="flex-end"
        p={0}
        sx={{
          color: themeColor,
          // height: 'inherit',
          position: 'absolute',
          top: '80%',
          left: '52%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            fontSize: `${(containerWidth / 210) * getGaugeWidgetFontSizeBasedOnTextLength(currentValue.toString() || 'NA')}px`,
            fontWeight: 700,
            lineHeight: `${(containerWidth / 250) * getGaugeWidgetFontSizeBasedOnTextLength(currentValue.toString() || 'NA')}px`,
            padding: 0,
          }}
          alignSelf="flex-end"
        >
          {parseFloat(currentValue) || 'NA'}
        </Box>
        <Box alignSelf="flex-end" sx={{ fontSize: '13px' }}>
          {unit}
        </Box>
      </Stack>
    </Box>
  );
};

export default SemiCircularGauge;
