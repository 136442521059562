import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';

const CreateNewPageDialogBox = ({ open, handleClose, handleCreate }: any) => {
  const [pageType, setPageType] = useState('ALL');

  const handlePageTypeChange = (event: any) => {
    setPageType(event.target.value);
  };

  const onCreate = () => {
    handleCreate(pageType);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ fontSize: '18px' }}>Create a New Page</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="dense">
          <InputLabel>Page Type</InputLabel>
          <Select
            value={pageType}
            onChange={handlePageTypeChange}
            label="Page Type"
            sx={{ minWidth: '200px' }}
          >
            <MenuItem value="ALL">Auto Layout Page</MenuItem>
            <MenuItem value="ALL_GRID">Drag And Drop Page</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onCreate} color="primary" variant="contained">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewPageDialogBox;
