import { Stack, Button, Tooltip, CircularProgress } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import useIsDesktop from '../../utilities/customHooks/useIsDesktop';
import { toast } from 'react-toastify';
import AddWidgetIcon from '../../core-components/assets/icons/AddWidgetIcon';

export default function PageConfigToolBar(props: any) {
  const { onSettingsButtonClick, onSaveIconClick, onAddChartIconClick, onCloseIconClick, loading } =
    props;

  const isDesktop = useIsDesktop(1200);

  return (
    <Stack direction="row" gap={3} px={1}>
      <Tooltip title="Page Layout Settings">
        <Button
          onClick={() => {
            onSettingsButtonClick(true);
          }}
          sx={{ width: 56, height: 56, background: '#fff', border: '1px solid #BDBDBD' }}
        >
          {' '}
          <SettingsIcon fontSize="medium" sx={{ color: '#183650' }} />
        </Button>
      </Tooltip>
      <Tooltip title={isDesktop ? 'Save Page' : 'Save only allowed on desktop view!'}>
        <Button
          disabled={loading}
          onClick={() => {
            if (isDesktop) onSaveIconClick();
            else toast.error('Save only allowed on desktop view');
          }}
          sx={{ width: 56, height: 56, background: '#fff', border: '1px solid #BDBDBD' }}
        >
          {loading ? (
            <CircularProgress size="20px" thickness={5} sx={{ color: '#183650' }} />
          ) : (
            <SaveIcon fontSize="medium" sx={{ color: '#183650' }} />
          )}
        </Button>
      </Tooltip>

      <Tooltip title="Add Widget">
        <Button
          onClick={() => {
            onAddChartIconClick();
          }}
          sx={{ width: 56, height: 56, background: '#fff', border: '1px solid #BDBDBD' }}
        >
          <AddWidgetIcon width={17} height={17} fillColor="#183650" />
        </Button>
      </Tooltip>

      <Button
        onClick={() => {
          onCloseIconClick();
        }}
        sx={{ width: 56, height: 56, background: '#fff', border: '1px solid #BDBDBD' }}
      >
        <Tooltip title="Close Settings">
          <CloseIcon fontSize="medium" sx={{ color: '#183650' }} />
        </Tooltip>
      </Button>

      {/* <Tooltip title="Clone page">
        <IconButton
          onClick={() => {
            clonePage();
          }}
          color="inherit"
        >
          <FileCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete page">
        <IconButton
          onClick={() => {
            setShowPageDeleteModal(true);
          }}
          color="inherit"
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip> */}
    </Stack>
  );
}
