export default function EditDashboardIcon({
  fillColor = '#183650',
  width = '9',
  height = '17',
}: any) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 13H11V3H3V13ZM3 21H11V15H3V21ZM13 3V9H21V3H13Z" fill="#183650" />
      <path
        d="M13 18.3336V20H14.6664L19.5813 15.0851L17.9149 13.4187L13 18.3336ZM20.87 13.7964C21.0433 13.6231 21.0433 13.3431 20.87 13.1698L19.8302 12.13C19.6569 11.9567 19.3769 11.9567 19.2036 12.13L18.3904 12.9432L20.0568 14.6096L20.87 13.7964Z"
        fill={fillColor}
      />
    </svg>
  );
}
