import { Dialog } from '@mui/material';
import SankeyWidget from './SankeyWidget';

export default function ExpandedSankeyWidget(props: any) {
  const { open, handleClose, widgetId, refresh, timeFrame } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '90%',
          width: '90%',
          borderRadius: '8px',
          height: '100%',
        },
      }}
    >
      <SankeyWidget
        widgetId={widgetId}
        widgetStructure={null}
        timeFrame={timeFrame}
        refresh={refresh}
        enableToolBar={false}
      />
    </Dialog>
  );
}
