import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton, TextFieldProps } from '@mui/material';
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material';

interface SearchBoxProps extends Omit<TextFieldProps, 'onChange'> {
  onSearch: (query: string) => void;
  onClear: () => void;
}

const SearchBox: React.FC<SearchBoxProps> = ({ onSearch, onClear, ...props }) => {
  const [input, setInput] = useState('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };
  function handleClear() {
    setInput('');
    onClear();
  }
  const handleSearch = () => {
    if (onSearch) {
      onSearch(input);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <TextField
      value={input}
      variant="outlined"
      onChange={handleChange}
      onKeyDown={handleKeyPress}
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {input.length > 0 && (
              <IconButton onClick={handleClear} edge="end">
                <ClearIcon />
              </IconButton>
            )}
            <IconButton onClick={handleSearch} edge="end">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBox;
