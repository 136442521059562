import { Box, Stack } from '@mui/material';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import Tooltip from '@mui/material/Tooltip';
import whiteLinkIcon from '../../../constants/Images/whiteLinkIcon.svg';
import InfoIcon from '@mui/icons-material/Info';
import Skeleton from '@mui/material/Skeleton';
import FactoryIcon from '@mui/icons-material/Factory';
import { WIDGET_THEMES } from '../../../constants/widgetConfig.constants';
// import {
//   widgetDataInterface,
//   widgetStructureInterface,
// } from "../../../Interfaces/widgetInterfaces";
import { useEffect, useState, useRef, useCallback, useLayoutEffect } from 'react';
import GrowthUpIcon from '../../../core-components/assets/icons/GrowthUpIcon.svg';
import GrowthDownIcon from '../../../core-components/assets/icons/GrowthDownIcon.svg';
import LinearRangeView from '../../../core-components/theme/components/RangeComponents/LinearRangeView';
import SemiCircularGauge from '../../../core-components/theme/components/RangeComponents/SemiCircularGauge';
import { getNumber2WidgetFontSizeBasedOnTextLength } from '../../../utils/widget.utils';
import moment from 'moment';
import WaterDropIcon from '../../../core-components/assets/icons/WaterDropIcon';
import BatteryIcon from '../../../core-components/assets/icons/BatteryIcon';
import PhysicalIcon from '../../../core-components/assets/icons/PhysicalIcon';
import TicketIcon from '../../../core-components/assets/icons/TicketIcon';
import PlantAutomationIcon from '../../../core-components/assets/icons/PlantAutomationIcon';
import ChemicalIcon from '../../../core-components/assets/icons/ChemicalIcon';
import MechanicalIcon from '../../../core-components/assets/icons/MechanicalIcon';
import ClockIcon from '@mui/icons-material/WatchLater';
import ClockOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';

const styles = {
  showIconStyle: {
    visibility: 'visible',
    alignSelf: 'center',
  },
  numberViewWrapper: {
    width: 'inherit',
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '16px',
    gap: '10px',
    fontSize: 'clamp(12px,10px + 0.1vw,25px)',
    fontWeight: 'bold',
    color: 'white',
    boxSizing: 'border-box',
    fontFamily: 'Roboto, Regular',
    background: '#fff',
    overflow: 'hidden',
  },
  numberViewHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // width: '100%',
    fontWeight: 600,
    // background: 'black',
    padding: '0',
    height: '15px',
    fontSize: 'clamp(13px,0.7vw,19px)',
  },
  linkIconStyle: {
    width: '18px',
    height: '18px',
    marginLeft: 'auto',
  },
  numberViewBody: {
    width: '100%',
    height: 'inherit',
    // fontSize : "20px",
    fontSize: 'clamp(18px,10vw,30px)',
    paddingTop: 'auto',
    color: '#000',
    overflow: 'hidden',
    fontWeight: 'normal',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  emptyNumberViewBody: {
    width: '100%',
    height: 'inherit',
    display: 'grid',
    placeItems: 'center',
    // fontSize : "13px",
    fontSize: 'clamp(13px,0.7vw,22px)',
    color: '#eee',
  },
};

// interface numberWidgetpropInterface {
//   widgetData: widgetDataInterface | null;
//   widgetStructure: widgetStructureInterface;
//   isLoading: boolean;
//   showLinkIcon?: boolean;
//   linkOnWidget?: string;
// }

export default function RangeNumberWidget(props: any) {
  const {
    // widgetStructure,
    widgetData,
    isLoading,
    showLinkIcon,
    linkOnWidget,
    // background,
    // setSelectedChart,
    // handleEditClick,
    // showClock,
    // setShowClock,
    // customTimeRangeSettings,
    // handleClockClick,
    // customGranularity,
    theme,
    timeFrame,
    showClock,
    handleClockClick,
    customTimeRangeSettings,
  } = props;

  const numberOfMetricsSelected = widgetData?.metricsData?.datasets?.length ?? 0;

  // const [resetZoom, setResetZoom] = useState(false);
  //@ts-ignore
  const [showIcon, setShowIcon] = useState(false);
  const [background, setBackbground] = useState('red');
  const [fontColor, setFontColor] = useState('red');
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(220);

  useEffect(() => {
    setBackbground(
      WIDGET_THEMES[theme]?.backgroundColor || WIDGET_THEMES['default']?.backgroundColor
    );
    setFontColor(WIDGET_THEMES[theme]?.fontColor || WIDGET_THEMES['default']?.fontColor);
    console.log('theme : ', theme, WIDGET_THEMES[theme]);
  }, [widgetData]);

  const clickedOnlinkIcon = () => {
    window.location.href = linkOnWidget!;
  };
  const updateDimensions = useCallback(() => {
    if (containerRef.current) {
      //@ts-ignore
      const newWidth = Math.floor(containerRef.current.offsetWidth);

      if (newWidth !== containerWidth) {
        setContainerWidth(newWidth);
      }
    }
  }, [containerWidth]);

  useLayoutEffect(() => {
    updateDimensions(); // Initial dimensions calculation
  }, [updateDimensions]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateDimensions();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [updateDimensions]);

  const startDateTime = () => {
    if (customTimeRangeSettings?.startTime && customTimeRangeSettings?.endTime) {
      const startTime = new Date(customTimeRangeSettings.startTime);
      return `${formatDateTime(startTime)}`;
    }
    return moment(timeFrame?.startDate?.toString()).format('MMM DD | hh:mm');
  };

  const endDateTime = () => {
    if (customTimeRangeSettings?.startTime && customTimeRangeSettings?.endTime) {
      const endTime = new Date(customTimeRangeSettings.endTime);
      return `${formatDateTime(endTime)}`;
    }
    return moment(timeFrame?.endDate?.toString()).format('MMM DD | hh:mm');
  };

  const formatDateTime = (date: Date) => {
    const dateString = date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
    });
    const timeString = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${dateString} | ${timeString}`;
  };

  const getWidgetIconBasedOnWidgetCategoryTheme = (widgetCategoryTheme: string | null) => {
    if (widgetCategoryTheme == 'waterQualityMetrics')
      return <WaterDropIcon fillColor={background} width="10" />;
    else if (widgetCategoryTheme == 'energyMetrics')
      return <BatteryIcon fillColor={background} width="8" />;
    else if (widgetCategoryTheme == 'mechanicalMetrics')
      return <MechanicalIcon fillColor={background} width="13" />;
    else if (widgetCategoryTheme == 'ticketMetrics')
      return <TicketIcon fillColor={background} width="15" />;
    else if (widgetCategoryTheme == 'chemicalMetrics')
      return <ChemicalIcon fillColor={background} width="13" />;
    else if (widgetCategoryTheme == 'physicalMetrics')
      return <PhysicalIcon fillColor={background} width="13" />;
    else if (widgetCategoryTheme == 'plantAutomationMetrics')
      return <PlantAutomationIcon fillColor={background} width="13" />;
    else return null;
  };

  if (isLoading)
    return (
      <Box
        sx={{
          ...styles.numberViewWrapper,
        }}
        p={1}
      >
        <Box
          className="numberHeading"
          sx={{
            ...styles.numberViewHeading,
            fontWeight: 'normal',
            fontColor: fontColor,
          }}
        >
          <div>{widgetData.widgetNickName ? widgetData.widgetNickName : widgetData.widgetName}</div>
          {showLinkIcon && (
            <Tooltip title="Click to know more">
              <img
                src={whiteLinkIcon}
                style={{ width: '18px', height: '18px', marginLeft: 'auto' }}
                onClick={() => {
                  clickedOnlinkIcon();
                }}
              ></img>
            </Tooltip>
          )}
          <Tooltip title={widgetData.widgetDescription} style={{ color: '#eee' }}>
            <InfoIcon fontSize="small" />
          </Tooltip>
        </Box>
        <Box style={styles.emptyNumberViewBody}>
          <Skeleton
            variant="rectangular"
            style={{
              width: 'inherit',
              height: 'inherit',
            }}
          />
        </Box>
      </Box>
    );
  else if (widgetData)
    return (
      <Box
        sx={{
          ...styles.numberViewWrapper,
          border: `1px solid ${background}`,
        }}
        onMouseEnter={() => setShowIcon(true)}
        onMouseLeave={() => setShowIcon(false)}
      >
        <Box
          sx={{
            padding: '12px',
            paddingBottom: '0px',
            maxHeight: '75%',
            height: '75%',
            boxSizing: 'border-box',
            overflowY: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
            {getWidgetIconBasedOnWidgetCategoryTheme(widgetData?.commonInfo?.widgetCategoryTheme)}
            <Box
              className="numberHeading"
              style={{ ...styles.numberViewHeading, color: fontColor }}
            >
              <div>
                {widgetData.widgetNickName ? widgetData.widgetNickName : widgetData.widgetName}
              </div>
            </Box>
          </Stack>

          <Box
            sx={{
              display: 'grid',
              placeItems: 'center',
              fontSize: '14px',
              maxHeight: '90%',
              height: 'calc(90% - 30px)',
              color: fontColor,
              width: 'inherit',
            }}
          >
            {numberOfMetricsSelected > 0 &&
              widgetData?.widgetType == 'number2' &&
              widgetData?.validRangeSettings?.showRange && (
                <Box
                  ref={containerRef}
                  sx={{
                    marginTop: 'auto',
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                  }}
                >
                  <Stack
                    direction="column"
                    alignItems="center"
                    gap={3}
                    sx={{ height: '100%', width: '100%' }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ color: fontColor, marginTop: 'auto' }}
                    >
                      <Box
                        sx={{
                          fontSize: `${(containerWidth / 250) * getNumber2WidgetFontSizeBasedOnTextLength(parseFloat(widgetData?.metricsData?.datasets?.[0]?.data[0]).toString() || 'NA')}px`,
                          fontWeight: 700,
                          lineHeight: `${(containerWidth / 350) * getNumber2WidgetFontSizeBasedOnTextLength(parseFloat(widgetData?.metricsData?.datasets?.[0]?.data[0]).toString() || 'NA')}px`,
                        }}
                      >
                        {parseFloat(widgetData?.metricsData?.datasets?.[0]?.data[0]) || 'NA'}
                      </Box>
                      <Box
                        alignSelf="flex-end"
                        sx={{
                          fontSize: '13px',
                        }}
                      >
                        {widgetData?.commonInfo?.widgetUnit &&
                        widgetData?.commonInfo?.widgetUnit != 'none'
                          ? widgetData?.commonInfo?.widgetUnit
                          : ''}
                      </Box>
                    </Stack>

                    <LinearRangeView
                      minValue={widgetData?.validRangeSettings?.min}
                      maxValue={widgetData?.validRangeSettings?.max}
                      lowerLimit={widgetData?.validRangeSettings?.lowerLimit}
                      upperLimit={widgetData?.validRangeSettings?.upperLimit}
                      currentValue={widgetData?.metricsData?.datasets?.[0]?.data[0]}
                    />
                  </Stack>
                </Box>
              )}

            {numberOfMetricsSelected > 0 &&
              widgetData?.widgetType == 'number2' &&
              !widgetData?.validRangeSettings?.showRange && (
                <Box
                  ref={containerRef}
                  sx={{
                    marginTop: 'auto',
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                  }}
                >
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    gap={3}
                    sx={{ height: '100%', width: '100%' }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{
                        color: fontColor,
                        marginTop: 'auto',
                        marginBottom: '10px',
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: `${(containerWidth / 230) * getNumber2WidgetFontSizeBasedOnTextLength(parseFloat(widgetData?.metricsData?.datasets?.[0]?.data[0]).toString() || 'NA')}px`,
                          fontWeight: 700,
                          lineHeight: `${(containerWidth / 330) * getNumber2WidgetFontSizeBasedOnTextLength(parseFloat(widgetData?.metricsData?.datasets?.[0]?.data[0]).toString() || 'NA')}px`,
                        }}
                      >
                        {parseFloat(widgetData?.metricsData?.datasets?.[0]?.data[0]) || 'NA'}
                      </Box>
                      <Box
                        alignSelf="flex-end"
                        sx={{
                          fontSize: '13px',
                        }}
                      >
                        {widgetData?.commonInfo?.widgetUnit != 'none'
                          ? widgetData?.commonInfo?.widgetUnit
                          : ''}
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              )}

            {numberOfMetricsSelected > 0 && widgetData?.widgetType == 'NumberGauge' && (
              <Box
                ref={containerRef}
                sx={{
                  marginTop: 'auto',
                  width: '100%',
                  height: '100%',
                  boxSizing: 'border-box',
                }}
              >
                <Box
                  sx={{
                    marginTop: 'auto',
                    height: '90%',
                  }}
                >
                  <SemiCircularGauge
                    lowerLimit={widgetData?.validRangeSettings?.lowerLimit ?? 0}
                    upperLimit={widgetData?.validRangeSettings?.upperLimit ?? 100}
                    currentValue={widgetData?.metricsData?.datasets?.[0]?.data[0] || 0}
                    themeColor={fontColor}
                    unit={
                      widgetData?.commonInfo?.widgetUnit != 'none'
                        ? widgetData?.commonInfo?.widgetUnit
                        : ''
                    }
                  />
                </Box>
              </Box>
            )}

            {numberOfMetricsSelected == 0 && 'No Metric Selected'}
          </Box>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={{
              fontSize: '10px',
              color: fontColor,
              height: '15px',
              lineHeight: '10px',
            }}
          >
            <FactoryIcon sx={{ fontSize: '10px' }} /> <span>{widgetData.plantName}</span>
          </Stack>
        </Box>
        <Stack
          direction="row"
          sx={{
            width: '100%',
            maxHeight: '25%',
            height: '25%',
            marginTop: 'auto',
            borderTop: `1px solid ${background}`,
            color: fontColor,
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            direction="row"
            gap={2}
            sx={{
              width: widgetData.widgetLook?.showPercentageChange ? '65%' : '100%',
              background: background,
              color: '#fff',
              fontSize: ` ${(containerWidth / 100) * 7}px`,
              fontWeight: 400,
            }}
          >
            <Box>
              {showClock == 'on' && (
                <Stack direction="row" spacing={1} mb={1}>
                  <ClockIcon
                    sx={{
                      width: '16px',
                      height: '16px',
                      cursor: 'pointer',
                      color: '#fff	',
                    }}
                    onClick={() => handleClockClick('disabled')}
                  />
                </Stack>
              )}
              {showClock === 'disabled' && (
                <Stack direction="row" spacing={1} mb={1}>
                  <ClockOutlinedIcon
                    sx={{
                      width: '16px',
                      height: '16px',
                      cursor: 'pointer',
                      color: '#fff',
                    }}
                    onClick={() => handleClockClick('on')}
                  />
                </Stack>
              )}
            </Box>
            <Stack direction="column">
              {/* <Box>{moment(timeFrame?.startDate?.toString()).format('MMM DD YYYY')}</Box> */}
              {/* <Box>{moment(timeFrame?.endDate?.toString()).format('MMM DD YYYY')}</Box> */}
              <Box>{startDateTime()}</Box>
              <Box>{endDateTime()}</Box>
            </Stack>
          </Stack>
          {widgetData.widgetLook?.showPercentageChange && (
            <Box sx={{ width: '35%', display: 'grid', placeItems: 'center' }}>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ gap: containerWidth > 150 ? '5px' : '2px' }}
              >
                {widgetData?.metricsData?.datasets?.[0]?.percentageChange?.[0] && (
                  <Box sx={{ display: 'grid' }}>
                    {widgetData?.metricsData?.datasets?.[0]?.percentageChange?.[0] > 0 ? (
                      <img
                        src={GrowthUpIcon}
                        style={{
                          width: `${(containerWidth / 250) * 30}px`,
                        }}
                      />
                    ) : (
                      <img src={GrowthDownIcon} />
                    )}
                  </Box>
                )}
                <Box
                  sx={{
                    fontSize: `${(containerWidth / 250) * 30}px`,
                    fontWeight: 500,
                    color: fontColor,
                  }}
                >
                  {Math.abs(widgetData?.metricsData?.datasets?.[0]?.percentageChange?.[0]) || '-'}
                </Box>
                <Stack direction="column">
                  <Box
                    sx={{ fontSize: '14px', lineHeight: '14px', fontWeight: 400, color: '#7D8188' }}
                  >
                    %
                  </Box>
                </Stack>
              </Stack>
            </Box>
          )}
        </Stack>
      </Box>
    );
  else return null;
}
