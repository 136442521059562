import { useEffect, useState } from 'react';
import { getWidgetByTypeAndId } from '../../services/dashboard/widget';
import { Box } from '@mui/material';
import { isValidColorString } from '../../utils/color.utils';

export default function GeneralisedHeadingWidget(props: any) {
  const { widgetStructure } = props;
  const [widgetData, setWidgetData] = useState<any>(null);

  useEffect(() => {
    getWidgetByTypeAndId('heading', widgetStructure?.widgetId).then(data => {
      setWidgetData(data);
    });
  }, []);

  return (
    <Box
      sx={{
        width: 'inherit',
        height: 'inherit',
      }}
    >
      <span
        style={{
          width: 'inherit',
          height: 'inherit',
          fontSize:
            widgetData && !isNaN(parseFloat(widgetData?.font?.size))
              ? parseFloat(widgetData?.font?.size)
              : '20px',
          color: isValidColorString(widgetData?.font?.color || '')
            ? widgetData?.font?.color
            : '#000',
          fontWeight: !isNaN(widgetData?.font?.fontWeight) ? widgetData?.font?.fontWeight : '500',
          wordBreak: 'break-all',
        }}
      >
        {widgetData?.heading?.english ? widgetData.heading?.english : 'No Heading'}
      </span>
    </Box>
  );
}
