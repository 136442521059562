import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "./useAuth";

interface IProps {
  allowedRoles: string[];
}
const RequireAuth = ({ allowedRoles }: IProps) => {
  const location = useLocation();
  const { role } = useAuth();
  const content = allowedRoles.includes(role) ? (
    <Outlet />
  ) : (
    <Navigate to="/Login" state={{ from: location }} replace />
  );
  return content;
};

export default RequireAuth;
