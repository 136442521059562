import { Stack, Checkbox, TextField, Typography, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import {
  IInflatedDashboadPage,
  IValidRangeSettings,
} from '../../Interfaces/newDashboardInterfaces/inflatedDashbaordPage.interfaces';
interface IProps {
  widgetIndex: number;
  widgetClusterIndex: number;
  pageData: IInflatedDashboadPage['pageConfig'];
  setPageData: Function;
  areMultipleSensorSelected: boolean;
  setRefreshStandAloneWidget: Function;
}
function ValidRangeSettings(props: IProps) {
  const {
    widgetIndex,
    widgetClusterIndex,
    pageData,
    setPageData,
    areMultipleSensorSelected,
    setRefreshStandAloneWidget,
  } = props;
  const [validRangeSettings, setValidRangeSettings] = useState<IValidRangeSettings>({
    applyMax: false,
    applyMin: false,
    max: null,
    min: null,
    lowerLimit: 0,
    upperLimit: 100,
    showRange: false,
  });

  const [errorMessage, setErrorMessage] = useState('');
  const widgetType = pageData.data[widgetClusterIndex]['widgets'][widgetIndex]?.widgetType;

  useEffect(() => {
    if (
      pageData.data[widgetClusterIndex]['widgets'][widgetIndex].hasOwnProperty('validRangeSettings')
    ) {
      let validRangeSettingsObject = //@ts-ignore
        pageData.data[widgetClusterIndex]['widgets'][widgetIndex]['validRangeSettings'];

      setValidRangeSettings(validRangeSettingsObject);
    }
  }, []);

  useEffect(() => {
    if (areMultipleSensorSelected) {
      const tempValidRange = {
        ...validRangeSettings,
        applyMin: false,
        applyMax: false,
      };
      setValidRangeSettings(tempValidRange);
      changeValidRangeSettings(tempValidRange);
    }
  }, [areMultipleSensorSelected]);

  const changeValidRangeSettings = (tempValidRange: IValidRangeSettings) => {
    setPageData((prevPageData: IInflatedDashboadPage['pageConfig']) => {
      const updatedPageData = { ...prevPageData };
      //@ts-ignore
      updatedPageData.data[widgetClusterIndex].widgets[widgetIndex].validRangeSettings =
        tempValidRange;
      return updatedPageData;
    });
    setRefreshStandAloneWidget((prevState: any) => !prevState);
  };

  const handleCheckBoxChange = (key: string, e: any) => {
    const tempValidRange = {
      ...validRangeSettings,
      [key]: e.target.checked,
    };
    setValidRangeSettings(tempValidRange);
    changeValidRangeSettings(tempValidRange);
  };

  const handleShowRangeCheckBox = (checked: boolean) => {
    let allow = false;
    let errormsg = '';

    if (widgetType == 'NumberGauge') {
      if (
        validRangeSettings?.lowerLimit != null &&
        validRangeSettings?.upperLimit != null &&
        !isNaN(validRangeSettings?.lowerLimit) &&
        !isNaN(validRangeSettings?.upperLimit)
      ) {
        allow = true;
      } else {
        errormsg = 'lowerLimit and upperLimit should be defined';
      }
    } else if (widgetType == 'number2') {
      if (
        validRangeSettings?.max != null &&
        validRangeSettings?.min != null &&
        validRangeSettings?.lowerLimit != null &&
        validRangeSettings?.upperLimit != null &&
        !isNaN(validRangeSettings?.lowerLimit) &&
        !isNaN(validRangeSettings?.upperLimit) &&
        !isNaN(validRangeSettings?.max) &&
        !isNaN(validRangeSettings?.min)
      ) {
        allow = true;
      } else {
        errormsg = 'min, max ,lowerLimit, upperLimit should not be empty';
      }
    } else if (widgetType == 'graph') {
      if (
        validRangeSettings?.max != null &&
        validRangeSettings?.min != null &&
        !isNaN(validRangeSettings?.max) &&
        !isNaN(validRangeSettings?.min)
      ) {
        allow = true;
      } else {
        errormsg = 'min, max  should be defined';
      }
    }

    if (allow) {
      const tempValidRange = {
        ...validRangeSettings,
        showRange: checked,
      };
      setValidRangeSettings(tempValidRange);
      changeValidRangeSettings(tempValidRange);
    } else {
      const tempValidRange = {
        ...validRangeSettings,
        showRange: false,
      };
      setValidRangeSettings(tempValidRange);
      changeValidRangeSettings(tempValidRange);
    }
    setErrorMessage(errormsg);
  };

  const handleInputChange = (key: string, e: any) => {
    console.log('key : ', key, e.target.value);
    try {
      const tempValidRange = {
        ...validRangeSettings,
        [key]: e.target.value === '' ? null : parseFloat(e.target.value),
      };
      setValidRangeSettings(tempValidRange);
      changeValidRangeSettings(tempValidRange);
    } catch (err) {
      // Set Error State
    }
  };
  return (
    <>
      {areMultipleSensorSelected === false ? (
        <></>
      ) : (
        <Typography color="error">Please select only one sensor</Typography>
      )}
      <Stack direction="column" gap={2}>
        {['number2', 'NumberGauge'].includes(widgetType) && (
          <Stack direction="row" gap={2}>
            <TextField
              type="number"
              label={<Typography>Lower Bound</Typography>}
              value={validRangeSettings.lowerLimit ?? ''}
              disabled={areMultipleSensorSelected}
              onChange={e => {
                handleInputChange('lowerLimit', e);
              }}
            />
            <TextField
              type="number"
              label={<Typography>Upper Bound</Typography>}
              value={validRangeSettings.upperLimit ?? ''}
              disabled={areMultipleSensorSelected}
              onChange={e => handleInputChange('upperLimit', e)}
            />
          </Stack>
        )}
        {widgetType != 'NumberGauge' && (
          <Stack direction="row" gap={2} alignItems="center">
            {!['NumberGauge', 'number2'].includes(widgetType) && (
              <Checkbox
                value={pageData}
                checked={validRangeSettings?.applyMin}
                onChange={(e: any) => handleCheckBoxChange('applyMin', e)}
                sx={{
                  color: '#3DA3F5',
                  '&.Mui-checked': {
                    color: '#3DA3F5',
                  },
                }}
                disabled={areMultipleSensorSelected}
              />
            )}
            <TextField
              type="number"
              label={<Typography>Min</Typography>}
              value={validRangeSettings.min ?? ''}
              disabled={areMultipleSensorSelected}
              onChange={e => {
                handleInputChange('min', e);
              }}
            />
            {!['NumberGauge', 'number2'].includes(widgetType) && (
              <Checkbox
                checked={validRangeSettings.applyMax}
                onChange={(e: any) => {
                  handleCheckBoxChange('applyMax', e);
                }}
                disabled={areMultipleSensorSelected}
                sx={{
                  color: '#3DA3F5',
                  '&.Mui-checked': {
                    color: '#3DA3F5',
                  },
                }}
              />
            )}
            <TextField
              type="number"
              label={<Typography>Max</Typography>}
              value={validRangeSettings.max ?? ''}
              disabled={areMultipleSensorSelected}
              onChange={e => handleInputChange('max', e)}
            />
          </Stack>
        )}
        {errorMessage?.length > 0 ? <Typography color="error">{errorMessage}</Typography> : null}
        {['graph', 'number2'].includes(widgetType) && (
          <Stack direction="row" alignItems="center">
            <Box>Show Range</Box>
            <Checkbox
              checked={validRangeSettings?.showRange}
              onChange={(e: any) => handleShowRangeCheckBox(e.target.checked)}
              sx={{
                color: '#3DA3F5',
                '&.Mui-checked': {
                  color: '#3DA3F5',
                },
              }}
              disabled={areMultipleSensorSelected}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
}

export default ValidRangeSettings;
