import { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Stack,
  IconButton,
  Tooltip,
  TextField,
  Tabs,
  Tab,
} from '@mui/material';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import SensorSettingsForSankey from './sensorSettingsForSankey';
import WidgetLevelSettingsForSankey from './widgetLevelSettingsForSankey';
import SensorNameDisplayComponent from '../../../core-components/theme/components/DisplayNames/SensorNameDisplayComponent';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';

export default function SankeyWidgetConfigView(props: any) {
  const { widget, sensorList, refresh, setRefresh } = props;

  const [selectedFlowIndex, setSelectedFlowIndex] = useState(0);
  const [selectedSettings, setSelectedSettings] = useState('flowSettings');

  const handleAddFlow = () => {
    let flowIndex = widget.flows.length;
    let fromName = `node${flowIndex + 1}`;
    let toName = `node${flowIndex + 2}`;
    widget.flows.push({
      from: fromName,
      to: toName,
      weightConfig: {
        weightType: 'sensor',
        defaultWeight: 1,
      },
      sensorConfig: {
        sensor: {},
        valueTypeCust: {},
      },
    });
    toast('New flow added!');
    setRefresh(!refresh);
  };

  const deleteFlow = (flowIndex: any) => {
    widget.flows?.splice(flowIndex, 1);
    setRefresh(!refresh);
  };

  const handleFlowPropertyChange = (flowIndex: number, property: string, value: any) => {
    if (widget.flows?.[flowIndex]?.[property]) {
      widget.flows[flowIndex][property] = value;
      setRefresh(!refresh);
    }
  };

  const handleFlowDefaultWeightChange = (flowIndex: number, value: any) => {
    console.log('handle flow weight change');
    if (widget.flows?.[flowIndex]?.weightConfig?.defaultWeight != null) {
      console.log('handle flow weight change2');
      widget.flows[flowIndex].weightConfig.defaultWeight = parseFloat(value);
      setRefresh(!refresh);
    }
  };

  return (
    <Box>
      <Typography variant="h2">Sankey Widget Config</Typography>
      <Grid container mt={5}>
        <Grid item xs={12} sm={12} md={7} lg={7} p={2}>
          <Box>
            <Stack direction="row" alignItems="center" justifyContent="space-between" px={1}>
              <Box>{`Flows (${widget.flows.length})`}</Box>
              <IconButton onClick={handleAddFlow}>
                <Tooltip title="Add Flow">
                  <PlaylistAddIcon />
                </Tooltip>
              </IconButton>
            </Stack>
            <Stack
              direction="column"
              border="1px solid #ddd"
              sx={{
                minHeight: '350px',
                maxHeight: '350px',
                overflowY: 'scroll',
                background: '#fff',
              }}
              borderRadius={3}
            >
              {widget.flows.map((flow: any, flowIndex: number) => {
                return (
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={4}
                    onClick={() => {
                      setSelectedFlowIndex(flowIndex);
                      setSelectedSettings('flowSettings');
                    }}
                    p={4}
                    sx={{ background: flowIndex == selectedFlowIndex ? 'lightblue' : '#fefefe' }}
                    // borderBottom={flowIndex != widget.plants?.length - 1 ? 'solid 1px #ddd' : 0}
                  >
                    <TextField
                      id="outlined-from"
                      label="From"
                      variant="outlined"
                      type="text"
                      fullWidth
                      defaultValue={flow.from || ''}
                      sx={{
                        background: '#fff',
                        borderRadius: '10px',
                        '& fieldset': { borderRadius: '10px' },
                        minWidth: '100px',
                      }}
                      onBlur={event => {
                        handleFlowPropertyChange(flowIndex, 'from', event.target.value);
                      }}
                    />
                    <TextField
                      id="outlined-from"
                      label="To"
                      variant="outlined"
                      type="text"
                      fullWidth
                      defaultValue={flow.to || ''}
                      sx={{
                        background: '#fff',
                        borderRadius: '10px',
                        '& fieldset': { borderRadius: '10px' },
                        minWidth: '100px',
                      }}
                      onBlur={event => {
                        handleFlowPropertyChange(flowIndex, 'to', event.target.value);
                      }}
                    />
                    {flow.weightConfig.weightType == 'number' && (
                      <TextField
                        id="outlined-from"
                        label="Weight"
                        variant="outlined"
                        type="number"
                        fullWidth
                        value={flow.weightConfig?.defaultWeight}
                        sx={{
                          background: '#fff',
                          borderRadius: '10px',
                          '& fieldset': { borderRadius: '10px' },
                          minWidth: '100px',
                        }}
                        onChange={event => {
                          handleFlowDefaultWeightChange(flowIndex, event.target.value);
                        }}
                      />
                    )}
                    {flow.weightConfig.weightType == 'sensor' && (
                      <Box
                        // p={5}
                        sx={{
                          background: '#fff',
                          minWidth: '200px',
                          fontSize: '13px',
                          borderRadius: '10px',
                          border: 'solid 1px #bbb',
                          padding: '20px',
                        }}
                      >
                        {flow.sensorConfig?.sensor?._id ? (
                          <SensorNameDisplayComponent sensor={flow.sensorConfig.sensor} />
                        ) : (
                          'No Sensor'
                        )}
                      </Box>
                    )}
                    <IconButton
                      onClick={() => {
                        deleteFlow(flowIndex);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Stack>
                );
              })}
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} p={1}>
          <Tabs
            value={selectedSettings}
            onChange={(event, newValue) => {
              setSelectedSettings(newValue);
            }}
            aria-label="full width tabs example"
            sx={{
              alignSelf: 'flex-start',
              marginBottom: '20px',

              '& .MuiTabs-flexContainer': {
                borderBottom: '2px solid #aaa', // Gray line below all tabs
                position: 'relative',
                // maxWidth: '270px',
                zIndex: 1, // Make sure this is above the TabIndicator
              },
            }}
            TabIndicatorProps={{
              style: {
                background: '#333', // Color of the selected tab indicator
                height: '2px',
                // position: 'relative',
                zIndex: 2, // Make sure the indicator is above the gray line
              },
            }}
            variant="fullWidth"
          >
            <Tab
              label="Widget Settings"
              value="widgetLevelSettings"
              wrapped
              sx={{
                textTransform: 'none',
                fontSize: '14px',
                color: '#aaa',
                fontWeight: 'bold',
                '&.Mui-selected': {
                  color: '#333', // Color for selected tab
                },
              }}
            />
            <Tab
              label="Flow Settings"
              value="flowSettings"
              wrapped
              sx={{
                textTransform: 'none',
                fontSize: '14px',
                color: '#aaa',
                fontWeight: 'bold',
                '&.Mui-selected': {
                  color: '#333', // Color for selected tab
                },
              }}
              // {...a11yProps(0)}
            />
          </Tabs>
          <Box
            border="1px solid #ddd"
            sx={{ background: '#fff' }}
            borderRadius={2}
            minHeight={300}
            p={3}
          >
            {selectedSettings == 'flowSettings' && (
              <SensorSettingsForSankey
                flowConfig={widget.flows?.[selectedFlowIndex]}
                sensorList={sensorList}
                setRefresh={setRefresh}
              />
            )}

            {selectedSettings == 'widgetLevelSettings' && (
              <WidgetLevelSettingsForSankey widget={widget} setRefresh={setRefresh} />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
