//@ts-nocheck
import React from 'react';
import { useState, useRef } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Chip,
  IconButton,
  Tooltip,
  setRef,
} from '@mui/material';
import { useEffect } from 'react';
import axiosInstance from '../../constants/axiosInstance';

import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { toast } from 'react-toastify';
import TableRowsIcon from '@mui/icons-material/TableRows';
import PinIcon from '@mui/icons-material/Pin';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import MetricConfigComponent from '../metricConfigComponent/metricConfigComponent';
import MetricConfigComponentV2 from './metricConfigComponentv2';
import { pageConfigDataInterface } from '../../Interfaces/dashboardPageInterfaces';
import { widgetInterface } from '../../Interfaces/widgetInterfaces';
import {
  lastMovedWidgetInterface,
  metricDiagnosisDataInterface,
} from '../../Interfaces/pageConfigPageInterfaces';
import GranularitySettings from './granularitySetting';
import TimeRangeSettings from './timeRangeSetting';
import MetricSearchBar from './metricSearchBar';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import DeleteConfirmation from '../../globalComponents/dialogs/DeleteConfirmation';
import { IInflatedDashboadPage } from '../../Interfaces/newDashboardInterfaces/inflatedDashbaordPage.interfaces';
import SellIcon from '@mui/icons-material/Sell';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import StandaloneGeneralisedWidget from '../GeneralisedWidgetComponents/StandaloneGeneralisedWidget';
import { useDashboardStore } from '../../store/DashboardStore';
import { usePlantLayoutStore } from '../../store/PlantLayoutStore';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import lodash from 'lodash';
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import { ArrowCircleUpRounded } from '@mui/icons-material';
import { ReactComponent as ArrowUpIcon } from '../../constants/Images/ArrowUpIcon.svg';
import { ReactComponent as ArrowDownIcon } from '../../constants/Images/ArrowDownIcon.svg';
import FactoryIcon from '@mui/icons-material/Factory';
import { add530Hours } from '../../utils/dashboard.utils';
import { start } from 'repl';
import ValidRangeSettings from '../widgetConfigComponentsV2/ValidRangeSettings';
import GeneralMetricSearchBar from '../workspace/GeneralMetricSearchBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MapMetricConfig from './subComponents/MapMetricConfig';
import MapPlantConfig from './subComponents/MapPlantConfig';
import { MAX_ALLOWED_SENSOR_VALUE, MIN_ALLOWED_SENSOR_VALUE } from '../../constants/constants';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import MapWidgetLevelConfig from './subComponents/MapWidgetLevelConfig';
import { resolvePackageData } from 'vite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapFiltersConfig from './subComponents/MapFiltersConfig';
import { RANGE_OBJ } from './constants/range.constant';
import { truncateText } from '../../utilities/string.utils';
import DnDMapWidgetConfigPopup from './subComponents/DnDMapWidgetConfigPopup';
import StandAloneMapWidget from '../MapWidget/StandAloneMapWidget';
import GeneralisedWidgetConfigHeader from '../GeneralisedWidgetComponents/subComponents/generalisedWidgetConfigHeader';
import MapWidget from '../MapWidget/MapWidget';

const styles = {
  widgetConfigComponentAccordion: {
    margin: '10px',
    maxHeight: '544px',
    overflowY: 'scroll',
    background: '#F0F0F0',
    borderRadius: '10px',
    border: '1px solid #BDBDBD',
  },
  widgetConfigSummaryContainer: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
  },
  widgetConfigSummaryActionButtonContainer: {
    display: 'flex',
    marginLeft: 'auto',
    color: '#444',
    alignItems: 'center',
  },
  textBoxStyle: {
    background: 'white',
    '& fieldset': { border: 'none' },
  },

  metricDiagnosisLoading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#eee',
    width: '400px',
    minHeight: '100px',
    borderRadius: '5px',
  },
  metricDiagnosisNotOk: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFCCCB',
    width: '400px',
    minHeight: '100px',
    borderRadius: '5px',
  },
  metricDiagnosisOk: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#addfad',
    width: '400px',
    minHeight: '100px',
    borderRadius: '5px',
  },
};

interface propsInterface {
  pageData: IInflatedDashboadPage['pageConfig'];
  setPageData: React.Dispatch<React.SetStateAction<IInflatedDashboadPage['pageConfig'] | null>>;
  widgetCluster: string;
  widgetClusterIndex: number;
  widget: widgetInterface;
  widgetIndex: number;
  moveWidgetPosition: (widgetClusterIndec: number, widgetIndex: number, action: string) => void;
  lastMovedWidget: lastMovedWidgetInterface;
  sensorList: any[];
  setDeletedWidgets: Function;
  deleteCustomWidget: Function;
}

const currentTime = new Date();
const midnight = new Date();
midnight.setHours(0, 0, 0, 0);
// const startDate = new Date(endDate.valueOf() - 24 * 60 * 60 * 1000);
// const { startDate, endDate } = add530Hours(midnight, currentTime);
// console.log('startDate : ', startDate);

export default function DnDMapWidgetConfig(props: propsInterface) {
  const {
    pageData,
    setPageData,
    widgetCluster,
    widgetClusterIndex,
    widget,
    widgetIndex,
    moveWidgetPosition,
    lastMovedWidget,
    sensorList,
    setDeletedWidgets,
    handleWidgetSizeChange,
    deleteCustomWidget,
  } = props;

  // const [refreshStandAloneWidget, setRefreshStandAloneWidget] = useState(false);
  const [openWidgetConfig, setOpenWidgetConfig] = useState(false);
  const [showConfigHeader, setShowConfigHeader] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const onWidgetSizeChange = (width: number, height: number) => {
    handleWidgetSizeChange(widgetClusterIndex, widgetIndex, width, height);
  };

  return (
    <>
      <Box sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
        <Box
          sx={{ width: '100%', height: '99%' }}
          onMouseEnter={() => {
            setShowConfigHeader(true);
          }}
          onMouseLeave={() => {
            setShowConfigHeader(false);
          }}
        >
          {showConfigHeader && (
            <GeneralisedWidgetConfigHeader
              onConfigButtonPress={() => {
                console.log('button press');
                setOpenWidgetConfig(true);
                setShowConfigHeader(false);
              }}
              onWidgetSizeChange={onWidgetSizeChange}
              widgetType="MapWidget"
              onDeleteButtonPress={() => {
                setShowDeleteModal(true);
              }}
              onCloseHeader={() => {
                setShowConfigHeader(false);
              }}
            />
          )}
          <StandAloneMapWidget
            widgetStructure={widget}
            timeFrame={{
              startDate: midnight,
              endDate: currentTime,
            }}
            refresh={refresh}
          />
        </Box>

        <DnDMapWidgetConfigPopup
          pageData={pageData}
          setPageData={setPageData}
          widgetCluster={widgetCluster}
          widgetClusterIndex={widgetClusterIndex}
          //@ts-ignore
          widget={widget}
          widgetIndex={widgetIndex}
          moveWidgetPosition={moveWidgetPosition}
          lastMovedWidget={lastMovedWidget}
          sensorList={sensorList}
          refresh={refresh}
          setRefresh={setRefresh}
          setDeletedWidgets={setDeletedWidgets}
          open={openWidgetConfig}
          handleClose={() => {
            setOpenWidgetConfig(false);
          }}
        />
      </Box>
      <DeleteConfirmation
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        alertMessage="Are you sure you want to delete this widget? This action is irreversible!"
        processing={processing}
        setProcessing={setProcessing}
        onConfirmAction={() => {
          // deleteCustomWidget(widgetClusterIndex, widgetIndex);
          deleteCustomWidget(widgetClusterIndex, widgetIndex);
        }}
      />
    </>
  );
}
